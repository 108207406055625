import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC, Fragment, ReactNode } from 'react';

export interface InfoBlockData {
	label: string;
	content: ReactNode;
}

type BreakpointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type BreakpointValue = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
type Breakpoint = Partial<Record<BreakpointName, BreakpointValue>>;

interface IInfoBlockProps {
	data: InfoBlockData[];
	containerSx?: SxProps<Theme>;
	labelSx?: SxProps<Theme>;
	labelGridSize?: Breakpoint;
	contentGridSize?: Breakpoint;
}

const InfoBlock: FC<IInfoBlockProps> = ({ data, containerSx, labelSx, labelGridSize, contentGridSize }: IInfoBlockProps) => (
	<Grid
		container
		sx={{
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			gap: 2,
			...containerSx,
		}}>
		{data.map(({ label, content }) => (
			<Fragment key={label}>
				<Grid
					item
					xs={labelGridSize?.xs}
					sm={labelGridSize?.sm}
					md={labelGridSize?.md}
					lg={labelGridSize?.lg}
					xl={labelGridSize?.xl}
					sx={{
						textWrap: 'nowrap',
						...labelSx,
					}}>
					{label}
				</Grid>
				<Grid item xs={contentGridSize?.xs} sm={contentGridSize?.sm} md={contentGridSize?.md} lg={contentGridSize?.lg} xl={contentGridSize?.xl}>
					{content}
				</Grid>
			</Fragment>
		))}
	</Grid>
);

export default InfoBlock;
