enum TENANT_ACTION_TYPES {
	LOGIN = 'LOGIN',
	LOGIN_VIA_PERSIST = 'LOGIN_VIA_PERSIST',
	LOGIN_SUCCESS = 'LOGIN_SUCCESS',
	LOGIN_ERROR = 'LOGIN_ERROR',

	LOGOUT = 'LOGOUT',
	LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

	CLEAR_STORE = 'CLEAR_STORE',
}

export default TENANT_ACTION_TYPES;
