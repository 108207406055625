import IState from '@interfaces/redux/i-state';

const mapStateToProps = (state: IState) =>
	({
		isAuth: state.tenant.result?.uid,
		claims: state.tenant.claims,
		userId: state.tenant.id,
	}) as const;

export type IAuthGatewayStateToProps = ReturnType<typeof mapStateToProps>;

export { mapStateToProps };
