import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		padding: '50px 20px',
	},
	body: {
		flex: '1 1 auto',
	},
	button: {
		mt: 1,
		width: 100,
	},
	divider: {
		width: '100%',
		mt: 1,
		ml: 0.5,
		backgroundColor: ({ custom }) => custom.simple,
	} as SxProps<Theme>,
	buttons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
	},
	footerButton: {
		display: 'flex',
		justifyContent: 'center',
		mb: 4,
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		mb: 2,
	},
	email: {
		wordBreak: 'break-word',
		color: 'inherit',
		fontSize: 'inherit',
		fontWeight: 'bold',
	},
};

export default styles as IStyles<typeof styles>;
