import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { FC } from 'react';
import { TransferFundsBlock } from '@components/index';
import StripeElements from '@components/stripe-elements/index.store';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import PaymentDetails from '@pages/accounts/account/payment-details/index.store';
import { IAdministrationDispatchToProps, IAdministrationStateToProps } from '@pages/administration/index.props';
import TenantBilling from '@pages/administration/tenant-billing/index.store';
import PlanType from '../../enums/plan-type';
import RoleType from '../../enums/role-type';
import ReportsAllClients from './card-reports/index.store';
import CardsUpdate from './cards-update/index.store';
import GridItem from './grid-item';
import UserForm from './user-form/index.store';

type IAdministrationProps = IAdministrationStateToProps & IAdministrationDispatchToProps;
/**
 * Administration
 * @constructor
 */
const Administration: FC<IAdministrationProps> = ({ userRoles, isDisplayBonusCards, plan, enableTenantBilling }) => {
	const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);
	const isAllowedForCustomer = isAllowedForUser([RoleType.Customer], userRoles as RoleType[]);
	const isCustomPlan = plan === PlanType.Custom;
	const isDisplayTransferBlock = isDisplayBonusCards && (isAllowedForAdmin || (isAllowedForCustomer && isCustomPlan));

	return (
		<Box
			sx={{
				background: ({ palette }) => (palette.mode === 'light' ? '#F5F5F5' : '#000'),
				height: '100%',
				boxShadow: 'none',
				pl: { xs: 0, sm: 2 },
				pt: '14px',
			}}>
			<Grid container gap={2}>
				{isAllowedForCustomer && (
					<GridItem>
						<UserForm />
					</GridItem>
				)}
				{isDisplayTransferBlock && (
					<GridItem>
						<TransferFundsBlock />
					</GridItem>
				)}
				{isAllowedForCustomer && (
					<GridItem>
						<StripeElements useGlobalStripe>
							<PaymentDetails showTitleBlock isGlobalPD={false} />
						</StripeElements>
					</GridItem>
				)}
				{isAllowedForAdmin && (
					<GridItem>
						<CardsUpdate />
					</GridItem>
				)}

				{isAllowedForAdmin && (
					<GridItem>
						<ReportsAllClients />
					</GridItem>
				)}

				{isAllowedForAdmin && enableTenantBilling && (
					<GridItem>
						<TenantBilling />
					</GridItem>
				)}
			</Grid>
		</Box>
	);
};

export default Administration;
