import { IDeposit } from '@interfaces/deposits/i-deposit';
import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

/**
 * Get deposits list
 */
export const getDepositsList = (): IAction<TYPES> => ({
	type: TYPES.GET_DEPOSITS_LIST,
	payload: {},
});

/**
 * Get deposits list success
 */
export const getDepositsListSuccess = (list: IDeposit[]): IAction<TYPES> => ({
	type: TYPES.GET_DEPOSITS_LIST_SUCCESS,
	payload: { list },
});

/**
 * Get deposits list error
 */
export const getDepositsListError = (message: string): IAction<TYPES> => ({
	type: TYPES.GET_DEPOSITS_LIST_ERROR,
	payload: { message },
});

/**
 * Update new deposit
 */
export const updateNewDeposit = (id: string, forEntity: 'account' | 'card', value: number, customBonus?: number): IAction<TYPES> => ({
	type: TYPES.UPDATE_NEW_DEPOSIT,
	payload: { id, value, customBonus, forEntity },
});

/**
 * Clear new deposit
 */
export const clearNewDeposit = (): IAction<TYPES> => ({
	type: TYPES.CLEAR_NEW_DEPOSIT,
	payload: {},
});
