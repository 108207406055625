import React, { FC } from 'react';
import type { CellProps } from 'react-table';
import { ToDeposit } from '@components/index';

/**
 * ToDepositCell
 * @constructor
 */

const ToDepositCell: FC<CellProps<any>> = ({ row: { index, original } }) => {
	/**
	 * Row id
	 */
	const id = `to-deposit-button-${index}`;

	return <ToDeposit id={id} card={original} />;
};

export default ToDepositCell;
