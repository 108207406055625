import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	search: {
		margin: '20px 21px 15px',
		borderRadius: '4px',
		border: ({ palette }) => `1px solid ${palette.mode === 'light' ? '#dddddd' : 'trasparent'}`,
	} as SxProps<Theme>,
	row: {
		pr: { xs: 0, md: 5 },

		'& svg': {
			cursor: 'pointer',

			'& [data-fill="theme"]': {
				fill: 'primary.main',
			},
			'& [data-stroke="theme"]': {
				stroke: 'primary.main',
			},
		},
	},
};

export default styles as IStyles<typeof styles>;
