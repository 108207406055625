import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as IconError } from '@assets/images/icons/error.svg';
import { ReactComponent as IconSuccess } from '@assets/images/icons/success.svg';
import { SmallButton, Timer } from '@components/index';
import styles from './styles';

interface ResultDialogProps {
	type: 'success' | 'error';
	description?: {
		i18nKey: string;
		values?: Record<string, string>;
	};
	close: () => void;
}

/**
 * SuccessDialog
 * @constructor
 */
const ResultDialog: FC<ResultDialogProps> = ({ type, description, close }) => {
	const { t } = useTranslation();

	return (
		<Box sx={styles.container}>
			<Box sx={styles.body}>
				<Typography fontSize={20} color="text.secondary" textAlign="center" marginBottom={5} mt={2}>
					{t(type)}
				</Typography>
				<Box sx={styles.icon}>{type === 'success' ? <IconSuccess /> : <IconError />}</Box>
				{description && (
					<Typography fontSize={20} color="action.active" textAlign="center" marginTop={6}>
						<Trans t={t} i18nKey={description.i18nKey} values={description.values} />
					</Typography>
				)}
			</Box>

			<Box sx={styles.footerButton}>
				<SmallButton variant="outlined" color="primary" onClick={close} sx={styles.button} startIcon={<Timer duration={5000} onFinish={close} />}>
					{t('close')}
				</SmallButton>
			</Box>
		</Box>
	);
};

export default ResultDialog;
