import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface IProps {
	isFetching: boolean;
	checked: boolean | undefined;
	onChange: (event: React.ChangeEvent<HTMLInputElement>, switchState: boolean) => void;
	titleTranslationKey: string;
	disabled?: boolean;
	data: unknown;
}
const CustomLoadingSwitch: FC<IProps> = ({ isFetching, checked, onChange, titleTranslationKey, disabled, data }) => {
	const { t } = useTranslation();
	const title = t(titleTranslationKey);

	const renderItem = useMemo(
		() => (
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<>
				{!data || isFetching || typeof checked !== 'boolean' ? (
					<Box sx={{ ...styles.container, pr: '40px', justifyContent: 'center' }}>
						<CircularProgress size={23} color="inherit" />
					</Box>
				) : (
					<Box sx={styles.container}>
						<Switch onChange={onChange} checked={checked} sx={styles.switchActive} disabled={disabled} />
					</Box>
				)}
			</>
		),
		[data, isFetching, checked, onChange, disabled],
	);

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.title}>{title}</Box>
			{renderItem}
		</Box>
	);
};

export default CustomLoadingSwitch;
