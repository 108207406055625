import React, { Component, ComponentType, ReactNode, LazyExoticComponent } from 'react';
import { ConnectedComponent } from 'react-redux';
import AuthGateway from '@containers/auth-gateway/index.store';
import { IRolesAndPermissions } from '@interfaces/auth/i-roles-and-permissions';

/**
 * Add auth gateway to page
 */
const withAuth = (
	WrappedComponent: ComponentType | ConnectedComponent<any, any> | LazyExoticComponent<ComponentType | ConnectedComponent<any, any>>,
	rolesAndPermissions: IRolesAndPermissions,
): ComponentType =>
	class extends Component {
		static displayName = 'withAuth';

		render(): ReactNode {
			return (
				<AuthGateway roles={rolesAndPermissions.roles} permissions={rolesAndPermissions.permissions}>
					<WrappedComponent />
				</AuthGateway>
			);
		}
	};

export default withAuth;
