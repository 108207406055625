import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, SmallButton, TitledBlock } from '@components/index';
import { IModalForward } from '@components/modal';

interface Props {
	modalRef: MutableRefObject<IModalForward | null>;
}

const translateKeys = ['p1', 'p2', 'p3', 'p4'];
const SubscriptionRenewalModal: FC<Props> = ({ modalRef }) => {
	const { t } = useTranslation();

	const onClose = () => {
		modalRef.current?.toggleModal();
	};

	return (
		<Modal ref={modalRef} height={'auto'} customStyle={{ maxWidth: '600px' }}>
			<TitledBlock title={t('subscriptionRenewal.title')}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						overflowY: 'auto',
					}}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'start',
							justifyContent: 'center',
							gap: 2,
						}}>
						{translateKeys.map((key) => (
							<Typography key={key}>{t(`subscriptionRenewal.${key}`)}</Typography>
						))}
					</Box>
					<SmallButton sx={{ width: '30%' }} onClick={onClose}>
						{t('ok')}
					</SmallButton>
				</Box>
			</TitledBlock>
		</Modal>
	);
};

export default SubscriptionRenewalModal;
