import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		background: ({ palette }) => (palette.mode === 'light' ? 'white' : '#1F1E1E'),
		height: { xs: 'auto', md: '82px' },
		my: '0 !important',
		ml: '0 !important',
		color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : 'white'),
		px: { xs: 2, sm: 4 },
		py: { xs: 2, sm: 0 },
		boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.25)',
		flexWrap: 'nowrap',
	} as SxProps<Theme>,

	plan: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '90%',
		textAlign: 'left',
		['@media (max-width:1200px)']: {
			textAlign: 'center',
		},
	},
	boxPay: {
		display: 'flex',
		gap: 2,
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	button: {
		margin: 0,
		alignSelf: 'flex-end',
	},

	value: {
		fontSize: { xs: '21px', sm: '16px' },
		fontWeight: 700,
		color: ({ palette }: any) => (palette.mode === 'light' ? '#787878' : 'rgba(255, 255, 255, 0.83)'),
	},

	title: {
		fontSize: '12px',
		color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),

		'& p': {
			color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
		},
	} as SxProps<Theme>,

	input: {
		width: '65px',
		input: { padding: '3.5px' },
		'&.MuiInputBase-multiline': { padding: '3.5px' },
	},

	icon: {
		ml: 4,
		display: 'flex',
		color: ({ palette }) => palette.primary.main,
	} as SxProps<Theme>,

	textPopup: {
		padding: '7px 15px',
		margin: '0',
		fontSize: '13px',
		textTransaction: '0.2s ease all',
		cursor: 'pointer',
		textAlign: 'left',
		color: ({ palette }: any) => (palette.mode === 'light' ? 'text.secondary' : 'white'),
		'&:hover': {
			background: ({ palette }: any) => (palette.mode === 'light' ? '#F5F5F5' : palette.grey[800]),
		},
	} as SxProps<Theme>,
	infoPlan: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		gap: 3,
		width: {
			md: '100%',
			lg: '85%',
		},
		maxWidth: '413px',
		minWidth: '300px',
		pr: { xs: 2, sm: 4 },

		'& p': {
			fontSize: '12px',
			my: 0,
			display: 'flex',
			overflow: 'unset',
			color: ({ palette }: any) => (palette.mode === 'light' ? '#666666' : 'white'),
		},
	} as SxProps<Theme>,

	pointer: {
		cursor: 'pointer',
		position: 'relative',

		'& p': {
			fontSize: '12px',
			color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
		} as SxProps<Theme>,
	},

	// inactive: {
	//   display: 'flex',
	//   flexDirection: 'column',
	//   justifyContent: 'space-between',
	//   pt: '17px',
	//   pb: '13.5px',
	//   alignItems: 'end',
	//   width: '105px',
	//   height: '100%',
	// },

	bonusDiscount: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		justifyContent: 'space-between',
		height: '100%',
	},

	topBlocks: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		overflowX: 'auto',
		overflowY: 'hidden',
	},

	autoCharge: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		pt: { xs: 0, md: '17px' },
		pb: { xs: 0, md: '13.5px' },
		alignItems: 'start',
		width: '105px',
		height: '100%',
	},
};

export default styles as IStyles<typeof styles>;
