import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface ITableSearch {
	onChange: (value: string) => void;
	value: string;
}

/**
 * TableSearch
 * @constructor
 */
const TableSearch: FC<ITableSearch> = ({ onChange, value }) => {
	const { t } = useTranslation();
	/**
	 * On change input value
	 */
	const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value), [onChange]);

	return (
		<Paper sx={styles.container}>
			<TextField
				onChange={handleOnChange}
				InputProps={{
					sx: styles.input,
					value,
					placeholder: `${t('search')}`,
					startAdornment: (
						<InputAdornment position="start">
							<SearchIcon sx={styles.icon} />
						</InputAdornment>
					),
					endAdornment: value ? (
						<InputAdornment position="end">
							<CloseIcon sx={styles.icon} onClick={() => onChange('')} />
						</InputAdornment>
					) : null,
				}}
				fullWidth
			/>
		</Paper>
	);
};

export default TableSearch;
