import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import IS_MOBILE from '@constants/is-mobile';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
	},
	main: ({ palette, breakpoints }: Theme) =>
		({
			backgroundColor: palette.mode === 'light' ? '#F5F5F5' : '#000',
			flexGrow: 1,
			height: IS_MOBILE ? `calc(${window.innerHeight}px - 72px)` : 'calc(100vh - 72px)',
			overflow: 'auto',
			px: 0,

			'&[data-fixed="true"]': {
				[breakpoints.up('md')]: {
					overflow: 'hidden',
				},
			},
		}) as SxProps<Theme>,
	container: {
		backgroundColor: ({ palette }) => `${palette.mode === 'light' ? '#fff' : '#1F1E1E'}`,
		boxShadow: '1px 0px 4px rgba(0, 0, 0, 0.25)',
		m: 0,
		px: '0 !important',
		height: '100%',
	} as SxProps<Theme>,
	box: {
		p: 0,
		pr: 0,
		pl: 0,
		width: '100%',
		height: '100%',
	},
};

export default styles as IStyles<typeof styles>;
