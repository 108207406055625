import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { nayaxCardsSync } from '@store/cards/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		isFetching: state.cards.nayaxSync.isFetching,
		isFetchingFirebase: state.tenant.isFetching,
	}) as const;

export const mapDispatchToProps = {
	nayaxCardsSync,
};

export type ICardUpdateStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardUpdateDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
