import type { SagaIterator } from '@redux-saga/core';
import { fork, delay, put, select, takeLatest, call } from 'redux-saga/effects';
import roundNumber from '@helpers/round-number';
import { onUpdateEssenceSuccess, onUpdateListFork } from '@helpers/sagas';
import type { IAccount } from '@interfaces/accounts/i-account';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import type { IAction } from '@interfaces/redux/i-action';
import { CARDS_PAY_DIALOG } from '@pages/accounts/account/cards-pay';
import { clearNewDeposit } from '@store/deposits/action-creators';
import type { IDepositsState } from '@store/deposits/reducer';
import { awaitUserToken } from '@store/tenant/sagas';
import PlanType from '../../enums/plan-type';
import TransactionStatus from '../../enums/transaction-status';
import TransactionType from '../../enums/transaction-type';
import { getAccount } from '../accounts/action-creators';
import { getCardsList } from '../cards/action-creators';
import {
	addTransactionError,
	addTransactionsError,
	addTransactionsSuccess,
	addTransactionSuccess,
	deleteTransactionError,
	deleteTransactionSuccess,
	getTransactionList,
	getTransactionListError,
	getTransactionListSuccess,
	updateTransactionError,
	updateTransactionSuccess,
} from './action-creators';
import TYPES from './action-types';
import { createTransactionApi, deleteTransactionApi, updateTransactionApi, createTransactionForCreditPlanApi, getTransactionListApi } from './api';
import type { ITransactionsState } from './reducer';

/**
 * Get the transaction list
 */
function* getTransactions({ payload: { cardId } }: IAction<TYPES>): SagaIterator {
	try {
		yield call(awaitUserToken);
		const pagination: ITransactionsState['list']['pagination'] = yield select((state) => state.transactions.list.pagination);
		const account: IAccount = yield select((state) => state.accounts.item.result);
		const { startDate, endDate } = yield select((state) => state.transactions.list?.dates);
		const sorting = yield select((state) => state.transactions.list?.sorting);
		const selectedCardId = yield select((state) => state.transactions.list.selectedCardId);
		const [sort, isDesc] = Object.entries(sorting ?? {})?.[0] ?? [];

		const result = yield call(getTransactionListApi, {
			page: pagination?.page ?? 1,
			size: pagination.size,
			order: 1,
			relations: ['card'],
			...(!(cardId || selectedCardId) && account && { accountId: account.id }), // для определенного аккаунта
			...((cardId || selectedCardId) && { cardId: cardId || selectedCardId }),
			...(sort !== 'undefined' && { sort, order: isDesc ? 'Descending' : 'Ascending' }),
			...(startDate && { startDate }),
			...(endDate && { endDate }),
		});

		yield put(
			getTransactionListSuccess(result.data, {
				page: Number(result.currentPage),
				total: result.count,
				size: pagination.size,
			}),
		);
	} catch (e) {
		yield put(getTransactionListError(e.message));
	}
}

/**
 * Update transaction
 */
function* updateTransaction({ payload: { id, newData, successCallback } }: IAction<TYPES>): SagaIterator {
	try {
		const result = yield call(updateTransactionApi, id, newData);

		yield put(updateTransactionSuccess(result));
		successCallback?.();
	} catch (e) {
		yield put(updateTransactionError(e.message));
	}
}

/**
 * Delete transaction
 */
function* deleteTransaction({ payload: { id, successCallback } }: IAction<TYPES>): SagaIterator {
	try {
		const list: ITransactionsState['list']['result'] = yield select((state) => state.transactions.list.result);

		yield call(deleteTransactionApi, id);
		yield put(getTransactionListSuccess(list.filter((value) => value.id !== id)));
		yield put(deleteTransactionSuccess());
		successCallback?.();
	} catch (e) {
		yield put(deleteTransactionError(e.message));
	}
}

/**
 * Add transaction
 */
function* addTransaction({ payload: { successCallback } }: IAction<TYPES>): SagaIterator {
	try {
		yield delay(1000);
		yield put(getTransactionList());
		yield put(addTransactionSuccess());
		successCallback?.();
	} catch (e) {
		yield put(addTransactionError(e.message));
	}
}

/**
 * Add transactions
 */
function* addTransactions({ payload: { callback, accountPlanName, toPayValue, discount, specialCardId } }: IAction<TYPES>): SagaIterator {
	try {
		const { deposit, customBonus, forEntity }: IDepositsState['item'] = yield select((state) => state.deposits.item);
		const taxPercentage = yield select((state) => state.payments.tax.result.percentage);
		const accountId = yield select((state) => state.accounts.item.result?.id);
		const isCustomPlanType: boolean = accountPlanName === PlanType.Custom;
		const isCoopPlanType: boolean = accountPlanName === PlanType.Coop;
		const isAdjustment: boolean = accountPlanName === PAYMENT_TYPE.ADJUSTMENT;

		if (isAdjustment) {
			yield call(createTransactionApi, specialCardId, {
				specialCardId,
				amount: -toPayValue,
				bonus: 0,
				tax: 0,
				name: PAYMENT_TYPE.ADJUSTMENT,
				type: TransactionType.adjustment,
				status: TransactionStatus.Processed,
			});

			yield put(addTransactionsSuccess());
			callback?.(CARDS_PAY_DIALOG.SUCCESS);
			yield put(getCardsList());

			return;
		}

		if (!isCustomPlanType && forEntity === 'account') {
			// Pay for credit plan (Monthly Unlimited)
			const tax = roundNumber((toPayValue * taxPercentage) / 100);

			let amount: number = toPayValue;
			let bonus = Number(discount);

			if (isCoopPlanType) {
				amount = deposit[accountId];
				bonus = customBonus[accountId];
			}

			yield call(createTransactionForCreditPlanApi, accountId, {
				accountId,
				amount,
				bonus,
				tax,
				name: 'At counter',
				type: TransactionType.atCounter,
				status: TransactionStatus.Processed,
			});

			yield put(getAccount(accountId));
		}

		if (isCustomPlanType && forEntity === 'card') {
			for (const [cardId, amount] of Object.entries(deposit)) {
				// Pay for custom plan
				if (!amount) {
					continue;
				}

				const tax = roundNumber((amount * taxPercentage) / 100);

				yield call(createTransactionApi, cardId, {
					cardId,
					amount,
					bonus: customBonus[cardId],
					tax,
					name: 'At counter',
					type: TransactionType.atCounter,
					status: TransactionStatus.Processed,
				});
			}
		}

		yield put(addTransactionsSuccess());
		yield put(clearNewDeposit());
		callback?.(CARDS_PAY_DIALOG.SUCCESS);
		yield put(getCardsList());
	} catch (e) {
		yield put(addTransactionsError(e.message));
		callback?.(CARDS_PAY_DIALOG.ERROR);
	}
}

/**
 * On update transaction success
 */
function* onUpdateTransaction(): SagaIterator {
	yield call(onUpdateEssenceSuccess, 'transactions', TYPES.UPDATE_TRANSACTION_SUCCESS, getTransactionList);
}

/**
 * On update pagination
 */
function* onUpdatePagination(): SagaIterator {
	yield call(onUpdateListFork, 'transactions', 'pagination', TYPES.UPDATE_TRANSACTIONS_LIST_PAGINATION, getTransactionList);
}

/**
 * On update sorting
 */
function* onUpdateSorting(): SagaIterator {
	yield call(onUpdateListFork, 'transactions', 'sorting', TYPES.UPDATE_TRANSACTIONS_LIST_SORTING, getTransactionList);
}

/**
 * On update dates
 */
function* onUpdateDates(): SagaIterator {
	yield call(onUpdateListFork, 'transactions', 'dates', TYPES.UPDATE_TRANSACTIONS_LIST_DATES, getTransactionList);
}

export default [
	takeLatest(TYPES.GET_TRANSACTIONS_LIST, getTransactions),
	takeLatest(TYPES.UPDATE_TRANSACTION, updateTransaction),
	takeLatest(TYPES.DELETE_TRANSACTION, deleteTransaction),
	takeLatest(TYPES.ADD_TRANSACTION, addTransaction),
	takeLatest(TYPES.ADD_TRANSACTIONS, addTransactions),
	fork(onUpdateTransaction),
	fork(onUpdatePagination),
	fork(onUpdateSorting),
	fork(onUpdateDates),
];
