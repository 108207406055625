import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TooltipMUI from '@mui/material/Tooltip';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, SmallButton, TitledBlock } from '@components/index';
import { IModalForward } from '@components/modal';
import styles from '@components/to-deposite/styles';
import isConditionDotsInString from '@helpers/condition-payment-dots';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import roundNumber from '@helpers/round-number';
import RoleType from 'src/enums/role-type';
import StyledMenu from './styled-menu';

interface IDropdown<T> {
	id: string;
	isDisabled?: boolean;
	isDisabledItem?: (v: T) => boolean;
	handleClickItem: (v: T) => void;
	data: T[];
	renderItem?: (item: T) => JSX.Element | string | number;
	hint?: string;
	handleGetCustomBonus?: any;
	userRoles?: any;
}

/**
 * Dropdown
 * @constructor
 */
function Dropdown<T>({ id, handleClickItem, isDisabled, isDisabledItem, data, renderItem, hint = '', userRoles }: IDropdown<T>): JSX.Element {
	const [customAmount, setCustomAmount] = useState<number | string>('');
	const [customBonus, setCustomBonus] = useState<number | string>('');
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const modalCustomAmountRef = useRef<IModalForward | null>(null);
	const { t } = useTranslation();

	const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);

	const toggleModal = useCallback(() => modalCustomAmountRef.current?.toggleModal(), []);
	/**
	 * Is open menu
	 */
	const isOpen = Boolean(anchorEl);

	/**
	 * On click button
	 */
	const onClickButton = useCallback((event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget), []);

	/**
	 * Close menu
	 */
	const handleClose = useCallback(() => setAnchorEl(null), []);

	/**
	 * On click menu item
	 */
	const onClickItem = useCallback(
		(v) => () => {
			handleClickItem(v);
			handleClose();
		},
		[handleClickItem, handleClose],
	);

	const handleSubmitCustomAmount = () => {
		if (Number(customAmount)) {
			handleClickItem({
				amount: Math.round(Number(customAmount) * 100) / 100,
				bonus: Math.round(Number(customBonus) * 100) / 100,
			} as unknown as T);
		}

		toggleModal();
		setAnchorEl(null);
	};

	const handleCloseModalAmount = () => {
		setCustomAmount('');
		setCustomBonus('');
		setAnchorEl(null);
	};

	const isConditionCustomBonus: boolean = Number(customAmount) !== 0 && Number(customBonus) >= 0 && Number(customAmount) >= Number(customBonus);

	const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

	const isRegexTest = (val: any) =>
		({
			name: 'period',
			regex: /^\d+(\.\d{0,2})?$/,
		}).regex.test(val);

	const handleChangePayment = useCallback((e: any, type: string) => {
		const value = Number(roundNumber(e.target.value));
		const handleFunc = (val: number | string) => (type === 'payment' && setCustomAmount(val)) || (type === 'bonus' && setCustomBonus(val));

		if (isConditionDotsInString(e.target.value)) {
			handleFunc(e.target.value);

			return;
		}

		if (isRegexTest(e.target.value)) {
			handleFunc(e.target.value);

			return;
		}

		if (value >= 0) {
			handleFunc(value);

			// return;
		}
	}, []);

	return (
		<TooltipMUI title={isDisabled ? hint : ''}>
			<Box fontSize={0}>
				<Button
					id={id}
					{...(isOpen && { 'aria-controls': id, 'aria-expanded': 'true' })}
					aria-haspopup="true"
					variant="contained"
					onClick={onClickButton}
					endIcon={<KeyboardArrowDownIcon />}
					disabled={isDisabled}
					sx={styles.button}
				/>

				<StyledMenu id={id} MenuListProps={{ 'aria-labelledby': id }} anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
					{data.map((item) => (
						<MenuItem key={typeof item === 'object' ? (item as any)?.id : String(item)} disabled={isDisabledItem?.(item)} onClick={onClickItem(item)}>
							{renderItem?.(item) ?? item}
						</MenuItem>
					))}
					{isAllowedForAdmin && (
						<MenuItem key={'custom_amount'} sx={styles.btnCustomAmount} onClick={() => modalCustomAmountRef.current?.toggleModal()}>
							<SmallButton>{t('dropdown.customAmount')}</SmallButton>
						</MenuItem>
					)}
				</StyledMenu>

				<Modal ref={modalCustomAmountRef} width={381} height={223} onClose={handleCloseModalAmount}>
					<TitledBlock title={'Custom payment'} styleChildren={{ pt: '14px' }}>
						<Box sx={{ pl: 1.2 }}>
							<Box sx={styles.boxCustomAmount}>
								<Typography color="text.primary">{t('dropdown.enterAmounts')}</Typography>

								<Box mt={1.75} display="flex" justifyContent="space-between">
									<Box justifyContent="center" display="flex" flexDirection="column">
										<Typography mb={0.75} fontSize={'12px'} color="text.primary">
											{t('dropdown.payments')}
										</Typography>
										<TextField
											InputProps={{
												startAdornment: <Typography sx={{ fontWeight: 'bold' }}>$</Typography>,
											}}
											className="number_text_field"
											value={customAmount}
											onKeyDown={blockInvalidChar}
											sx={styles.inputAmount}
											onChange={(e) => handleChangePayment(e, 'payment')}
										/>
									</Box>
									<Box justifyContent="center" display="flex" flexDirection="column">
										<Typography mb={0.75} fontSize={'12px'} color="text.primary">
											{t('bonus')}
										</Typography>
										<TextField
											InputProps={{
												startAdornment: <Typography sx={{ fontWeight: 'bold' }}>$</Typography>,
											}}
											className="number_text_field"
											value={customBonus}
											sx={styles.inputAmount}
											onKeyDown={blockInvalidChar}
											onChange={(e) => handleChangePayment(e, 'bonus')}
										/>
									</Box>
								</Box>
								<Box display="flex" justifyContent="space-between">
									<SmallButton
										sx={{ width: '154px', mt: 2 }}
										variant="outlined"
										onClick={() => {
											handleCloseModalAmount();
											toggleModal();
										}}>
										{t('cancel')}
									</SmallButton>
									<SmallButton sx={{ width: '154px', mt: 2 }} onClick={handleSubmitCustomAmount} disabled={!isConditionCustomBonus}>
										{t('submit')}
									</SmallButton>
								</Box>
							</Box>
						</Box>
					</TitledBlock>
				</Modal>
			</Box>
		</TooltipMUI>
	);
}

export default Dropdown;
