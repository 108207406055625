import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { getAccount, updateAccount } from '@store/accounts/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		isFetchingUser: state.accounts.item.isFetching,
		userRoles: state.tenant.claims?.roles,
		userInfo: state.accounts.item.result,
		userId: state.tenant.id,
		isFetchingAccountUpdate: state.accounts.update.isFetching,
	}) as const;

export const mapDispatchToProps = {
	getAccount,
	updateAccount,
};

export type IUserFormStateToProps = ReturnType<typeof mapStateToProps>;

export type IUserFormDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
