import type { RouteProps } from 'react-router-dom';
import withAuth from '@containers/wrappers/with-auth';
import withBars from '@containers/wrappers/with-bars';
import withSettings from '@containers/wrappers/with-settings';
import Account from '@pages/accounts/account/index.store';
import Accounts from '@pages/accounts/main/index.store';
import Administration from '@pages/administration/index.store';
import Cards from '@pages/cards/main/index.store';
import Changelog from '@pages/changelog';
import ForgotConfirm from '@pages/forgot-confirm/index.store';
import Forgot from '@pages/forgot/index.store';
import Login from '@pages/login/index.store';
import ReportPage from '@pages/report/index.store';
import TransferFunds from '@pages/transfer-funds';
import PermissionType from '../enums/permission-type';
import RoleType from '../enums/role-type';
import RoutePath from '../enums/route-path';

/**
 * Project router
 */
export default class SiteRoutes {
	/**
	 * Project routes
	 */
	public static routes: RouteProps<RoutePath | `${RoutePath.AccountsEdit}/:code`>[] = [
		{
			path: RoutePath.Accounts,
			component: withAuth(withBars(Accounts), {
				roles: [RoleType.Admin],
				permissions: [PermissionType.Read],
			}),
		},
		{
			path: `${RoutePath.AccountsEdit}/:code`,
			component: withAuth(withBars(Account), {
				roles: [RoleType.Customer, RoleType.Admin],
				permissions: [PermissionType.Write],
			}),
		},
		{
			path: RoutePath.AccountsAdd,
			component: withAuth(withBars(Account), {
				roles: [RoleType.Admin],
				permissions: [PermissionType.Write],
			}),
		},
		{
			path: RoutePath.Cards,
			component: withAuth(withBars(Cards), {
				roles: [RoleType.Admin],
				permissions: [PermissionType.Read],
			}),
		},
		{
			path: RoutePath.Administration,
			component: withAuth(withBars(Administration), {
				roles: [RoleType.Customer, RoleType.Admin],
				permissions: [PermissionType.Write, PermissionType.Read],
			}),
		},
		{
			path: RoutePath.Login,
			component: Login,
		},
		{
			path: RoutePath.Changelog,
			component: withAuth(withBars(Changelog), {
				roles: [RoleType.Admin],
				permissions: [PermissionType.Read],
			}),
		},
		{
			path: RoutePath.ForgotConfirm,
			component: withSettings(ForgotConfirm, ['enableUserPortal']),
		},
		{
			path: RoutePath.Forgot,
			component: withSettings(Forgot, ['enableUserPortal']),
		},
		{
			path: RoutePath.TransferFunds,
			component: withSettings(TransferFunds, ['displayBonusCards']),
		},
		{
			path: RoutePath.Report,
			component: withAuth(withSettings(ReportPage, ['enableUserPortal']), {
				roles: [RoleType.Admin, RoleType.Customer],
				permissions: [PermissionType.Read],
			}),
		},
	];
}
