/* eslint-disable @typescript-eslint/default-param-last */
import { IPayment } from '@interfaces/payments/i-payment';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import { ITaxRate } from '@interfaces/tax-rate/i-tax-rate';
import { ITransaction } from '@interfaces/transactions/i-transaction';
import TYPES from './action-types';

export interface IPaymentsState {
	list: IReducerState<IPayment[]> & ITableList;
	update: IReducerState<string | undefined>;
	delete: IReducerState<string | undefined>;
	add: IReducerState<string | undefined>;
	listen: IReducerState<boolean> & { isActive: boolean } & { transactions: null | ITransaction[] };
	tax: IReducerState<ITaxRate>;
	creditIntegrationData: IReducerState<{ clientSecret: string; stripePaymentId: string } | null>;
	statusPay: string | null;
	typeListener: string | null;
}

const initState: IPaymentsState = {
	list: {
		result: [],
		isFetching: false,
		error: null,
		searchValue: '',
		pagination: { total: 0, page: 1, size: 10 },
		sorting: {},
		dates: { startDate: null, endDate: null },
	},
	update: {
		result: undefined, // loadingRowId
		isFetching: false,
		error: null,
	},
	delete: {
		result: undefined, // loadingRowId
		isFetching: false,
		error: null,
	},
	add: {
		result: undefined, // id
		isFetching: false,
		error: null,
	},
	listen: {
		result: false, // is account interface is blocked
		isFetching: false,
		error: null,
		isActive: false,
		transactions: null,
	},
	tax: {
		result: {
			displayName: '',
			inclusive: false,
			percentage: 0,
		},
		isFetching: false,
		error: null,
	},

	creditIntegrationData: {
		result: null,
		isFetching: false,
		error: null,
	},

	statusPay: null,
	typeListener: null,
};

/**
 * Payments reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
	switch (type) {
		// list
		case TYPES.GET_PAYMENTS_LIST:
			return { ...state, list: { ...state.list, error: null, isFetching: true } };

		case TYPES.GET_PAYMENTS_LIST_SUCCESS:
			return {
				...state,
				list: {
					...state.list,
					result: payload.list,
					pagination: payload.pagination,
					isFetching: false,
					error: null,
				},
			};

		case TYPES.GET_PAYMENTS_LIST_ERROR:
			return { ...state, list: { ...state.list, isFetching: false, error: payload.message } };

		case TYPES.CLEAR_PAYMENTS_LIST:
			return { ...state, list: { ...initState.list } };

		case TYPES.UPDATE_PAYMENTS_LIST_PAGINATION:
			return {
				...state,
				list: {
					...state.list,
					pagination: {
						...state.list.pagination,
						...payload.pagination,
						...(payload.pagination.size && payload.pagination.size !== state.list.pagination.size && { page: 1 }),
					},
				},
			};

		case TYPES.UPDATE_PAYMENTS_LIST_SORTING:
			return { ...state, list: { ...state.list, sorting: { [payload.name]: payload.desc } } };

		case TYPES.UPDATE_PAYMENTS_LIST_DATES:
			return { ...state, list: { ...state.list, dates: { ...payload } } };

		// update
		case TYPES.UPDATE_PAYMENT:
			return {
				...state,
				update: { ...state.update, error: null, isFetching: true, result: payload.id },
			};

		case TYPES.UPDATE_PAYMENT_SUCCESS:
			return { ...state, update: { ...initState.update } };

		case TYPES.UPDATE_PAYMENT_ERROR:
			return {
				...state,
				update: { ...state.update, isFetching: false, error: payload.message, result: undefined },
			};

		// delete
		case TYPES.DELETE_PAYMENT:
			return {
				...state,
				delete: { ...state.delete, error: null, isFetching: true, result: payload.id },
			};

		case TYPES.DELETE_PAYMENT_SUCCESS:
			return { ...state, delete: { ...initState.delete } };

		case TYPES.DELETE_PAYMENT_ERROR:
			return {
				...state,
				delete: { ...state.delete, isFetching: false, error: payload.message, result: undefined },
			};

		// add
		case TYPES.ADD_PAYMENTS:
			return {
				...state,
				add: { ...state.add, error: null, isFetching: true, result: payload.id },
			};

		case TYPES.ADD_PAYMENTS_SUCCESS:
			return { ...state, add: { ...initState.add } };

		case TYPES.CREATE_CREDIT_INTEGRATION:
			return {
				...state,
				creditIntegrationData: { ...state.creditIntegrationData, isFetching: true },
			};

		case TYPES.CREATE_CREDIT_INTEGRATION_SUCCESS:
			return {
				...state,
				creditIntegrationData: {
					result: payload.creditIntegrationData,
					isFetching: false,
					error: false,
				},
			};

		case TYPES.UPDATE_STATUS_PAY_CREDIT: {
			return { ...state, statusPay: payload.status };
		}

		case TYPES.ADD_PAYMENTS_ERROR:
			return {
				...state,
				add: { ...state.add, isFetching: false, error: payload.message, result: undefined },
			};

		// listen
		case TYPES.LISTEN_LAST_PAYMENT_START:
			return {
				...state,
				listen: { ...state.listen, error: null, isActive: true },
			};

		case TYPES.LISTEN_LAST_PAYMENT_SUCCESS:
			return {
				...state,
				listen: { ...state.listen, result: payload.value, transactions: payload.transactions },
			};

		case TYPES.LISTEN_LAST_PAYMENT_ERROR:
			return {
				...state,
				listen: { ...state.listen, error: payload.message },
			};

		case TYPES.LISTEN_LAST_PAYMENT_STOP:
			return { ...state, listen: { ...initState.listen }, add: { ...initState.add } };

		case TYPES.GET_TAX_INFO:
			return { ...state, tax: { ...initState.tax, isFetching: true } };

		case TYPES.GET_TAX_INFO_SUCCESS:
			return { ...state, tax: { result: payload, isFetching: false, error: null } };

		case TYPES.GET_TAX_INFO_ERROR:
			return { ...state, tax: { ...initState.tax, error: payload.message } };

		case TYPES.CLEAR_CLIENT_SECRET:
			return { ...state, clientSecret: { result: undefined, isFetching: false, error: null } };

		default:
			return state;
	}
};
