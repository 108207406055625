enum CARDS_ACTION_TYPES {
	GET_CARDS_LIST = 'GET_CARDS_LIST',
	GET_CARDS_LIST_SUCCESS = 'GET_CARDS_LIST_SUCCESS',
	GET_CARDS_LIST_ERROR = 'GET_CARDS_LIST_ERROR',
	CLEAR_CARDS_LIST = 'CLEAR_CARDS_LIST',
	UPDATE_CARDS_LIST_PAGINATION = 'UPDATE_CARDS_LIST_PAGINATION',
	UPDATE_CARDS_SEARCH = 'UPDATE_CARDS_SEARCH',
	UPDATE_CARDS_SORTING = 'UPDATE_CARDS_SORTING',

	UPDATE_CARD = 'UPDATE_CARD',
	UPDATE_CARD_SUCCESS = 'UPDATE_CARD_SUCCESS',
	UPDATE_CARD_ERROR = 'UPDATE_CARD_ERROR',

	DELETE_CARD = 'DELETE_CARD',
	DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS',
	DELETE_CARD_ERROR = 'DELETE_CARD_ERROR',

	ADD_CARD = 'ADD_CARD',
	ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS',
	ADD_CARD_ERROR = 'ADD_CARD_ERROR',

	ADD_CARDS = 'ADD_CARDS',
	ADD_CARDS_SUCCESS = 'ADD_CARDS_SUCCESS',
	ADD_CARDS_ERROR = 'ADD_CARDS_ERROR',

	NAYAX_CARDS_SYNC = 'NAYAX_CARDS_SYNC',
	NAYAX_CARDS_SYNC_SUCCESS = 'NAYAX_CARDS_SYNC_SUCCESS',
	NAYAX_CARDS_SYNC_ERROR = 'NAYAX_CARDS_SYNC_ERROR',

	TOGGLE_SHOW_INACTIVE = 'TOGGLE_SHOW_INACTIVE',
	SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE',
	SET_PAYMENT_TYPE_SUCCESS = 'SET_PAYMENT_TYPE_SUCCESS',

	TRANSFER_ANOTHER_CARD = 'TRANSFER_ANOTHER_CARD',
	TRANSFER_ANOTHER_CARD_RESPONSE = 'TRANSFER_ANOTHER_CARD_RESPONSE',
	TRANSFER_EXTERNAL_CARD = 'TRANSFER_EXTERNAL_CARD',
	TRANSFER_EXTERNAL_CARD_SUCCESS = 'TRANSFER_EXTERNAL_CARD_SUCCESS',

	TRANSFER_CARD_SUCCESS = 'TRANSFER_CARD_SUCCESS',
	TRANSFER_CARD_ERROR = 'TRANSFER_CARD_ERROR',
}

export default CARDS_ACTION_TYPES;
