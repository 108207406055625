import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { format } from 'date-fns';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TitledBlock } from '@components/index';
import InfoBlock from '@components/info-block';
import OneInputForm from '@components/one-input-form';
import StripeElements from '@components/stripe-elements/index.store';
import dateFormat from '@constants/date-format';
import regex from '@constants/regex';
import PaymentDetails from '@pages/accounts/account/payment-details/index.store';
import { ITenantBillingDispatchToProps, ITenantBillingStateToProps } from '@pages/administration/tenant-billing/index.props';
import styles from './styles';

type Props = ITenantBillingStateToProps & ITenantBillingDispatchToProps;
const TenantBilling: FC<Props> = ({
	tenantName,
	isFetching,
	isFetchingUpdate,
	nextBillingDate,
	tenantEmail,
	lastPaymentDay,
	updateTenantBillingData,
}) => {
	const { t } = useTranslation();

	const getInfoBlockData = useCallback(
		() => [
			{
				label: `${t('tenantBilling.organization')}:`,
				content: tenantName,
			},
			{
				label: `${t('tenantBilling.nextBillingDay')}:`,
				content: nextBillingDate ? `${format(new Date(nextBillingDate), dateFormat)}` : null,
			},
			{
				label: `${t('additionalBlocks.lastPayment')}:`,
				content: lastPaymentDay ? `${format(new Date(lastPaymentDay), dateFormat)}` : null,
			},
			{
				label: `${t('email')}:`,
				content: (
					<OneInputForm<{ email: string }>
						formData={{
							id: 'email',
							label: 'email',
							pattern: regex.email,
						}}
						isFetching={isFetchingUpdate}
						defaultFields={{ email: tenantEmail ? tenantEmail : '' }}
						successCallback={updateTenantBillingData}
					/>
				),
			},
			{
				label: `${t('tenantBilling.defaultCreditCard')}:`,
				content: (
					<StripeElements useGlobalStripe>
						<PaymentDetails showTitleBlock={false} isGlobalPD />
					</StripeElements>
				),
			},
		],
		[t, tenantName, nextBillingDate, lastPaymentDay, isFetchingUpdate, tenantEmail, updateTenantBillingData],
	);

	return (
		<TitledBlock title={t('tenantBilling.title')}>
			{isFetching ? (
				<Box sx={styles.loaderBox}>
					<CircularProgress />
				</Box>
			) : (
				<Box sx={styles.box}>
					<InfoBlock data={getInfoBlockData()} labelGridSize={{ xs: 3 }} contentGridSize={{ xs: 8 }} />
				</Box>
			)}
		</TitledBlock>
	);
};

export default TenantBilling;
