import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 2,

		input: {
			py: 1,
		},
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: 4,
	},
	text: {
		color: 'text.primary',
		textAlign: 'center',
		marginRight: 2,
	},
};

export default styles as IStyles<typeof styles>;
