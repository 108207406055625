import { Tooltip } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { Box } from '@mui/system';
import React, { FC } from 'react';

type IProps = TooltipProps & { showForDisableChildren: boolean; hideForEnableChildren: boolean };

/**
 * Tooltip
 * @constructor
 */
const CustomTooltip: FC<IProps> = ({ title, children, showForDisableChildren, hideForEnableChildren }) => (
	<Box>
		{hideForEnableChildren ? (
			children
		) : (
			<Tooltip title={title} placement="top" disableFocusListener disableTouchListener arrow>
				{showForDisableChildren ? <div>{children}</div> : children}
			</Tooltip>
		)}
	</Box>
);

export default CustomTooltip;
