import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import AccountsReducer from './accounts/reducer';
import BonusCardsReducer from './bonus-cards/reducer';
import CardsReducer from './cards/reducer';
import DepositsReducer from './deposits/reducer';
import ForgotReducer from './forgot/reducer';
import LocationsReducer from './locations/reducer';
import NewCardsReducer from './new-cards/reducer';
import PaymentMethodsReducer from './payment-methods/reducer';
import PaymentsReducer from './payments/reducer';
import SettingsReducer from './settings/reducer';
import SidebarReducer from './sidebar/reducer';
import SubscriptionReducer from './subscriptions/reducer';
import TenantBillingReducer from './tenant-billing/reducer';
import UserReducer from './tenant/reducer';
import TransactionsReducer from './transactions/reducer';

const reducers = {
	settings: SettingsReducer,
	tenant: persistReducer({ key: 'tenant', storage, whitelist: ['result'] }, UserReducer),
	cards: CardsReducer,
	bonusCards: BonusCardsReducer,
	newCards: NewCardsReducer,
	accounts: AccountsReducer,
	sidebar: persistReducer({ key: 'sidebar', storage, whitelist: ['isOpen'] }, SidebarReducer),
	transactions: TransactionsReducer,
	payments: PaymentsReducer,
	deposits: DepositsReducer,
	locations: LocationsReducer,
	forgot: ForgotReducer,
	subscriptions: SubscriptionReducer,
	paymentMethod: PaymentMethodsReducer,
	tenantBilling: TenantBillingReducer,
};

export default reducers;
