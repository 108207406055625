import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import ExportToExcel from '@components/create-excel/create-excel';
import TRANSACTION_TIME_KEY from '@constants/transaction-time-key';
import formatMoney from '@helpers/format-money';
import generateExcelOptions from '@helpers/generate-excel-options';
import PlanType from 'src/enums/plan-type';
import TableType from 'src/enums/table-type';
import SaveMode from '../../../enums/save-mode';
import ReportType from '../../../enums/type-reports';
import DateRangePicker from '../date-range-picker';
import FixedTableHeight from '../fixed-table-height';
import Modal, { IModalForward } from '../modal';
import Table from '../table';
import DateCell from '../table/cells/date-cell';
import { ITransactionsModalDispatchToProps, ITransactionsModalStateToProps } from './index.props';
import styles from './styles';

interface ITransactionsModal {
	id?: string;
	displayId?: string;
	onClose?: () => void;
	isAdmin?: boolean;
}

type IProps = ITransactionsModal & ITransactionsModalStateToProps & ITransactionsModalDispatchToProps;

/**
 * TransactionsModal
 * @constructor
 */
const TransactionsModal = forwardRef<IModalForward, IProps>(
	(
		{
			id,
			displayId,
			onClose,
			transactions,
			pagination,
			isFetching,
			getTransactionList,
			clearTransactionList,
			updateTransactionListDates,
			updateTransactionListPagination,
			updateTransactionListSorting,
			dates,
			account,
			isAdmin,
		},
		ref,
	) => {
		const { t } = useTranslation();
		const modalRef = useRef<IModalForward | null>(null);
		const isCustomPlanAccount: boolean = account?.accountPlan.plan.name === PlanType.Custom;

		/**
		 * Ref methods
		 */
		useImperativeHandle(ref, () => ({ toggleModal: () => modalRef.current?.toggleModal() }));

		/**
		 * Table columns config
		 */
		const columns: Column<any>[] = useMemo(() => {
			const result = [
				{
					Header: `${t('transactionsModal.amount')}`,
					accessor: 'amount',
					sortType: (a: any, b: any) => Number(a) - Number(b),
					Cell: ({ value }: { value: string | number }) => formatMoney(Number(value)),
				},
				{
					Header: isCustomPlanAccount ? 'Bonus' : 'Discount',
					accessor: 'bonus',
					Cell: ({ value }: { value: string | number }) => formatMoney(Number(value)),
				},
				{
					Header: `${t('transactionsModal.tax')}`,
					accessor: 'tax',
					Cell: ({ value }: { value: string | number }) => formatMoney(Number(value)),
				},
				{ Header: `${t('transactionsModal.type')}`, accessor: 'type' },
				{ Header: `${t('status')}`, accessor: 'status' },
				{ Header: `${t('date')}`, accessor: TRANSACTION_TIME_KEY, Cell: DateCell },
			];

			if (!id) {
				result.unshift(
					{ Header: `${t('transactionsModal.cardId')}`, accessor: 'card.name' },
					{
						Header: `${t('transactionsModal.description')}`,
						accessor: 'card.extra',
					},
				);
			}

			return result;
		}, [id, isCustomPlanAccount, t]);

		/**
		 * On open modal
		 */
		const onOpen = useCallback(() => {
			getTransactionList(id);
		}, [getTransactionList, id]);

		const handleOnClose = useCallback(() => {
			if (onClose) {
				onClose();
			}

			clearTransactionList();
		}, [onClose, clearTransactionList]);

		const handleChangePagination = useCallback(
			(props, cardId) => {
				updateTransactionListPagination(props, cardId);
				getTransactionList(cardId);
			},
			[getTransactionList, updateTransactionListPagination],
		);

		return (
			<Modal ref={modalRef} onClose={handleOnClose} onOpen={onOpen} customStyle={{ mt: 0 }} withoutTitle height={{ md: '95%', xs: '95%' }}>
				<Box sx={styles.container}>
					<FixedTableHeight
						heightPercent={100}
						topPane={
							<>
								<Typography color="text.primary" variant="h6" component="h2" textAlign="center">
									{displayId ? `${t('card')} #${displayId}` : t('transactionsModal.titleTable')}
								</Typography>
								<DateRangePicker onChange={updateTransactionListDates} cardIdForTransactions={id} sxContainer={{ mt: 1 }} />
							</>
						}>
						<Table
							data={transactions}
							columns={columns}
							isFetching={isFetching}
							pagination={pagination}
							onChangePagination={handleChangePagination}
							onChangeSorting={updateTransactionListSorting}
							tableContainerSx={styles.table}
							stickyHeader
							cardIdForTransactions={id}
							sortByDescSettlement
							tableType={TableType.History}
						/>
						{!displayId && isAdmin && (
							<Box sx={{ position: 'absolute', bottom: '15px', left: '25px' }}>
								<ExportToExcel
									options={generateExcelOptions(ReportType.AllTransactions, dates, account ? account : undefined)}
									saveMode={SaveMode.OnClick}
								/>
							</Box>
						)}
					</FixedTableHeight>
				</Box>
			</Modal>
		);
	},
);

TransactionsModal.displayName = 'TransactionsModal';

export default TransactionsModal;
