import { User } from '@firebase/auth';
import { IRolesAndPermissions } from '@interfaces/auth/i-roles-and-permissions';
import { IAction } from '@interfaces/redux/i-action';
import PlanType from '../../enums/plan-type';
import TYPES from './action-types';

/**
 * Begin authenticate
 */
export const login = (username: FormDataEntryValue | null, password: FormDataEntryValue | null): IAction<TYPES> => ({
	type: TYPES.LOGIN,
	payload: { username, password },
});

/**
 * Authenticate user via persist
 */
export const loginViaPersist = (): IAction<TYPES> => ({
	type: TYPES.LOGIN_VIA_PERSIST,
	payload: {},
});

/**
 * Authenticate success
 */
export const loginSuccess = (
	user: User,
	claims: IRolesAndPermissions,
	id: string,
	tenantId: string,
	initials: string,
	plan: PlanType,
	accountName: string,
	tenantName: string,
): IAction<TYPES> => ({
	type: TYPES.LOGIN_SUCCESS,
	payload: { user, claims, id, tenantId, initials, plan, accountName, tenantName },
});

/**
 * Authenticate error
 */
export const loginError = (message: string): IAction<TYPES> => ({
	type: TYPES.LOGIN_ERROR,
	payload: { message },
});

/**
 * Authenticate logout
 */
export const logout = (): IAction<TYPES> => ({
	type: TYPES.LOGOUT,
	payload: {},
});

/**
 * Authenticate logout success
 */
export const logoutSuccessAction = (): IAction<TYPES> => ({
	type: TYPES.LOGOUT_SUCCESS,
	payload: {},
});

/**
 * Clear store
 */
export const clearStore = (): IAction<TYPES> => ({
	type: TYPES.CLEAR_STORE,
	payload: {},
});
