import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	textField: {
		label: {
			'&[data-shrink="false"]': {
				transform: 'translate(14px,10px) scale(1)',
			},
		},
		'& .MuiFormHelperText-contained': {
			position: 'absolute',
			bottom: 0,
			left: 0,
			transform: 'translate(0, 100%)',
		},
	},
	input: {
		input: { padding: 1 },
		'&.MuiInputBase-multiline': { padding: 1 },

		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
};

export default styles as IStyles<typeof styles>;
