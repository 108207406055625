import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';

export const mapStateToProps = (state: IState) =>
	({
		userRoles: state.tenant.claims?.roles,
		isDisplayBonusCards: state.settings.result?.settings.displayBonusCards,
		enableTenantBilling: state.settings.result?.settings.enableTenantBilling,
		plan: state.tenant.plan,
	}) as const;

export const mapDispatchToProps = {};

export type IAdministrationStateToProps = ReturnType<typeof mapStateToProps>;

export type IAdministrationDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
