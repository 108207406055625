import React, { FC } from 'react';
import LoadingButton, { ILoadingButton } from '../loading-button';
import styles from './styles';

/**
 * SmallButton
 * @constructor
 */
const SmallButton: FC<ILoadingButton> = ({ children, ...props }) => (
	<LoadingButton variant="contained" {...props} sx={{ ...styles.button, ...(props?.sx ?? ({} as any)) }}>
		{children}
	</LoadingButton>
);

export default SmallButton;
