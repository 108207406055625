import type { SagaIterator } from '@redux-saga/core';
import { PaymentIntentResult } from '@stripe/stripe-js';
import type { Task } from 'redux-saga';
import { call, cancelled, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { configs } from '@helpers/get-env';
import roundNumber from '@helpers/round-number';
import { onUpdateEssenceSuccess, onUpdateListFork } from '@helpers/sagas';
import { IAccount } from '@interfaces/accounts/i-account';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import type { IAction } from '@interfaces/redux/i-action';
import { ITransaction } from '@interfaces/transactions/i-transaction';
import { CARDS_PAY_DIALOG } from '@pages/accounts/account/cards-pay';
import notistack from '@services/notistack-service';
import CARDS_ACTION_TYPES from '@store/accounts/action-types';
import { clearNewDeposit } from '@store/deposits/action-creators';
import { IDepositsState } from '@store/deposits/reducer';
import { updatePaymentMethod } from '@store/payment-methods/action-creators';
import { awaitUserToken } from '@store/tenant/sagas';
import PlanType from 'src/enums/plan-type';
import TransactionStatus from '../../enums/transaction-status';
import TransactionType from '../../enums/transaction-type';
import i18n from '../../i18n';
import { getAccount, getAccountSuccess } from '../accounts/action-creators';
import { getAccountApi } from '../accounts/api';
import { getCardsList } from '../cards/action-creators';
import {
	addPaymentsError,
	addPaymentsSuccess,
	createCreditIntegrationSuccess,
	deletePaymentError,
	deletePaymentSuccess,
	getPaymentsList,
	getPaymentsListError,
	getPaymentsListSuccess,
	getTaxRateInfoError,
	getTaxRateInfoSuccess,
	listenLastPaymentError,
	listenLastPaymentStart,
	listenLastPaymentStop,
	listenLastPaymentSuccess,
	setTypeListenerPay,
	updatePaymentError,
	updatePaymentSuccess,
	updateStatusPayCredit,
} from './action-creators';
import TYPES from './action-types';
import {
	createCreditIntegrationApi,
	createPaymentsApi,
	deletePaymentApi,
	getPaymentItemApi,
	getPaymentsListApi,
	getTaxRateApi,
	updatePaymentApi,
} from './api';
import type { IPaymentsState } from './reducer';

/**
 * Get payments list
 */
function* getPayments(): SagaIterator {
	try {
		yield call(awaitUserToken);
		const pagination: IPaymentsState['list']['pagination'] = yield select((state) => state.payments.list.pagination);
		const accountId = yield select((state) => state.accounts.item.result?.id);
		const { startDate, endDate } = yield select((state) => state.payments.list?.dates);
		const sorting = yield select((state) => state.payments.list?.sorting);
		const [sort, isDesc] = Object.entries(sorting ?? {})?.[0] ?? [];

		const result = yield call(
			getPaymentsListApi,
			{
				page: pagination?.page ?? 1,
				size: pagination.size,
				order: 1,
				relations: ['transactions'],
				...(sort !== 'undefined' && { sort, order: isDesc ? 'Descending' : 'Ascending' }),
				...(startDate && { startDate }),
				...(endDate && { endDate }),
			},
			accountId,
		);

		yield put(
			getPaymentsListSuccess(result.data, {
				page: Number(result.currentPage),
				total: result.count,
				size: pagination.size,
			}),
		);

		yield delay(2000);
		yield put(getCardsList());
		yield put(updateStatusPayCredit('payed'));
	} catch (e) {
		yield put(getPaymentsListError(e.message));
	}
}

/**
 * Update payment
 */
function* updatePayment({ payload: { id, newData, successCallback } }: IAction<TYPES>): SagaIterator {
	try {
		const result = yield call(updatePaymentApi, id, newData);

		yield put(updatePaymentSuccess(result));
		successCallback?.();
	} catch (e) {
		yield put(updatePaymentError(e.message));
	}
}

/**
 * Delete payment
 */
function* deletePayment(): SagaIterator {
	try {
		const accountId = yield select((state) => state.accounts.item.result?.id);
		let paymentId;

		while (!paymentId) {
			paymentId = yield call(getLastPaymentId, accountId);
		}

		if (accountId && paymentId) {
			yield call(deletePaymentApi, accountId, paymentId);
			yield put(deletePaymentSuccess());
			// yield put(listenLastPaymentSuccess(false));
			// notistack.success(i18n.t('paymentWasSuccessfullyCanceled'));
		}
	} catch (e) {
		yield put(deletePaymentError(e.message));
	}
}

/**
 * Add payments
 */
function* addPayments({ payload: { typePayment, callback, accountPlanName, toPayValue, discount } }: IAction<TYPES>): SagaIterator {
	try {
		const accountId = yield select((state) => state.accounts.item.result?.id);
		const stripePaymentId = yield select((state) => state.payments.creditIntegrationData.result?.stripePaymentId);
		const { deposit, customBonus, forEntity }: IDepositsState['item'] = yield select((state) => state.deposits.item);
		const tenantId = yield select((state) => state.tenant.tenantId);
		const taxPercentage = yield select((state) => state.payments.tax.result.percentage);
		const isCustomPlanType: boolean = accountPlanName === PlanType.Custom;
		const isCoopPlanType: boolean = accountPlanName === PlanType.Coop;

		const transactionPaymentName = 'Credit Card';
		let transactions;
		let paramsPaymentType = {};

		if (typePayment === PAYMENT_TYPE.SEND_INVOICE) {
			paramsPaymentType = {
				type: 'Uncollectible',
				integration: 'Invoice',
			};
		}

		if (typePayment === PAYMENT_TYPE.CREDIT_CARD) {
			paramsPaymentType = {
				type: 'Open',
				integration: 'Payment Intent',
				externalId: stripePaymentId,
			};
		}

		if (isCustomPlanType && forEntity === 'card') {
			transactions = Object.entries(deposit).reduce((result: Partial<ITransaction>[], [cardId, amount]) => {
				if (amount) {
					const tax = roundNumber((amount * taxPercentage) / 100);

					result.push({
						cardId,
						amount,
						bonus: customBonus[cardId],
						tax,
						name: transactionPaymentName,
						type: TransactionType.creditCard,
						status: TransactionStatus.Pending,
					});
				}

				return result;
			}, []);
		} else if (!isCustomPlanType && forEntity === 'account') {
			const tax = roundNumber((toPayValue * taxPercentage) / 100);

			let amount: number = toPayValue;
			let bonus = Number(discount);

			if (isCoopPlanType) {
				amount = deposit[accountId];
				bonus = customBonus[accountId];
			}

			transactions = [
				{
					accountId,
					amount,
					bonus,
					tax,
					name: transactionPaymentName,
					type: TransactionType.creditCard,
					status: TransactionStatus.Pending,
				},
			];
		}

		yield call(createPaymentsApi, accountId, {
			transactions,
			tax: taxPercentage,
			name: transactionPaymentName,
			...paramsPaymentType,
		});

		if (typePayment !== PAYMENT_TYPE.CREDIT_CARD) {
			const account = yield call(getAccountApi, tenantId, accountId);

			yield put(getAccountSuccess(account));
		}

		if (typePayment === PAYMENT_TYPE.SEND_INVOICE) {
			yield put(setTypeListenerPay('Open'));
			yield put(addPaymentsSuccess());
			yield put(clearNewDeposit());
			callback?.(CARDS_PAY_DIALOG.SUCCESS);
			yield put(getPaymentsList());
		}

		if (typePayment === PAYMENT_TYPE.CREDIT_CARD) {
			yield put(setTypeListenerPay('Processing'));
		}
	} catch (e) {
		yield put(addPaymentsError(e.message));
		callback?.(CARDS_PAY_DIALOG.ERROR);
	}
}

function* confirmPayment({
	payload: { useDefaultPaymentMethod, stripe, elements, isSavePaymentMethod, setDialogType, toPayValue, discount, setToPayValue, setMessage },
}: IAction<TYPES>): SagaIterator {
	let paymentIntentResult: PaymentIntentResult;
	const clientSecret = yield select((state) => state.payments.creditIntegrationData.result?.clientSecret);
	const paymentMethodId = yield select((state) => state.paymentMethod.item.result?.id);
	const account: IAccount = yield select((state) => state.accounts.item.result);

	const accountPlanName = account.accountPlan.plan.name;
	const accountId = account.id;
	const isCustomPlan = accountPlanName === PlanType.Custom;

	const action = {
		type: TYPES.ADD_PAYMENTS,
		payload: {
			typePayment: PAYMENT_TYPE.CREDIT_CARD,
			callback: setDialogType,
			accountPlanName,
			toPayValue,
			discount,
		},
	};

	yield call(addPayments, action);

	if (useDefaultPaymentMethod) {
		paymentIntentResult = yield call(stripe.confirmCardPayment, clientSecret ?? '', {
			payment_method: paymentMethodId ?? '',
		});
	} else {
		paymentIntentResult = yield call(stripe.confirmPayment, {
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: configs.REACT_APP_API_URL,
				save_payment_method: isSavePaymentMethod,
			},
			redirect: 'if_required',
		});
	}

	const { error, paymentIntent } = paymentIntentResult;

	if (error?.type === 'card_error' || error?.type === 'validation_error') {
		setMessage(error?.message);
	} else {
		setMessage('');
		setDialogType(CARDS_PAY_DIALOG.ERROR);
	}

	if (paymentIntent?.status === 'succeeded') {
		if (isSavePaymentMethod && accountId && typeof paymentIntent.payment_method === 'string') {
			yield put(updatePaymentMethod(paymentIntent.payment_method, accountId));
		}

		yield put(updateStatusPayCredit('succeeded'));
		yield put(clearNewDeposit());
		yield put(getPaymentsList());
		setDialogType(CARDS_PAY_DIALOG.SUCCESS);
		yield put(getCardsList());

		if (!isCustomPlan && accountId) {
			yield delay(4000);
			yield put(getAccount(accountId));
			setToPayValue(0);
		}
	}
}

function* createCreditIntegration({ payload: { accountPlanName } }: IAction<TYPES>): SagaIterator {
	const accountId = yield select((state) => state.accounts.item.result?.id);
	const { deposit, forEntity }: IDepositsState['item'] = yield select((state) => state.deposits.item);

	const isCustomPlanType: boolean = accountPlanName === PlanType.Custom;
	let transactionsSumAmount = 0;

	if (isCustomPlanType && forEntity === 'card') {
		transactionsSumAmount = Object.values(deposit).reduce((acc: number, amount) => (acc += amount), 0);
	} else if (!isCustomPlanType && forEntity === 'account') {
		transactionsSumAmount = deposit[accountId];
	}

	const creditIntegrationData = yield call(createCreditIntegrationApi, accountId, {
		transactionsSumAmount,
	});

	yield put(createCreditIntegrationSuccess(creditIntegrationData));
}

/**
 * Get last payment id
 */
function* getLastPaymentId(accountId: string): SagaIterator {
	try {
		// const typeSearch = yield select((state) => state.payments.typeListener);

		const result = yield call(
			getPaymentsListApi,
			{
				search: 'Open',
				column: 'type',
				page: 1,
				size: 1,
				order: 'Descending',
			},
			accountId,
		);

		return result?.data?.[0]?.id;
	} catch (e) {
		console.log(e);
	}
}

/**
 * Get last payment status
 */
function* getLastPaymentStatus(accountId: string, paymentId: string): SagaIterator {
	yield put(listenLastPaymentStart());

	try {
		while (true) {
			try {
				const result = yield call(getPaymentItemApi, accountId, paymentId, {
					relations: ['transactions'],
				});

				yield put(listenLastPaymentSuccess(Boolean(result?.type === 'Open') || Boolean(result?.type === 'Processing'), result?.transactions));
			} catch (e) {
				yield put(listenLastPaymentError(e.message));
			}

			yield delay(10000);
		}
	} finally {
		if (yield cancelled()) {
			yield delay(3000);

			const tenantId = yield select((state) => state.tenant.tenantId);
			const accId = yield select((state) => state.accounts.item.result?.id);

			if (accId) {
				const account: IAccount = yield call(getAccountApi, tenantId, accId);
				const accountPlan = account.accountPlan.plan.name;

				if (accountPlan !== PlanType.Pool) {
					yield put(getCardsList());
				}

				yield put(getAccountSuccess(account));
			}

			yield put(listenLastPaymentStop());
		}
	}
}

/**
 * Listen last payment
 */
function* listenLastPayment(): SagaIterator {
	let channel: Task | null = null;
	let paymentId: string | null = null;

	while (true) {
		const { success, open, addedPayments, close, deleted } = yield race({
			open: take(CARDS_ACTION_TYPES.GET_ACCOUNT_SUCCESS),
			close: take(CARDS_ACTION_TYPES.CLEAR_ACCOUNT),
			success: take(TYPES.LISTEN_LAST_PAYMENT_SUCCESS),
			addedPayments: take(TYPES.ADD_PAYMENTS_SUCCESS),
			deleted: take(TYPES.DELETE_PAYMENT_SUCCESS),
		});

		const accountId: string | undefined = yield select((state) => state.accounts.item.result?.id);

		if (accountId && !paymentId) {
			paymentId = yield call(getLastPaymentId, accountId);

			if (!paymentId) {
				yield put(listenLastPaymentStop());
			}
		}

		if ((open || addedPayments) && accountId && paymentId) {
			if (channel) {
				channel.cancel();
			}

			channel = yield fork(getLastPaymentStatus, accountId, paymentId);
		}

		if (channel && (close || deleted || success?.payload?.value === false)) {
			channel.cancel();
			paymentId = null;

			if (deleted) {
				yield put(listenLastPaymentSuccess(false));
				notistack.success(i18n.t('paymentWasSuccessfullyCanceled'));
			}
		}
	}
}

/**
 * On update payment success
 */
function* onUpdatePayment(): SagaIterator {
	yield call(onUpdateEssenceSuccess, 'payments', TYPES.UPDATE_PAYMENT_SUCCESS, getPaymentsList);
}

/**
 * On update pagination
 */
function* onUpdatePagination(): SagaIterator {
	yield call(onUpdateListFork, 'payments', 'pagination', TYPES.UPDATE_PAYMENTS_LIST_PAGINATION, getPaymentsList);
}

/**
 * On update sorting
 */
function* onUpdateSorting(): SagaIterator {
	yield call(onUpdateListFork, 'payments', 'sorting', TYPES.UPDATE_PAYMENTS_LIST_SORTING, getPaymentsList);
}

/**
 * On update dates
 */
function* onUpdateDates(): SagaIterator {
	yield call(onUpdateListFork, 'payments', 'dates', TYPES.UPDATE_PAYMENTS_LIST_DATES, getPaymentsList);
}

function* getTaxInfo(): SagaIterator {
	try {
		yield call(awaitUserToken);
		const taxInfo = yield call(getTaxRateApi);

		yield put(getTaxRateInfoSuccess(taxInfo));
	} catch (e) {
		yield put(getTaxRateInfoError(e.message));
	}
}

export default [
	takeLatest(TYPES.GET_PAYMENTS_LIST, getPayments),
	takeLatest(TYPES.UPDATE_PAYMENT, updatePayment),
	takeLatest(TYPES.DELETE_PAYMENT, deletePayment),
	takeLatest(TYPES.ADD_PAYMENTS, addPayments),
	takeLatest(TYPES.CONFIRM_PAYMENT, confirmPayment),
	takeLatest(TYPES.CREATE_CREDIT_INTEGRATION, createCreditIntegration),
	takeLatest(TYPES.GET_TAX_INFO, getTaxInfo),
	takeLatest(TYPES.CALL_LISTEN_LAST_PAYMENT, listenLastPayment),
	fork(onUpdatePayment),
	fork(onUpdatePagination),
	fork(onUpdateSorting),
	fork(onUpdateDates),
	fork(listenLastPayment),
];
