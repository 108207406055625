import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	modalCustomStyle: {
		width: { md: '50%', xs: '95%' },
		maxWidth: '695px',
	},
	title: {
		color: 'text.primary',
		textAlign: 'center',
		marginTop: 2,
	},
	container: {
		textAlign: 'center',
		margin: '30px 0',
	},
};

export default styles as IStyles<typeof styles>;
