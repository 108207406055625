const getDataIcons = (domain: string): Promise<any> =>
	import(`@assets/icons/${domain}/favicon.svg`)
		.then(async () => {
			const faviconDef = await import(`@assets/icons/${domain}/favicon.svg`);
			const manifestDef = await import(`@assets/icons/${domain}/manifest.json`);

			return {
				faviconPath: faviconDef?.default,
				manifestPath: manifestDef?.default,
			};
		})
		.catch(async () => {
			const faviconDef = await import(`@assets/icons/default/favicon.svg`);
			const manifestDef = await import(`@assets/icons/default/manifest.json`);

			return {
				faviconPath: faviconDef?.default,
				manifestPath: manifestDef?.default,
			};
		});

const getDomain = (): string => {
	const localAppMode = process.env.REACT_APP_MODE === 'local';
	const domain = localAppMode ? process.env.REACT_APP_TENANT_HOSTNAME || 'localhost' : document.location.hostname.split('.')[0];

	return domain;
};

const updateDomain = (): Promise<void> => {
	const domain = getDomain();
	const favicon: any = document.getElementById('favicon');
	const manifest: any = document.getElementById('manifest');

	return getDataIcons(domain).then((e) => {
		document.title = domain.charAt(0).toUpperCase() + domain.slice(1);
		favicon.href = e.faviconPath;
		manifest.href = e.manifestPath;
	});
};

export { getDomain, updateDomain };
