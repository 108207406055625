import { differenceInDays, parseISO } from 'date-fns';

const isOver45Days = (lastPaymentDate: string): boolean => {
	const parsedDate = parseISO(lastPaymentDate);
	const today = new Date();

	return differenceInDays(today, parsedDate) >= 45;
};

export default isOver45Days;
