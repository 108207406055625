/**
 * Format date
 */
const formatDate = (value: string) =>
	new Date(value).toLocaleDateString('en-US', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true,
	});

export default formatDate;
