/* eslint-disable @typescript-eslint/default-param-last */
import { IAccount } from '@interfaces/accounts/i-account';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import TYPES from './action-types';

export interface IAccountsState {
	list: IReducerState<IAccount[]> & ITableList;
	item: IReducerState<IAccount | null>;
	update: IReducerState<string | undefined>;
	delete: IReducerState<string | undefined>;
	add: IReducerState<undefined>;
	updateAutoCharge: IReducerState<undefined, undefined>;
}

const initState: IAccountsState = {
	list: {
		result: [],
		isFetching: false,
		error: null,
		searchValue: '',
		locationId: null,
		pagination: { total: 0, page: 1, size: 15 },
		sorting: {},
	},
	item: {
		result: null,
		isFetching: false,
		error: null,
	},
	update: {
		result: undefined, // loadingRowId
		isFetching: false,
		error: null,
	},
	delete: {
		result: undefined, // loadingRowId
		isFetching: false,
		error: null,
	},
	add: {
		result: undefined,
		isFetching: false,
		error: null,
	},
	updateAutoCharge: {
		result: undefined,
		isFetching: false,
		error: undefined,
	},
};

/**
 * Accounts reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
	switch (type) {
		// list
		case TYPES.GET_ACCOUNTS_LIST:
			return { ...state, list: { ...state.list, error: null, isFetching: true } };

		case TYPES.GET_ACCOUNTS_LIST_SUCCESS:
			return {
				...state,
				list: {
					...state.list,
					result: payload.list,
					pagination: payload.pagination,
					isFetching: false,
					error: null,
				},
			};

		case TYPES.GET_ACCOUNTS_LIST_ERROR:
			return { ...state, list: { ...state.list, isFetching: false, error: payload.message } };

		case TYPES.UPDATE_ACCOUNTS_LIST_PAGINATION:
			return {
				...state,
				list: {
					...state.list,
					pagination: {
						...state.list.pagination,
						...payload.pagination,
						...(payload.pagination.size && payload.pagination.size !== state.list.pagination.size && { page: 1 }),
					},
				},
			};

		case TYPES.UPDATE_ACCOUNTS_SEARCH:
			return { ...state, list: { ...state.list, searchValue: payload.value } };

		case TYPES.UPDATE_ACCOUNTS_SEARCH_BY_LOCATION:
			return { ...state, list: { ...state.list, locationId: payload.value } };

		case TYPES.UPDATE_ACCOUNTS_SORTING:
			return { ...state, list: { ...state.list, sorting: { [payload.name]: payload.desc } } };

		// item
		case TYPES.GET_ACCOUNT:
			return { ...state, item: { result: null, error: null, isFetching: true } };

		case TYPES.GET_ACCOUNT_SUCCESS:
			return {
				...state,
				item: { result: payload.item, isFetching: false, error: null },
			};

		case TYPES.GET_ACCOUNT_ERROR:
			return { ...state, item: { ...state.item, isFetching: false, error: payload.message } };

		case TYPES.CLEAR_ACCOUNT:
			return { ...state, item: { ...initState.item } };

		// update
		case TYPES.UPDATE_ACCOUNT:
			return {
				...state,
				update: { ...state.update, error: null, isFetching: true, result: payload.id },
			};

		case TYPES.UPDATE_ACCOUNT_SUCCESS:
			return {
				...state,
				update: { ...initState.update },
				item: { ...state.item, result: payload.item, error: null },
			};

		case TYPES.UPDATE_ACCOUNT_ERROR:
			return {
				...state,
				update: { ...state.update, isFetching: false, error: payload.message, result: undefined },
			};

		// delete
		case TYPES.DELETE_ACCOUNT:
			return {
				...state,
				delete: { ...state.delete, error: null, isFetching: true, result: payload.id },
			};

		case TYPES.DELETE_ACCOUNT_SUCCESS:
			return { ...state, delete: { ...initState.delete } };

		case TYPES.DELETE_ACCOUNT_ERROR:
			return {
				...state,
				delete: { ...state.delete, isFetching: false, error: payload.message, result: undefined },
			};

		// add
		case TYPES.ADD_ACCOUNT:
			return {
				...state,
				add: { ...state.add, error: null, isFetching: true },
			};

		case TYPES.ADD_ACCOUNT_SUCCESS:
			return { ...state, add: { ...initState.add } };

		case TYPES.ADD_ACCOUNT_ERROR:
			return {
				...state,
				add: { ...state.add, isFetching: false, error: payload.message },
			};

		// update auto-charge
		case TYPES.UPDATE_AUTO_CHARGE:
			return {
				...state,
				updateAutoCharge: { isFetching: true },
			};

		case TYPES.UPDATE_AUTO_CHARGE_SUCCESS:
			return {
				...state,
				item: {
					...state.item,
					result: {
						...state.item.result,
						accountPlan: { ...state.item.result?.accountPlan, autoCharge: payload.autoCharge },
					},
				},
				updateAutoCharge: { isFetching: false },
			};

		default:
			return state;
	}
};
