import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		width: '100%',
		height: '100%',
		textAlign: 'center',
		py: 2.5,
		px: 4,
		background: 'white',
		borderRadius: '8px',
		// position: 'fixed',
		// bottom: 0,

		'& p': {
			fontSize: '14px',
			wordSpacing: '-0.3px',
		},
	} as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
