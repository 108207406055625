import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	container: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		bgcolor: 'background.default',
		outline: 'unset',
		boxShadow: 24,
	},
	close: {
		position: 'absolute',
		top: 11,
		right: 13,
		cursor: 'pointer',
		color: ({ palette }) => (palette.mode === 'light' ? '#fff' : '#000'),
	} as SxProps<Theme>,

	closeWithoutTitle: {
		color: ({ palette }) => (palette.mode === 'light' ? '#000' : '#fff'),
	} as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
