import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import type { GridTypeMap } from '@mui/material/Grid/Grid';
import type { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, ChangeEventHandler, FC } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAutocomplete from '@components/custom-autocomplete';
import CustomPhoneNumberInput from '@components/custom-phone-number-input';
import InputType from '../../../enums/input-type';
import styles from './styles';

export interface IFormItem {
	inputType?: InputType;
	id: string;
	label: string;
	isDisabled?: boolean;
	options?: { label: string; value: string }[];
	grid?: Partial<GridTypeMap['props']>;
	InputProps?: Partial<StandardInputProps>;
	hasError?: boolean;
	isRequired?: boolean;
	helperText?: string;
	pattern?: RegExp;
	handleOpenAutocomplete?: () => any;
	handleCloseAutocomplete?: () => any;
	optionValueKeyForAutocomplete?: string;
	autocompleteType?: any;
	dataLocations?: any[];
	conditionForUser?: boolean;
}

/**
 * FormItem
 * @constructor
 */
const FormItem: FC<IFormItem & { defaultValue?: string | number }> = ({
	id,
	label,
	inputType,
	options,
	defaultValue,
	InputProps,
	hasError,
	helperText,
	isDisabled,
	isRequired,
	handleOpenAutocomplete,
	handleCloseAutocomplete,
	optionValueKeyForAutocomplete,
	dataLocations,
	conditionForUser,
}) => {
	const { t } = useTranslation();

	const typeError: any = helperText?.split(' ').slice(0);
	// eslint-disable-next-line
  const helpErrorText: any = helperText ? `${t(typeError)} ${helperText?.split(' ').slice(1).join(' ')}` : undefined;

	switch (inputType) {
		case InputType.Checkbox:
			return <FormControlLabel disabled={isDisabled} control={<Checkbox color="primary" name={id} />} label={t(label)} defaultValue={defaultValue} />;

		case InputType.Dropdown:
			return (
				<FormControl fullWidth disabled={isDisabled}>
					<InputLabel id={id}>{t(label)}</InputLabel>
					<Select labelId={id} id={id} label={t(label)} defaultValue={defaultValue}>
						{options?.map((option) => (
							<MenuItem key={option.label} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			);

		case InputType.Autocomplete:
			return (
				<CustomAutocomplete<any>
					id={id}
					label={label}
					optionValueKey={optionValueKeyForAutocomplete as string}
					handleCustomOpen={handleOpenAutocomplete}
					handleCustomClose={handleCloseAutocomplete}
					defaultValue={defaultValue}
					InputProps={InputProps}
					size="small"
					dataLocations={dataLocations}
					conditionForUser={conditionForUser}
					// getIdItem={(e: any) => getIdItem(e)}
				/>
			);

		case InputType.Phone:
			return (
				<CustomPhoneNumberInput
					id={id}
					name={id}
					label={`${t(label)}${isRequired ? ' *' : ''}`}
					onChange={
						InputProps?.onChange as ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> &
							((e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
					}
					fullWidth
					error={hasError}
					helperText={helpErrorText}
					disabled={isDisabled}
					sx={styles.textField}
					variant="outlined"
					value={defaultValue}
				/>
			);

		case InputType.Input:
		default:
			return (
				<TextField
					sx={styles.textField}
					disabled={isDisabled}
					InputProps={{ sx: styles.input, ...InputProps }}
					fullWidth
					autoComplete={id}
					name={id}
					id={id}
					label={`${t(label)}${isRequired ? ' *' : ''}`}
					defaultValue={defaultValue}
					error={hasError}
					helperText={helpErrorText}
				/>
			);
	}
};

export default FormItem;
