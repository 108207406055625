import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { LoadingButton, LoginBanner } from '@components/index';
import Loader from '@components/main-loader/loader';
import DynamicIcon from '@helpers/dynamis-icon';
import Domain from 'src/enums/domains';
import RoutePath from '../../enums/route-path';
import { getDomain } from '@helpers/domain-update';
import type { IForgotConfirmDispatchToProps, IForgotConfirmStateToProps } from './index.props';
import styles from './styles';

type IProps = IForgotConfirmStateToProps & IForgotConfirmDispatchToProps;

/**
 * Forgot page
 * @constructor
 */
const ForgotConfirm: FC<IProps> = ({
	forgotVerify,
	forgotConfirm,
	isConfirmed,
	isFetchingConfirm,
	isFetchingVerify,
	errorVerify,
	errorConfirm,
	verifiedEmail,
	oobCode,
	clearStore,
}) => {
	const { t } = useTranslation();
	const history = useHistory();
	const domain = getDomain() as Domain;
	const token = new URLSearchParams(document.location.search).get('token');
	const [isValidPassword, setIsValidPassword] = useState<boolean>(true);
	const [isValidConfirmPassword, setIsValidConfirmPassword] = useState<boolean>(true);
	const [newPassword, setNewPassword] = useState<string>('');
	const [confirmedNewPassword, setConfirmedNewPassword] = useState<string>('');
	const reg = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?])[A-Za-z\d-+_!@#$%^&*.,?]{6,16}$/);
	const [inputTypeNewPassword, setInputTypeNewPassword] = useState<'password' | 'text'>('password');
	const [inputTypeConfirmedNewPassword, setInputTypeConfirmedNewPassword] = useState<'password' | 'text'>('password');

	const forwardTo = useCallback(
		(url: string) => {
			history.push(url);
			clearStore();
		},
		[history, clearStore],
	);

	useEffect(() => {
		if (!token) {
			forwardTo(RoutePath.Login);
		}

		forgotVerify(token as string);
	}, [token, forgotVerify, forwardTo]);

	/**
	 * Submit form
	 */
	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			const isValid = reg.test(newPassword);
			const isValidConfirm = newPassword === confirmedNewPassword;

			setIsValidPassword(isValid);
			setIsValidConfirmPassword(isValidConfirm);

			if (isValid && isValidConfirm && oobCode) {
				forgotConfirm(newPassword, oobCode);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[newPassword, oobCode, confirmedNewPassword],
	);

	const handleOnChangePassword = (value: string) => {
		setNewPassword(value);
		setIsValidPassword(true);
		setIsValidConfirmPassword(true);
	};

	const handleOnChangeConfirmPassword = (value: string) => {
		setConfirmedNewPassword(value);
		setIsValidPassword(true);
		setIsValidConfirmPassword(true);
	};

	const description = useMemo(() => {
		const transProps: {
			i18nKey: string;
			values: {
				deviceLimit: string;
				email?: string;
			};
		} = {
			i18nKey: '',
			values: {
				deviceLimit: '',
			},
		};

		if (errorVerify || errorConfirm) {
			transProps.i18nKey = 'forgotConfirm.errorDescription';
			transProps.values.deviceLimit = 'forgotConfirm.errorDescription';
		} else {
			transProps.i18nKey = isConfirmed ? 'forgotConfirm.successDescription' : 'forgotConfirm.description';
			transProps.values.deviceLimit = isConfirmed ? 'forgotConfirm.successDescription' : 'forgotConfirm.description';
			transProps.values.email = verifiedEmail;
		}

		return (
			<Typography sx={styles.infoDescribe}>
				<Trans t={t} i18nKey={transProps.i18nKey} values={transProps.values} />
			</Typography>
		);
	}, [errorVerify, errorConfirm, verifiedEmail, isConfirmed, t]);

	const redirectButton = useMemo(() => {
		let btnText = '';
		let onClick;

		if (errorVerify || errorConfirm) {
			btnText = 'forgotConfirm.goToResetPassword';
			onClick = () => forwardTo(RoutePath.Forgot);
		}

		if (isConfirmed) {
			btnText = 'forgotConfirm.backToLogin';
			onClick = () => forwardTo(RoutePath.Login);
		}

		return (
			<LoadingButton fullWidth variant="contained" sx={styles.submit} onClick={onClick}>
				{t(btnText)}
			</LoadingButton>
		);
	}, [errorVerify, errorConfirm, isConfirmed, t, forwardTo]);

	const forgotForm = useMemo(
		() => (
			<Box component="form" onSubmit={handleSubmit} noValidate marginTop={1} width={1}>
				<TextField
					margin="normal"
					fullWidth
					id="newPassword"
					label={t('newPassword')}
					name="newPassword"
					error={!isValidPassword}
					onChange={(e) => handleOnChangePassword(e.target.value)}
					type={inputTypeNewPassword}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="end"
								onMouseDown={() => setInputTypeNewPassword('text')}
								onMouseUp={() => setInputTypeNewPassword('password')}
								onTouchStart={() => setInputTypeNewPassword('text')}
								onTouchEnd={() => setInputTypeNewPassword('password')}>
								{inputTypeNewPassword === 'password' ? <VisibilityOffOutlined cursor="pointer" /> : <VisibilityOutlined cursor="pointer" />}
							</InputAdornment>
						),
					}}
				/>
				{!isValidPassword && <Typography sx={styles.errorText}>{t('forgotConfirm.invalidPassword')}</Typography>}

				<TextField
					margin="normal"
					fullWidth
					id="confirmPassword"
					label={t('forgotConfirm.confirmNewPassword')}
					name="confirmPassword"
					error={!isValidConfirmPassword && isValidPassword}
					onChange={(e) => handleOnChangeConfirmPassword(e.target.value)}
					type={inputTypeConfirmedNewPassword}
					InputProps={{
						endAdornment: (
							<InputAdornment
								position="end"
								onMouseDown={() => setInputTypeConfirmedNewPassword('text')}
								onMouseUp={() => setInputTypeConfirmedNewPassword('password')}
								onTouchStart={() => setInputTypeConfirmedNewPassword('text')}
								onTouchEnd={() => setInputTypeConfirmedNewPassword('password')}>
								{inputTypeConfirmedNewPassword === 'password' ? <VisibilityOffOutlined cursor="pointer" /> : <VisibilityOutlined cursor="pointer" />}
							</InputAdornment>
						),
					}}
				/>
				{!isValidConfirmPassword && isValidPassword && <Typography sx={styles.errorText}>{t('forgotConfirm.notMatchPassword')}</Typography>}
				<LoadingButton type="submit" fullWidth variant="contained" sx={styles.submit} isFetching={isFetchingConfirm}>
					{t('submit')}
				</LoadingButton>

				<Typography sx={styles.infoDescribe}>{t('forgotConfirm.passwordRules')}</Typography>
			</Box>
		),
		[handleSubmit, inputTypeConfirmedNewPassword, inputTypeNewPassword, isFetchingConfirm, isValidConfirmPassword, isValidPassword, t],
	);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{isFetchingVerify || (!verifiedEmail && !errorVerify) ? (
				<Loader />
			) : (
				<Box component="main" sx={styles.wrapper}>
					<LoginBanner />
					<Box sx={styles.bannerDark} />
					<Container maxWidth="xs" sx={styles.container}>
						<Paper elevation={6}>
							<Box sx={styles.box} padding={2}>
								<DynamicIcon domain={domain} height="150px" />
								{description}
								{isConfirmed || errorVerify || errorConfirm ? redirectButton : forgotForm}
							</Box>
						</Paper>
					</Container>
				</Box>
			)}
		</>
	);
};

export default ForgotConfirm;
