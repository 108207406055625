const styles = {
	addWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
	},
	button: {
		marginTop: 0,
		width: '100%',
	},
	buttonWrapper: { display: 'flex', width: '100%', justifyContent: 'flex-end' },
	initWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		overflowX: 'auto',
		gap: 2,
	},
	loaderModalWrapper: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'auto',
	},
};

export default styles;
