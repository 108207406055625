import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		pt: '17px',
		pb: '13.5px',
	},

	container: {
		display: 'flex',
		alignItems: 'center',
		width: '125px',
	},

	title: {
		fontSize: '12px',
		color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),

		'& p': {
			color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
		},
	} as SxProps<Theme>,

	// todo Create custom switch component and replace this style
	switchActive: {
		height: 33,
		'& .MuiSwitch-thumb': {
			width: 16,
			height: 16,
		},

		'& .MuiSwitch-track': {
			width: 33,
			height: 12,
		},

		'& .Mui-checked': {
			top: 1,
		},
	},
};

export default styles as IStyles<typeof styles>;
