import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import {
	updateAccountsSearch,
	updateAccountsListPagination,
	updateAccountsSorting,
	getAccountsList,
	updateAccountsSearchByLocation,
} from '@store/accounts/action-creators';
import { getLocationsList } from '@store/locations/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		accounts: state.accounts.list.result,
		pagination: state.accounts.list.pagination,
		isFetching: state.accounts.list.isFetching,
		isFetchingFirebase: state.tenant.isFetching,
		searchValue: state.accounts.list.searchValue,
		locationList: state.locations.list.result,
		coopThresholdAmount: state.settings.result?.settings.coopThresholdAmount,
		coopThresholdColour: state.settings.result?.settings.coopThresholdColour,
	}) as const;

export const mapDispatchToProps = {
	getAccountsList,
	updateAccountsSearch,
	updateAccountsListPagination,
	updateAccountsSorting,
	getLocationsList,
	updateAccountsSearchByLocation,
};

export type IAccountsStateToProps = ReturnType<typeof mapStateToProps>;

export type IAccountsDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
