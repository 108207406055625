import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import React, { useCallback, useMemo, useState } from 'react';
import { IFormItem } from '@components/form-item';
import { IValidation } from '@interfaces/form/i-validation';
import InputType from '../../enums/input-type';

/**
 * Use form with validation hook
 */
function useForm<T extends Record<string, any>>(formData: IFormItem[], successCallback: (data: T) => void, additionalErrors?: IValidation) {
	const [validation, setValidation] = useState<IValidation>({});

	const clearValidation = useCallback(() => {
		setValidation({});
	}, []);

	/**
	 * Validate form
	 */
	const validateForm = useCallback(
		(values: T) => {
			const newErrors = Object.entries(values).reduce((res: IValidation, [key, value]) => {
				const params = formData.find((v) => v.id === key);

				if (params?.isRequired && !value) {
					res[key] = { hasError: true, helperText: `${params.label} is required` };
				}

				if (value && !res?.[key] && params?.pattern && !params.pattern.test(value)) {
					res[key] = { hasError: true, helperText: `${params.label} is invalid` };
				}

				if (
					value &&
					params?.inputType === InputType.Phone &&
					(typeof value !== 'string' || !isPossiblePhoneNumber(value) || !isValidPhoneNumber(value))
				) {
					res[key] = { hasError: true, helperText: `${params.label} is invalid` };
				}

				return res;
			}, {} as IValidation);

			setValidation(newErrors);

			return !Object.keys(newErrors).length;
		},
		[formData],
	);

	/**
	 * Submit form
	 */
	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const data = new FormData(event.currentTarget);
			const result: Record<string, any> = {};

			data.forEach((val, key) => (result[key] = String(val).trim()));

			const isValidationPassed = validateForm(result as T);

			if (!isValidationPassed) {
				return;
			}

			successCallback(result as T);
		},
		[successCallback, validateForm],
	);

	return useMemo(
		() => ({ validation: { ...validation, ...additionalErrors }, handleSubmit, clearValidation }),
		[additionalErrors, handleSubmit, validation, clearValidation],
	);
}

export default useForm;
