/**
 * Get summary for array values
 */
function getSummary<T = number | Record<string, number>>(array: T[], key?: string): number {
	return array.reduce((res: number, val) => {
		const value: number = key && typeof val === 'object' ? (val as any)[key] : Number(val);

		res += value;

		return res;
	}, 0);
}

export default getSummary;
