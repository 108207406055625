import { IAccount } from '@interfaces/accounts/i-account';
import { IAction } from '@interfaces/redux/i-action';
import { IPagination } from '@interfaces/redux/i-pagination';
import TYPES from './action-types';
import { IAccountsState } from './reducer';

/**
 * Get accounts list
 */
export const getAccountsList = (): IAction<TYPES> => ({
	type: TYPES.GET_ACCOUNTS_LIST,
	payload: {},
});

/**
 * Get accounts list success
 */
export const getAccountsListSuccess = (list: IAccountsState['list']['result'], pagination?: IPagination): IAction<TYPES> => ({
	type: TYPES.GET_ACCOUNTS_LIST_SUCCESS,
	payload: { list, pagination },
});

/**
 * Get accounts list error
 */
export const getAccountsListError = (message: string): IAction<TYPES> => ({
	type: TYPES.GET_ACCOUNTS_LIST_ERROR,
	payload: { message },
});

/**
 * Update accounts list pagination
 */
export const updateAccountsListPagination = (pagination: Partial<IPagination>): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNTS_LIST_PAGINATION,
	payload: { pagination },
});

/**
 * Update accounts search value
 */
export const updateAccountsSearch = (value: string | number): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNTS_SEARCH,
	payload: { value },
});

/**
 * Update accounts search by location
 */
export const updateAccountsSearchByLocation = (value: string | number): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNTS_SEARCH_BY_LOCATION,
	payload: { value },
});

/**
 * Update accounts sorting
 */
export const updateAccountsSorting = (name?: string, desc?: boolean): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNTS_SORTING,
	payload: { name, desc },
});

/**
 * Get account
 */
export const getAccount = (id: string): IAction<TYPES> => ({
	type: TYPES.GET_ACCOUNT,
	payload: { id },
});

/**
 * Get account success
 */
export const getAccountSuccess = (item: IAccountsState['item']['result']): IAction<TYPES> => ({
	type: TYPES.GET_ACCOUNT_SUCCESS,
	payload: { item },
});

/**
 * Get account error
 */
export const getAccountError = (message: string): IAction<TYPES> => ({
	type: TYPES.GET_ACCOUNT_ERROR,
	payload: { message },
});

/**
 * Clear account data
 */
export const clearAccount = (): IAction<TYPES> => ({
	type: TYPES.CLEAR_ACCOUNT,
	payload: {},
});

/**
 * Update account
 */
export const updateAccount = (id: string, newData: Partial<IAccount>, successCallback?: () => void): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNT,
	payload: { id, newData, successCallback },
});

/**
 * Update account success
 */
export const updateAccountSuccess = (item: IAccount): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNT_SUCCESS,
	payload: { item },
});

/**
 * Update account error
 */
export const updateAccountError = (message: string): IAction<TYPES> => ({
	type: TYPES.UPDATE_ACCOUNT_ERROR,
	payload: { message },
});

/**
 * Delete account
 */
export const deleteAccount = (id: string, successCallback?: () => void): IAction<TYPES> => ({
	type: TYPES.DELETE_ACCOUNT,
	payload: { id, successCallback },
});

/**
 * Delete account success
 */
export const deleteAccountSuccess = (): IAction<TYPES> => ({
	type: TYPES.DELETE_ACCOUNT_SUCCESS,
	payload: {},
});

/**
 * Delete account error
 */
export const deleteAccountError = (message: string): IAction<TYPES> => ({
	type: TYPES.DELETE_ACCOUNT_ERROR,
	payload: { message },
});

/**
 * Add account
 */
export const addAccount = (
	item: IAccount & { billingDay?: number; planId: string },
	isSendPasswordEmail: boolean,
	successCallback?: (id: string) => void,
): IAction<TYPES> => ({
	type: TYPES.ADD_ACCOUNT,
	payload: { item, successCallback, isSendPasswordEmail },
});

/**
 * Add account success
 */
export const addAccountSuccess = (): IAction<TYPES> => ({
	type: TYPES.ADD_ACCOUNT_SUCCESS,
	payload: {},
});

/**
 * Add account error
 */
export const addAccountError = (message: string): IAction<TYPES> => ({
	type: TYPES.ADD_ACCOUNT_ERROR,
	payload: { message },
});

export const updateAutoCharge = (accountId: string, autoCharge: boolean): IAction<TYPES> => ({
	type: TYPES.UPDATE_AUTO_CHARGE,
	payload: { accountId, autoCharge },
});

export const updateAutoChargeSuccess = (autoCharge: boolean): IAction<TYPES> => ({
	type: TYPES.UPDATE_AUTO_CHARGE_SUCCESS,
	payload: { autoCharge },
});
