import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { forgotConfirm, forgotVerify, clearStore } from '@store/forgot/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		errorVerify: state.forgot.verifiedData.error,
		isFetchingVerify: state.forgot.verifiedData.isFetching,
		verifiedEmail: state.forgot.verifiedData.result?.email,
		oobCode: state.forgot.verifiedData.result?.oobCode,
		errorConfirm: state.forgot.isConfirmed.error,
		isFetchingConfirm: state.forgot.isConfirmed.isFetching,
		isConfirmed: state.forgot.isConfirmed.result,
	}) as const;

export const mapDispatchToProps = {
	forgotConfirm,
	forgotVerify,
	clearStore,
};

export type IForgotConfirmStateToProps = ReturnType<typeof mapStateToProps>;

export type IForgotConfirmDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
