import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormItem, { IFormItem } from '@components/form-item';
import SmallButton from '@components/small-button';
import useForm from '@hooks/use-form';
import styles from './styles';

interface Props<T> {
	defaultFields: T;
	formData: IFormItem;
	isFetching: boolean;
	successCallback: (data: T) => void;
}
const OneInputForm = <T extends Record<string, string>>({ defaultFields, formData, isFetching, successCallback }: Props<T>) => {
	const { t } = useTranslation();
	const [isEditState, setIsEditState] = useState<boolean>(false);
	const [values, setValues] = useState<T>(defaultFields);
	const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(defaultFields[formData.id] === values[formData.id]);

	useEffect(() => {
		setIsEditState(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultFields[formData.id]]);

	const { validation, handleSubmit, clearValidation } = useForm<T>([formData], successCallback);

	const renderActionButton = useMemo(() => {
		if (isEditState) {
			return (
				<>
					<Grid item xs={6}>
						<SmallButton variant="outlined" onClick={() => setIsEditState(false)} sx={styles.button}>
							{t('cancel')}
						</SmallButton>
					</Grid>
					<Grid item xs={6}>
						<SmallButton type="submit" isFetching={isFetching} disabled={isDisableSubmit} sx={styles.button}>
							{t('save')}
						</SmallButton>
					</Grid>
				</>
			);
		}

		return (
			<Grid item xs={6}>
				<SmallButton onClick={() => setIsEditState(true)} variant="outlined" sx={styles.button}>
					{t('edit')}
				</SmallButton>
			</Grid>
		);
	}, [isEditState, t, isFetching, isDisableSubmit]);

	const onChange = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (!e) {
			return;
		}

		if (defaultFields[formData.id] !== e.target.value) {
			setIsDisableSubmit(false);
		}

		if (formData.id === 'email' && formData.pattern) {
			setIsDisableSubmit(!new RegExp(formData.pattern).test(e.target.value));
		}

		setValues({ [formData.id]: e.target.value } as T);

		if (clearValidation) {
			clearValidation();
		}
	};

	return (
		<Box component="form" onSubmit={handleSubmit} sx={styles.formWrapper}>
			<Box sx={{ width: '50%' }}>
				<FormItem
					{...formData}
					defaultValue={defaultFields?.[formData.id]}
					InputProps={{ ...formData.InputProps, onChange }}
					{...validation?.[formData.id]}
					isDisabled={!isEditState}
				/>
			</Box>
			<Grid container columnSpacing={2} width={'50%'} justifyContent={'flex-end'}>
				{renderActionButton}
			</Grid>
		</Box>
	);
};

export default OneInputForm;
