import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { login } from '@store/tenant/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		isAuth: state.tenant.claims,
		error: state.tenant.error,
		isFetching: state.tenant.isFetching,
		userRoles: state.tenant.claims?.roles,
		userId: state.tenant.id,
		enableUserPortal: state.settings.result?.settings.enableUserPortal,
		contactRedirect: state.settings.result?.settings.contactRedirect,
	}) as const;

export const mapDispatchToProps = {
	login,
};

export type ILoginStateToProps = ReturnType<typeof mapStateToProps>;

export type ILoginDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
