import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

interface IProps {
	emailValue: string;
}

const ForgetSuccessfullySend: FC<IProps> = ({ emailValue }) => {
	const { t } = useTranslation();

	return (
		<Box textAlign="center">
			<Box mt={1} sx={styles.mainText}>
				<Typography color="text.primary">{t('forgetSuccessfullySend.mainText_p1')}</Typography>
				<Typography color="text.primary">{emailValue}</Typography>
				<Typography color="text.primary">{t('forgetSuccessfullySend.mainText_p2')}</Typography>
			</Box>
			<Box mt={5} sx={styles.secondaryText}>
				<Typography color="text.primary">{t('forgetSuccessfullySend.secondaryText_p1')},</Typography>
				<Typography color="text.primary">{t('forgetSuccessfullySend.secondaryText_p2')}.</Typography>
			</Box>
		</Box>
	);
};

export default ForgetSuccessfullySend;
