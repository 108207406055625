import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';

export const mapStateToProps = (state: IState) =>
	({
		stripePublic: state.settings.result?.settings.stripePublic,
		clientSecret: state.payments.creditIntegrationData.result?.clientSecret,
		stripePublicGlobal: state.settings.result?.globalSettings.stripePublic,
	}) as const;

export const mapDispatchToProps = {};

export type IStripeElementsStateToProps = ReturnType<typeof mapStateToProps>;

export type IStripeElementsDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
