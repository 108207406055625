import TextField from '@mui/material/TextField';
import { Box } from '@mui/system';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '@components/form-item/styles';
import { ICard } from '@interfaces/cards/i-card';
import { ITransaction } from '@interfaces/transactions/i-transaction';

interface IProps {
	initialValue: string | null;
	card: ICard;
	updateCard: (id: string, value: Record<string, string | null>) => void;
	listenTransactions: ITransaction[] | null;
	sxInput?: any;
}

const EditableInputCard: FC<IProps> = ({ initialValue, card, updateCard, listenTransactions, sxInput }) => {
	const [value, setValue] = useState(initialValue);
	const isFetchingAccountUpdate = useSelector((state) => state.accounts.update.isFetching);
	const isFetchingCards = useSelector((state) => state.cards.list.isFetching);

	/**
	 * On change cell value
	 */
	const onChange = (e: any) => setValue(e.target.value);

	/**
	 * Update the external data when the input is blurred
	 */
	const onBlur = () => {
		if (initialValue === value) {
			return;
		}

		updateCard(card.id, { extra: value });
	};

	/**
	 * If the initialValue is changed external, sync it up with our state
	 */
	useEffect(() => setValue(initialValue), [initialValue]);

	const isDisabled =
		!card.active ||
		isFetchingAccountUpdate ||
		isFetchingCards ||
		Boolean(listenTransactions?.find(({ cardId }: { cardId: number | string }) => cardId === card.id));

	return (
		<Box sx={{ margin: '6px 0' }}>
			<TextField disabled={isDisabled} InputProps={{ sx: { ...styles.input, ...sxInput } }} value={value} onChange={onChange} onBlur={onBlur} />
		</Box>
	);
};

export default EditableInputCard;
