import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { bonusCardClear } from '@store/bonus-cards/action-creators';
import { deleteCard, updateCard, getCardsList } from '@store/cards/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		deleteFetchingRowId: state.cards.delete.result,
		updateFetchingRowId: state.cards.update.result,
		userRoles: state.tenant.claims?.roles,
		listenTransactions: state.payments.listen.transactions,
		isDisplayBonusCards: state.settings.result?.settings.displayBonusCards,
		isFetchingAccountUpdate: state.accounts.update.isFetching,
		isAccountActive: state.accounts.item.result?.active,
	}) as const;

export const mapDispatchToProps = {
	deleteCard,
	updateCard,
	bonusCardClear,
	getCardsList,
};

export type ICardActionsCellStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardActionsCellDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
