import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Modal, TitledBlock, ForgetSuccessfullySend, CreateCustomPlanUser } from '@components/index';
import { IModalForward } from '@components/modal';
import CustomTooltip from '@components/tooltip';
import { request } from '@helpers/request';
import { IValidation } from '@interfaces/form/i-validation';
import { forgotSendEmail } from '@store/forgot/action-creators';
import { getLocationsList } from '@store/locations/action-creators';
import FormItem, { IFormItem } from '../form-item';
import SmallButton from '../small-button';
import styles from './styles';

interface IFormSection {
	type?: string;
	defaultFields?: Record<string, any>;
	userFormData: IFormItem[];
	userFormContactInfo: IFormItem[];
	title: string;
	withMargin?: boolean;
	isFetching?: boolean;
	isFetchingInit?: boolean;
	isDisabled?: boolean;
	validation?: IValidation;
	clearValidation?: () => void;
	changePassword?: boolean;
	successCallback?: any;
	getListPlan?: any;
	accountId?: any;
	conditionForUser?: boolean;
	isSendPasswordEmail?: boolean;
	setIsSendPasswordEmail?: React.Dispatch<React.SetStateAction<boolean>>;
	isLargeAccountForm?: boolean;
}

/**
 * FormSection
 * @constructor
 */
const FormSection: FC<IFormSection> = ({
	type,
	defaultFields,
	title,
	userFormData,
	withMargin,
	isFetching,
	isFetchingInit,
	userFormContactInfo,
	isDisabled,
	validation = {},
	clearValidation,
	changePassword,
	successCallback,
	accountId,
	conditionForUser,
	isSendPasswordEmail,
	setIsSendPasswordEmail,
	isLargeAccountForm,
}) => {
	const { t } = useTranslation();
	const [values, setValues] = useState<Record<string, string>>({});
	const [isDisabledSendPasswordEmail, setIsDisabledSendPasswordEmail] = useState<boolean>(true);

	const dispatch = useDispatch();
	const history = useHistory();
	const dataLocations: any[] = useSelector((state) => state.locations.list.result);
	const isUserPortalEnable: boolean | undefined = useSelector((state) => state.settings.result?.settings.enableUserPortal);

	const isCreateNewAccount = history.location.pathname.split('/').pop() === 'add';

	/**
	 * Set data changes to state for compare
	 */
	const onChange = (e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
		if (!e) {
			return;
		}

		if (clearValidation) {
			clearValidation();
		}

		if (typeof e === 'string') {
			setValues((prevState) => ({ ...prevState, ['phone']: e }));

			return;
		}

		setValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

		if (e.target.name === 'email') {
			setIsDisabledSendPasswordEmail(!new RegExp(/^\S+@\S+\.\S+$/).test(e.target.value));
		}
	};

	const isChangePasswordDisabled = !defaultFields?.email || (defaultFields.email !== values.email && values.email !== undefined);

	/**
	 * Add submit button disabled if form values were not edited
	 */
	const isInitialDisabled =
		!Object.values(values) ||
		Object.entries(values).reduce((res: boolean, [key, value]) => {
			if (!res) {
				return false;
			}

			if (value === '' && defaultFields?.[key] === value) {
				return res;
			}

			if ((value && defaultFields?.[key] !== value) || (value === '' && defaultFields?.[key] !== value)) {
				res = false;
			}

			return res;
		}, true);

	const handleOpenAutocomplete = useCallback(() => dispatch(getLocationsList()), [dispatch]);
	/**
	 * Render form item
	 */
	const renderFormItem = (item: IFormItem) => (
		<Grid key={item.id} item xs={12} {...item.grid}>
			<FormItem
				{...item}
				handleOpenAutocomplete={handleOpenAutocomplete}
				defaultValue={defaultFields?.[item.id]}
				isDisabled={isDisabled}
				InputProps={{ ...item.InputProps, onChange }}
				dataLocations={dataLocations}
				conditionForUser={conditionForUser}
				{...validation?.[item.id]}
			/>
		</Grid>
	);
	const modalRef = useRef<IModalForward | null>(null);
	const modalPlanRef = useRef<IModalForward | null>(null);
	const [planList, setPlanList] = useState<any[]>([]);

	useEffect(() => {
		if (type === 'add') {
			void request('plans').then((resp: any) => setPlanList(resp));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getLocationsList());
		// eslint-disable-next-line
  }, []);

	/**
	 * Send forgot email
	 */
	const toggleModal = useCallback(() => modalRef.current?.toggleModal(), []);
	const toggleOpenPlan = useCallback(() => modalPlanRef.current?.toggleModal(), []);

	const sendForgotEmail = () => {
		dispatch(forgotSendEmail(defaultFields?.email));
		toggleModal();
	};

	const toggleModalPlan = useCallback(
		(props) => {
			successCallback(props);
			modalPlanRef.current?.toggleModal();
		},
		[successCallback],
	);

	const handleSave = () => {
		if (!accountId) {
			toggleOpenPlan();
		}
	};

	useEffect(() => {
		// eslint-disable-next-line
    if (Object.keys(validation).length > 0) {
			if (!accountId) {
				toggleOpenPlan();
			}
		}
		// eslint-disable-next-line
  }, [validation]);

	return (
		<TitledBlock title={title} withMargin={withMargin} sxWrapper={{ height: '100%' }} styleTitle={{ px: '29px' }}>
			<Box px={1.65}>
				<Grid container spacing={3}>
					{isFetchingInit ? (
						<Box sx={styles.fetchingInitBox} height={(userFormData.length + userFormContactInfo.length) * 63 + 39}>
							<CircularProgress color="inherit" />
						</Box>
					) : (
						<>
							{userFormData.map(renderFormItem)}
							{isLargeAccountForm && <Typography sx={styles.contactSubTitle}>{t('userForm.contact')}</Typography>}

							{userFormContactInfo?.map(renderFormItem)}
						</>
					)}
				</Grid>
				{isCreateNewAccount && isUserPortalEnable && (
					<Box
						sx={{
							marginTop: { xs: 1.5, sm: 3 },
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
						}}>
						<Typography fontSize={{ xs: '14px' }} noWrap>
							{t('userForm.sendPasswordEmail')}
						</Typography>
						<CustomTooltip
							title={`${t('userForm.sendPasswordEmailTooltip')}`}
							showForDisableChildren={isDisabledSendPasswordEmail}
							hideForEnableChildren={!isDisabledSendPasswordEmail}>
							<Switch
								disabled={isDisabledSendPasswordEmail}
								checked={isSendPasswordEmail}
								onChange={(_, checked) => {
									if (setIsSendPasswordEmail) {
										setIsSendPasswordEmail(checked);
									}
								}}
							/>
						</CustomTooltip>
					</Box>
				)}
				{!isFetchingInit && (
					<Box sx={changePassword && isUserPortalEnable ? styles.rowBetween : styles.rowEnd}>
						{changePassword && isUserPortalEnable && (
							<SmallButton onClick={sendForgotEmail} disabled={isChangePasswordDisabled} sx={styles.buttonsWidth}>
								{t('userForm.changePassword')}
							</SmallButton>
						)}
						<SmallButton
							sx={styles.buttonsWidth}
							type="submit"
							onClick={handleSave}
							isFetching={isFetching}
							disabled={isDisabled || isInitialDisabled}>
							{t('userForm.save')}
						</SmallButton>
					</Box>
				)}
				<Modal ref={modalRef} width={{ xs: '100%', sm: 400 }} height={350}>
					<Box mt={15}>
						<ForgetSuccessfullySend emailValue={defaultFields?.email} />
					</Box>
				</Modal>

				<Modal ref={modalPlanRef} width={400} height={330}>
					<CreateCustomPlanUser
						handleClose={toggleModalPlan}
						handleCancel={() => modalPlanRef.current?.toggleModal()}
						planList={planList}
						accountInfo={values}
					/>
				</Modal>
			</Box>
		</TitledBlock>
	);
};

export default React.memo(FormSection);
