import type { IFormItem } from '@components/form-item';
import regex from '@constants/regex';
import InputType from '../../../enums/input-type';

export const userFormData: IFormItem[] = [
	{
		id: 'name',
		label: 'accountName',
		isRequired: true,
	},
	{
		id: 'note',
		label: 'note',
		InputProps: { multiline: true, maxRows: 3 },
	},
];

export const userFormContactInfo: IFormItem[] = [
	{
		id: 'contact',
		label: 'userName',
		isRequired: true,
	},
	{
		id: 'phone',
		label: 'userPhone',
		inputType: InputType.Phone,
	},
	{
		id: 'email',
		label: 'email',
		pattern: regex.email,
		isRequired: true,
	},
];
