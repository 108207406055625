import { Button, ClickAwayListener, Fade, Paper, Popper, PopperPlacementType, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { FC, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellProps } from 'react-table';
import { ReactComponent as DotsIcon } from '@assets/images/icons/dots.svg';
import { ConfirmationPopper, TransactionsModal, Modal, TitledBlock } from '@components/index';
import type { IModalForward } from '@components/modal';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import RoleType from '../../../../../enums/role-type';
import ModalBalanceAdjustment from './modals/modal-balace-adjust/index.store';
import ModalTransferAnotherCard from './modals/modal-transfer-another/index.store';
import ModalTransferBonusCard from './modals/modal-transfer-bonus/index.store';
import { ModalRemoveCard } from './modals/modals';
import NotesEdit, { INotesEditForward } from './notes-edit';
import type { ICardActionsCellDispatchToProps, ICardActionsCellStateToProps } from './index.props';
import styles from './styles';

type IProps = CellProps<any> & ICardActionsCellStateToProps & ICardActionsCellDispatchToProps & { isCustomPlan: boolean };

/**
 * CardActionsCell
 * @constructor
 */
const CardActionsCell: FC<IProps> = ({
	row: {
		original: { id, notes, name, balance, active },
	},
	deleteFetchingRowId,
	updateFetchingRowId,
	deleteCard,
	updateCard,
	userRoles,
	bonusCardClear,
	isCustomPlan,
	listenTransactions,
	isDisplayBonusCards,
	isFetchingAccountUpdate,
	isAccountActive,
}) => {
	const { t } = useTranslation();
	const [isOpen, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [placement, setPlacement] = useState<PopperPlacementType>();

	const modalRef = useRef<IModalForward | null>(null);
	const modalTransferRef = useRef<IModalForward | null>(null);
	const modalBonusRef = useRef<IModalForward | null>(null);
	const modalBalanceAdjustRef = useRef<IModalForward | null>(null);

	const notesRef = useRef<INotesEditForward | null>(null);
	const transactionsModal = useRef<IModalForward | null>(null);
	const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);

	const handleClick = (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => placement !== newPlacement || !prev);
		setPlacement(newPlacement);
	};

	const isDeleteDisabled = !((isAllowedForAdmin && balance === 0) || (isCustomPlan && balance === 0) || (!isCustomPlan && balance === 10000));
	const isHasBalance = balance !== 0;
	const isTransactionCard = listenTransactions?.find((elem) => elem.cardId === id);

	const toggleModalBonus = useCallback(() => modalBonusRef.current?.toggleModal(), []);

	const handleCloseBonusTransfer = useCallback(() => {
		bonusCardClear();
	}, [bonusCardClear]);

	const toggleModalTransfer = useCallback(() => modalTransferRef.current?.toggleModal(), []);

	const toggleModal = useCallback(() => modalRef.current?.toggleModal(), []);

	const toggleBalanceAdjust = useCallback(() => modalBalanceAdjustRef.current?.toggleModal(), []);

	const handleRemoveCard = (close: any) => (isHasBalance ? toggleModal() : deleteCard(id, close));

	const stylePopupText = {
		...styles.textPopup,
		...(isDeleteDisabled && styles.disabled),
	};

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<Box sx={styles.boxWrapper}>
				<Popper open={isOpen} anchorEl={anchorEl} placement={placement} transition>
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={200}>
							<Paper>
								<Typography sx={styles.textPopup} onClick={() => transactionsModal.current?.toggleModal()}>
									{t('history')}
								</Typography>
								{isAllowedForAdmin && (
									<Box sx={styles.icon}>
										<ConfirmationPopper
											body={<NotesEdit ref={notesRef} defaultNote={notes} />}
                      onSubmit={(close) => updateCard(id, { notes: notesRef.current?.getValue() }, close)} // eslint-disable-line
											isFetching={id === updateFetchingRowId}>
											<Typography sx={styles.textPopup}>{t('note')}</Typography>
										</ConfirmationPopper>
									</Box>
								)}
								{isAllowedForAdmin && (
									<Box>
										<ConfirmationPopper
											sxBox={{ width: '381px', px: 0, pt: 0 }}
											body={
												<TitledBlock title={'Delete card'}>
													<div style={{ textAlign: 'left' }}>
														#{t('cardActionsCell.deleteCard')} <br /> {name} ?
													</div>
												</TitledBlock>
											}
											isDisabled={isDeleteDisabled}
											onSubmit={(close) => handleRemoveCard(close)}
											isFetching={id === deleteFetchingRowId}>
											<Typography sx={stylePopupText}>{t('cardActionsCell.removeCard')}</Typography>
										</ConfirmationPopper>
									</Box>
								)}
								{isCustomPlan && !isFetchingAccountUpdate && isAccountActive && active && (
									<>
										<Box onClick={() => !isTransactionCard && toggleModalTransfer()}>
											<Typography
												sx={{
													...styles.textPopup,
													...(isTransactionCard && {
														color: '#C4C4C4',
														cursor: 'not-allowed',
														'&:hover': {
															background: 'transparent',
														},
													}),
												}}>
												{t('cardActionsCell.transferFromAnotherCard')}
											</Typography>
										</Box>
										{isDisplayBonusCards && (
											<Box onClick={() => !isTransactionCard && toggleModalBonus()}>
												<Typography
													sx={{
														...styles.textPopup,
														...(isTransactionCard && {
															color: '#C4C4C4',
															cursor: 'not-allowed',
															'&:hover': {
																background: 'transparent',
															},
														}),
													}}>
													{t('cardActionsCell.transferFromExternalCard')}
												</Typography>
											</Box>
										)}
									</>
								)}
								{isAllowedForAdmin && isCustomPlan && !isFetchingAccountUpdate && isAccountActive && active && (
									<Box onClick={toggleBalanceAdjust}>
										<Typography sx={styles.textPopup}>{t('cardActionsCell.balanceAdjustment')}</Typography>
									</Box>
								)}
							</Paper>
						</Fade>
					)}
				</Popper>

				<TransactionsModal ref={transactionsModal} id={id} displayId={name} onClose={() => setOpen(false)} />

				<Box sx={styles.icon}>
					<Button onClick={handleClick('bottom-start')}>
						<DotsIcon height={27} width={7} />
					</Button>
				</Box>

				{isHasBalance && (
					<Modal ref={modalRef} width={360} height={250}>
						<ModalRemoveCard
							styles={styles}
							name={name}
							deleteCard={deleteCard}
							deleteFetchingRowId={deleteFetchingRowId}
							id={id}
							toggleModal={toggleModal}
						/>
					</Modal>
				)}

				<Modal
					ref={modalTransferRef}
					customStyle={{
						maxHeight: '448px',
						minHeight: '275px',
						maxWidth: '800px',
					}}>
					<ModalTransferAnotherCard currentCardId={id} />
				</Modal>

				<Modal ref={modalBalanceAdjustRef} width={'380px'} height={300}>
					<ModalBalanceAdjustment close={toggleBalanceAdjust} currentCardId={id} cardName={name} cardBalance={balance} setOpenModal={setOpen} />
				</Modal>

				<Modal ref={modalBonusRef} width={381} height={230} onClose={handleCloseBonusTransfer}>
					<ModalTransferBonusCard cardName={name} />
				</Modal>
			</Box>
		</ClickAwayListener>
	);
};

export default CardActionsCell;
