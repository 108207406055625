import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreditCardForm from '@components/credit-card-form';
import { Modal, TitledBlock } from '@components/index';
import { IModalForward } from '@components/modal';
import PaymentBrandIcon from '@components/payment-brand-icon';
import ResultDialog from '@components/result-dialog';
import SmallButton from '@components/small-button';
import PaymentDetailsDialog from '@pages/accounts/account/payment-details/dialog';
import { IPaymentDetailsDispatchToProps, IPaymentDetailsStateToProps } from '@pages/accounts/account/payment-details/index.props';
import styles from './styles';

export enum PaymentDetailsDialogType {
	ADD = 'add',
	EDIT = 'edit',
	DELETE = 'delete',
	SUCCESS = 'success',
	ERROR = 'error',
}

type IProps = IPaymentDetailsStateToProps & IPaymentDetailsDispatchToProps & { showTitleBlock: boolean; isGlobalPD: boolean };
const PaymentDetails: FC<IProps> = ({
	accountId,
	accountIdOfSignInUser,
	defaultPaymentMethod,
	email,
	emailOfSignInUser,
	tenantEmail,
	isFetchingDeletePaymentMethod,
	isFetchingUpdatePaymentMethod,
	isFetchingPaymentMethod,
	getPaymentMethod,
	clearPaymentMethodState,
	deletePaymentMethod,
	updatePaymentMethod,
	showTitleBlock,
	isGlobalPD = false,
}) => {
	const stripe = useStripe();
	const elements = useElements();
	const { t } = useTranslation();
	const modalRef = useRef<IModalForward | null>(null);
	const toggleModal = useCallback(() => {
		modalRef.current?.toggleModal();
	}, []);

	const [isCardValid, setIsCardValid] = useState<boolean>(false);
	const [isEditCard, setIsEditCard] = useState<boolean>(false);
	const [isFetchingModal, setIsFetchingModal] = useState<boolean>(false);
	const [dialogType, setDialogType] = useState<PaymentDetailsDialogType>(PaymentDetailsDialogType.ADD);

	useEffect(() => {
		if (accountId || accountIdOfSignInUser || isGlobalPD) {
			let data;

			if (!isGlobalPD) {
				data = accountId ?? accountIdOfSignInUser;
			}

			getPaymentMethod(data);
		}

		return () => {
			clearPaymentMethodState();
		};
	}, [isGlobalPD, accountId, accountIdOfSignInUser, clearPaymentMethodState, getPaymentMethod]);

	const memoBlock = useMemo(() => {
		if (!defaultPaymentMethod && !isEditCard) {
			return (
				<Box sx={styles.buttonWrapper}>
					<Grid container width={'50%'} justifyContent={'flex-end'} columnSpacing={2}>
						<Grid item xs={6}></Grid>
						<Grid item xs={6}>
							<SmallButton sx={styles.button} variant="outlined" color="primary" onClick={() => setIsEditCard(true)}>
								{t('add')}
							</SmallButton>
						</Grid>
					</Grid>
				</Box>
			);
		}

		if (isEditCard) {
			return (
				<Box>
					<CreditCardForm setIsCardValid={setIsCardValid} />
					<Box sx={{ ...styles.buttonWrapper, mt: 2 }}>
						<Grid container width={'50%'} justifyContent={'flex-end'} columnSpacing={2}>
							<Grid item xs={6}>
								<SmallButton sx={styles.button} variant="outlined" color="primary" onClick={() => setIsEditCard(false)}>
									{t('cancel')}
								</SmallButton>
							</Grid>
							<Grid item xs={6}>
								<SmallButton
									sx={styles.button}
									variant="contained"
									color="primary"
									disabled={!isCardValid}
									onClick={() => {
										setDialogType(PaymentDetailsDialogType.ADD);
										toggleModal();
									}}>
									{t('save')}
								</SmallButton>
							</Grid>
						</Grid>
					</Box>
				</Box>
			);
		}

		if (defaultPaymentMethod && !isEditCard) {
			return (
				<Box sx={styles.initWrapper}>
					<Box sx={{ gap: 2, display: 'flex', alignItems: 'center' }}>
						<PaymentBrandIcon brand={defaultPaymentMethod?.brand} />
						<Typography fontWeight="bold" noWrap textOverflow="clip">
							**** **** **** {defaultPaymentMethod?.cardLast4}
						</Typography>
					</Box>
					<Grid container columnSpacing={2} width={'50%'}>
						<Grid item xs={6}>
							<SmallButton sx={{ ...styles.button, width: '100%' }} variant="outlined" color="primary" onClick={() => setIsEditCard(true)}>
								{t('edit')}
							</SmallButton>
						</Grid>
						<Grid item xs={6}>
							<SmallButton
								sx={{ ...styles.button, width: '100%' }}
								variant="outlined"
								color="primary"
								onClick={() => {
									setDialogType(PaymentDetailsDialogType.DELETE);
									toggleModal();
								}}>
								{t('delete')}
							</SmallButton>
						</Grid>
					</Grid>
				</Box>
			);
		}
	}, [defaultPaymentMethod, isCardValid, isEditCard, t, toggleModal]);

	const handleCancel = () => {
		setIsEditCard(false);
		toggleModal();
	};

	const handleSubmit = async () => {
		try {
			setIsFetchingModal(true);

			if ((isGlobalPD && !tenantEmail) || (!email && !emailOfSignInUser)) {
				return;
			}

			if (!stripe || !elements) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				setDialogType(PaymentDetailsDialogType.ERROR);

				return;
			}

			const card = elements.getElement(CardElement);

			let emailToApply = email ?? emailOfSignInUser ?? '';

			if (isGlobalPD) {
				emailToApply = tenantEmail ?? '';
			}

			const { paymentMethod, error } = await stripe.createPaymentMethod({
				type: 'card',
				card: card !== null ? card : { token: '' },
				// eslint-disable-next-line camelcase
				billing_details: {
					email: emailToApply,
				},
			});

			if (error) {
				setDialogType(PaymentDetailsDialogType.ERROR);

				return;
			}

			if (paymentMethod) {
				updatePaymentMethod(paymentMethod.id, isGlobalPD ? undefined : accountId);
			}

			setIsFetchingModal(false);
			setDialogType(PaymentDetailsDialogType.SUCCESS);
		} catch (err) {
			setIsFetchingModal(false);
			setDialogType(PaymentDetailsDialogType.ERROR);
		}
	};

	const handleSubmitDelete = () => {
		try {
			setIsFetchingModal(true);
			deletePaymentMethod(isGlobalPD ? undefined : accountId);
			setIsFetchingModal(false);
			setDialogType(PaymentDetailsDialogType.SUCCESS);
		} catch (e) {
			setDialogType(PaymentDetailsDialogType.ERROR);
		}
	};

	const dialog = useMemo(() => {
		if (isFetchingModal || isFetchingPaymentMethod || isFetchingDeletePaymentMethod || isFetchingUpdatePaymentMethod) {
			return (
				<Box sx={{ ...styles.loaderModalWrapper, height: '300px' }}>
					<CircularProgress />
				</Box>
			);
		}

		switch (dialogType) {
			case PaymentDetailsDialogType.ADD:
			case PaymentDetailsDialogType.EDIT:
				return <PaymentDetailsDialog type={dialogType} handleCancel={handleCancel} handleSubmit={handleSubmit} />;

			case PaymentDetailsDialogType.DELETE:
				return <PaymentDetailsDialog type={dialogType} handleCancel={handleCancel} handleSubmit={handleSubmitDelete} />;

			case PaymentDetailsDialogType.SUCCESS:
				return <ResultDialog type="success" description={{ i18nKey: 'paymentDetailsDialog.success' }} close={handleCancel} />;

			case PaymentDetailsDialogType.ERROR:
				return <ResultDialog type="error" description={{ i18nKey: 'paymentDetailsDialog.error' }} close={handleCancel} />;
		}
		// eslint-disable-next-line
  }, [
		dialogType,
		stripe,
		elements,
		accountId,
		isFetchingModal,
		isFetchingPaymentMethod,
		isFetchingUpdatePaymentMethod,
		isFetchingDeletePaymentMethod,
	]);

	const content = isFetchingPaymentMethod ? (
		<Box sx={styles.loaderModalWrapper}>
			<CircularProgress />
		</Box>
	) : (
		<Box px={showTitleBlock ? 1.65 : 0}>{memoBlock}</Box>
	);

	return (
		<>
			{showTitleBlock ? <TitledBlock title="Payment details">{content}</TitledBlock> : content}
			<Modal
				ref={modalRef}
				width={{ xs: '100%', sm: 455 }}
				customStyle={{
					height: 'auto',
				}}
				isLoad={isFetchingModal || isFetchingUpdatePaymentMethod || isFetchingDeletePaymentMethod}>
				{dialog}
			</Modal>
		</>
	);
};

export default PaymentDetails;
