/* eslint-disable @typescript-eslint/default-param-last */
import { ILocation } from '@interfaces/locations/i-location';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITableList } from '@interfaces/redux/i-table-list';
import TYPES from './action-types';

export interface ILocations {
	list: IReducerState<ILocation[]> & ITableList;
}

const initState: ILocations = {
	list: {
		result: [],
		isFetching: false,
		error: null,
		searchValue: '',
		pagination: { total: 0, page: 1, size: 10 },
		sorting: {},
	},
};

/**
 * Locations reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
	switch (type) {
		// list
		case TYPES.GET_LOCATIONS_LIST:
			return { ...state, list: { ...state.list, isFetching: true } };

		case TYPES.GET_LOCATIONS_LIST_SUCCESS:
			return {
				...state,
				list: {
					...state.list,
					result: payload.list,
					isFetching: false,
				},
			};

		case TYPES.GET_LOCATIONS_LIST_ERROR:
			return {
				...state,
				list: { ...state.list, isFetching: false, error: payload.message },
			};

		case TYPES.CLEAR_LOCATIONS_LIST:
			return {
				...state,
				list: {
					...state.list,
				},
			};

		default:
			return state;
	}
};
