import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SmallButton, TitledBlock } from '@components/index';
import { ICardUpdateStateToProps, ICardUpdateDispatchToProps } from './index.props';
import styles from './styles';

type IProps = ICardUpdateStateToProps & ICardUpdateDispatchToProps;

/**
 * CardsUpdate
 * @constructor
 */
const CardsUpdate: FC<IProps> = ({ nayaxCardsSync, isFetching, isFetchingFirebase }) => {
	const { t } = useTranslation();
	const isLoad = isFetchingFirebase || isFetching;

	return (
		<TitledBlock title={t('cardsUpdate.title')}>
			<Box sx={styles.box}>
				<Box height="100%">
					<Typography color="text.primary" textAlign="center">
						{t('cardsUpdate.description')}
					</Typography>
				</Box>

				<Box sx={styles.button}>
					<SmallButton type="submit" onClick={nayaxCardsSync} isFetching={isLoad} disabled={isLoad}>
						{isFetching ? t('cardsUpdate.btnUpdating') : t('cardsUpdate.btnStartUpdate')}
					</SmallButton>
				</Box>
			</Box>
		</TitledBlock>
	);
};

export default CardsUpdate;
