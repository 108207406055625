import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

export interface IColorModeContext {
	toggleColorMode: () => void;
}

type IPaletteMode = 'light' | 'dark';

export const initValue: IColorModeContext = {
	toggleColorMode: (): void => undefined,
};

const ColorModeContext = createContext(initValue);

/**
 * Initial color mode from local storage
 */
const initColorMode = localStorage.getItem('color-mode') ?? 'light';

/**
 * Color mode (theme) context provider
 * @constructor
 */
export const ColorModeProvider: FC = ({ children }) => {
	const [mode, setMode] = useState<IPaletteMode>(initColorMode as IPaletteMode);

	/**
	 * Current color mode
	 */
	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			},
		}),
		[],
	);

	/**
	 * Save color mode to local storage on changing
	 */
	useEffect(() => localStorage.setItem('color-mode', mode), [mode]);

	/**
	 * Set new theme palette on change color mode
	 * @see {https://mui.com/customization/palette/#default-values}
	 * @see {https://mui.com/material-ui/customization/dark-mode/}
	 */
	const theme = useMemo(
		() =>
			createTheme({
				custom: { simple: mode === 'light' ? '#000' : '#fff' },
				palette: {
					mode,
					...(mode === 'light'
						? {
								primary: { main: '#3C4977' },
								text: { secondary: '#3C4977' },
							}
						: {
								primary: { main: '#607EB5' },
							}),
				},
			}),
		[mode],
	);

	/**
	 * Update color theme for scrollbar on changing
	 */
	useEffect(() => {
		document.documentElement.style.setProperty('--scroll-color', theme.palette.primary.main);
		document.documentElement.style.setProperty('--scroll-bcg', theme.palette.action.selected);
	}, [theme.palette.action.selected, theme.palette.primary.main]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
};

export const useColorModeContext = (): IColorModeContext => useContext(ColorModeContext);
