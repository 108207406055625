import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { updateNewDeposit } from '@store/deposits/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		deposit: state.deposits.item.deposit,
		listenTransaction: state.payments.listen.transactions,
		userRoles: state.tenant.claims?.roles,
		depositsList: state.deposits.list.result,
		isDeleteFetching: state.payments.delete.isFetching,
		isFetchingAccountUpdate: state.accounts.update.isFetching,
		isFetchingCards: state.cards.list.isFetching,
	}) as const;

export const mapDispatchToProps = {
	updateNewDeposit,
};

export type IToDepositStateToProps = ReturnType<typeof mapStateToProps>;

export type IToDepositDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
