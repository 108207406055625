import { useTheme } from '@mui/system';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { IStripeElementsDispatchToProps, IStripeElementsStateToProps } from './index.props';

type StripeElementsProps = IStripeElementsStateToProps &
	IStripeElementsDispatchToProps & {
		useGlobalStripe: boolean;
		children: any;
	};

const StripeElements = ({ stripePublic, children, clientSecret, stripePublicGlobal, useGlobalStripe }: StripeElementsProps) => {
	const stripePromise = loadStripe(useGlobalStripe ? (stripePublicGlobal ?? '') : (stripePublic ?? ''), {
		locale: 'auto',
	});
	const [theme, setTheme] = useState<string>('light');
	const { palette: paletteTheme } = useTheme();

	useEffect(() => {
		setTheme(paletteTheme.mode);
	}, [paletteTheme]);

	const options = {
		clientSecret,
	};

	return (useGlobalStripe ? Boolean(stripePublicGlobal) : Boolean(stripePublic)) ? (
		<Elements
			options={{
				...options,
				appearance: {
					theme: theme === 'dark' ? 'night' : 'stripe',
					variables: {
						colorPrimary: theme === 'dark' ? '#4283b8' : '#3C4977',
					},
				},
			}}
			stripe={stripePromise}>
			{children}
		</Elements>
	) : (
		<div />
	);
};

export default StripeElements;
