import { IAction } from '@interfaces/redux/i-action';
import TYPES from './action-types';

/**
 * Begin forgot send email
 */
export const forgotSendEmail = (email: FormDataEntryValue | null): IAction<TYPES> => ({
	type: TYPES.FORGOT_SEND_EMAIL,
	payload: { email },
});

/**
 * Forgot send email success
 */
export const forgotSendEmailSuccess = (): IAction<TYPES> => ({
	type: TYPES.FORGOT_SEND_EMAIL_SUCCESS,
	payload: {},
});

/**
 * Forgot send email error
 */
export const forgotSendEmailError = (message: string): IAction<TYPES> => ({
	type: TYPES.FORGOT_SEND_EMAIL_ERROR,
	payload: { message },
});

/**
 * Begin forgot confirm
 */
export const forgotConfirm = (password: FormDataEntryValue | null, actionCode: FormDataEntryValue | null): IAction<TYPES> => ({
	type: TYPES.FORGOT_CONFIRM,
	payload: { password, actionCode },
});

/**
 * Forgot confirm success
 */
export const forgotConfirmSuccess = (): IAction<TYPES> => ({
	type: TYPES.FORGOT_CONFIRM_SUCCESS,
	payload: {},
});

/**
 * Forgot confirm email error
 */
export const forgotConfirmError = (message: string): IAction<TYPES> => ({
	type: TYPES.FORGOT_CONFIRM_ERROR,
	payload: { message },
});

/**
 * Begin forgot verify
 */
export const forgotVerify = (token: string): IAction<TYPES> => ({
	type: TYPES.FORGOT_VERIFY,
	payload: { token },
});

/**
 * Forgot verify success
 */
export const forgotVerifySuccess = (email: string, oobCode: string): IAction<TYPES> => ({
	type: TYPES.FORGOT_VERIFY_SUCCESS,
	payload: { email, oobCode },
});

/**
 * Forgot verify email error
 */
export const forgotVerifyError = (message: string): IAction<TYPES> => ({
	type: TYPES.FORGOT_VERIFY_ERROR,
	payload: { message },
});

/**
 * Clear store
 */
export const clearStore = (): IAction<TYPES> => ({
	type: TYPES.CLEAR_STORE,
	payload: {},
});
