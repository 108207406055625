import Button from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { FC } from 'react';

export interface ILoadingButton extends ButtonProps {
	isFetching?: boolean;
}

/**
 * Loading Button
 * @constructor
 */
const LoadingButton: FC<ILoadingButton> = ({ isFetching, children, ...props }) => (
	<Button
		{...props}
		{...(isFetching && {
			startIcon: <CircularProgress size={14} color="inherit" />,
			disabled: isFetching,
		})}>
		{children}
	</Button>
);

export default LoadingButton;
