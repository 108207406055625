import type { SagaIterator } from '@redux-saga/core';
import { call, put } from 'redux-saga/effects';
import { configs } from '@helpers/get-env';
import { getDomain } from '@helpers/domain-update';
import { getPublicSettings as getPublicSettingsAC, getPublicSettingsError, getPublicSettingsSuccess } from './action-creators';
import { getPublicSettingsApi } from './api';

export function* getPublicSettingsSaga(): SagaIterator {
	try {
		const domain = getDomain();
		if (domain === configs.REACT_APP_LOGIN_SUBDOMAIN) {
			return;
		}

		yield put(getPublicSettingsAC());
		const { settings, globalSettings } = yield call(getPublicSettingsApi);

		yield put(getPublicSettingsSuccess(settings, globalSettings));
	} catch (e) {
		yield put(getPublicSettingsError(e.message));
	}
}

export default [call(getPublicSettingsSaga)];
