import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		background: ({ palette }: any) => (palette.mode === 'light' ? 'white' : '#000'),
		border: ({ palette }: any) => (palette.mode === 'light' ? '1px solid #DDDDDD' : '1px solid transparent'),
		borderRadius: '5px',
	} as SxProps<Theme>,

	autocomplete: {
		width: '100%',
		my: -0.5,
	} as SxProps<Theme>,

	iconContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		mr: '9px',
		ml: '18px',
		color: '#828EB9',
		minWidth: '10px !important',
	},
	icon: {
		color: '#828EB9',
		width: '26px',
		height: '26px',
	},
	input: {
		padding: '9px 4px',
		color: '#C4C4C4',

		'& :before, & :after': { content: 'unset' },
	},
	button: {
		color: '#fff',
		py: '7.5px',
		px: '21px',
		mt: 0,
		mr: '7px',
		ml: '2px',
	},
};

export default styles as IStyles<typeof styles>;
