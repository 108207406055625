import React, { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Column } from 'react-table';
import { CardActionsCell, EditableInputCell, EditableSwitchCell, Table, ToDepositCell } from '@components/index';
import formatMoney from '@helpers/format-money';
import { IAccount } from '@interfaces/accounts/i-account';
import PlanType from 'src/enums/plan-type';
import type { ICardsTableDispatchToProps, ICardsTableStateToProps } from './index.props';

type IProps = ICardsTableDispatchToProps & ICardsTableStateToProps & { account: IAccount | null; tableHeadSx?: any };
const usageTranslationKey = 'cardsTable.usage';

/**
 * CardsTable
 * @constructor
 */
const CardsTable: FC<IProps> = ({
	cards,
	isShowInactive,
	deleteFetchingRowId,
	updateFetchingRowId,
	isListFetching,
	updateCard,
	getCardsList,
	clearCardsList,
	listenTransactions,
	account,
	isFetchingAccountUpdate,
	isAccountActive,
	tableHeadSx,
}) => {
	const { t } = useTranslation();
	const accountPlan = account?.accountPlan.plan.name;
	const isCustomPlan = accountPlan === PlanType.Custom;

	/**
	 * Get cards list on component mounting
	 */
	useEffect(() => {
		if (!account?.id) {
			return;
		}

		getCardsList();
	}, [getCardsList, account?.id]);

	/**
	 * Clear cards list on page unmount
	 */
	useEffect(() => () => clearCardsList(), [clearCardsList]);

	const planColumn: Column<any>[] = useMemo(() => {
		const planColumnLookup: Record<PlanType, Column<any>[]> = {
			[PlanType.Custom]: [
				{
					Header: `${t('balance')}`,
					accessor: 'balance',
					Cell: ({ value }) => formatMoney(Number(value)),
				},
				{ Header: `${t('cardsTable.toDeposit')}`, accessor: 'toDeposit', Cell: ToDepositCell },
			],
			[PlanType.Pool]: [
				{
					Header: `${t(usageTranslationKey)}`,
					accessor: 'usagePool',
					Cell: ({ value }) => formatMoney(Number(value)),
				},
			],
			[PlanType.Coop]: [
				{
					Header: `${t(usageTranslationKey)}`,
					accessor: 'usageCoop',
					Cell: ({ value }) => formatMoney(Number(value)),
				},
			],
			[PlanType.Unlimited]: [
				{
					Header: `${t(usageTranslationKey)}`,
					accessor: 'finaleBalance',
					Cell: ({ value }) => formatMoney(Number(value)),
				},
			],
			[PlanType.Fleet]: [
				{
					Header: `${t(usageTranslationKey)}`,
					accessor: 'finaleBalance',
					Cell: ({ value }) => formatMoney(Number(value)),
				},
			],
		};

		return accountPlan ? planColumnLookup[accountPlan] : [];
	}, [accountPlan, t]);

	/**
	 * Table columns config
	 */
	const columns: Column<any>[] = useMemo(
		() => [
			{ Header: `${t('card')}`, accessor: 'name' },
			{
				Header: `${t('cardsTable.cardDescription')}`,
				accessor: 'extra',
				Cell: (props) => <EditableInputCell {...props} sxInput={{ input: { padding: '3.5px' } }} isFetchingAccountUpdate={isFetchingAccountUpdate} />,
			},
			...planColumn,
			{
				Header: `${t('active')}`,
				accessor: 'active',
				Cell: EditableSwitchCell,
				sortType: 'basic',
			},
			{
				Header: `${t('action')}`,
				accessor: `${t('action')}`,
				Cell: (props) => <CardActionsCell {...props} isCustomPlan={isCustomPlan} />,
			},
		],
		[t, planColumn, isFetchingAccountUpdate, isCustomPlan],
	);

	/**
	 * Data with filter for all/without inactive
	 */
	const filteredData = useMemo(() => cards.filter(({ active }) => (isShowInactive ? true : active)), [cards, isShowInactive]);

	/**
	 * Check row and table fetching
	 */
	const fetchingRowId = updateFetchingRowId || deleteFetchingRowId;

	return (
		<Table
			columns={columns}
			data={filteredData}
			isFetching={Boolean(fetchingRowId) || isListFetching}
			fetchingRowId={fetchingRowId}
			updateCellData={updateCard}
			withPagination={false}
			listenTransactions={listenTransactions}
			tableHeaderSx={tableHeadSx}
			disableSortBy
			isFetchingAccountUpdate={isFetchingAccountUpdate}
			isAccountActive={isAccountActive}
			stickyHeader={false}
		/>
	);
};

export default CardsTable;
