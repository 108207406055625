import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useTheme } from '@mui/material/styles';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles';

export interface INotesEditForward {
	getValue: () => string;
}

interface INotesEdit {
	defaultNote?: string;
}

/**
 * NotesEdit
 * @constructor
 */
const NotesEdit = forwardRef<INotesEditForward, INotesEdit>(({ defaultNote = '' }, ref) => {
	const [note, setNote] = useState(defaultNote);
	const { t } = useTranslation();

	const theme = useTheme();

	/**
	 * On change textarea value
	 */
	const handleOnChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => setNote(e.target.value), []);

	/**
	 * Ref methods
	 */
	useImperativeHandle(ref, () => ({ getValue: () => note }));

	return (
		<TextareaAutosize style={styles.textarea(theme)} minRows={5} placeholder={`${t('writeSomething')}...`} value={note} onChange={handleOnChange} />
	);
});

NotesEdit.displayName = 'NotesEdit';

export default NotesEdit;
