import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React, { ComponentType, LazyExoticComponent } from 'react';
import { ConnectedComponent } from 'react-redux';
import SideBar from '@containers/sidebar/index.store';
import RoutePath from '../../../enums/route-path';
import styles from './styles';

/**
 * Add sidebar to page
 */
const withBars = (
	WrappedComponent: ComponentType | ConnectedComponent<any, any> | LazyExoticComponent<ComponentType | ConnectedComponent<any, any>>,
): ComponentType =>
	class extends React.Component {
		static displayName = 'withBars';

		render() {
			const isFixed = location.pathname.includes(`${RoutePath.AccountsEdit}/`);
			return (
				<Box sx={styles.wrapper}>
					<SideBar />
					<Box component="main" sx={styles.main} data-fixed={isFixed}>
						<Container maxWidth="lg" sx={styles.container}>
							<Grid container height={'100%'}>
								<Box sx={styles.box}>
									<WrappedComponent />
								</Box>
							</Grid>
						</Container>
					</Box>
				</Box>
			);
		}
	};

export default withBars;
