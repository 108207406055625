import { Typography } from '@mui/material';
import List from '@mui/material/List';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import SmallButton from '@components/small-button';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import RoleType from 'src/enums/role-type';
import RoutePath from '../../enums/route-path';
import styles from './styles';

interface IListItem {
	title: string;
	href: RoutePath | string;
}

export const mainListAdmin = [
	{ title: 'sidebar.accounts', href: RoutePath.Accounts },
	{ title: 'sidebar.cards', href: RoutePath.Cards },
	{
		title: 'administration.title',
		href: RoutePath.Administration,
	},
];

export const mainList = (accountId: string) => [
	{ title: 'sidebar.account', href: `${RoutePath.AccountsEdit}/${accountId}` },
	{
		title: 'administration.title',
		href: RoutePath.Administration,
	},
];

interface IListItems {
	list: IListItem[];
	userRoles: any;
}

/**
 * List items
 * @constructor
 */
export const ListItems: FC<IListItems> = ({ list, userRoles }) => {
	const { t } = useTranslation();
	const isAllowedForAdmin = isAllowedForUser([RoleType.Admin], userRoles as RoleType[]);
	const history = useHistory();
	const navigateToAddPage = useCallback(() => history.push(RoutePath.AccountsAdd), [history]);

	return (
		<>
			{isAllowedForAdmin && (
				<SmallButton variant="outlined" sx={styles.btnAddAccount} onClick={navigateToAddPage}>
					+ {t('new')}
				</SmallButton>
			)}
			<List sx={styles.list}>
				{list.map(({ title, href }) => (
					<NavLink
						key={title}
						exact
						to={href ?? '/'}
						style={{
							border: 'none',
							textDecoration: 'none',
							marginRight: '35px',
							borderBottom: '1px solid transparent',
						}}
						activeStyle={{
							borderImageSource: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0.98%, #FFFFFF 46.71%, rgba(255, 255, 255, 0) 98.53%)',
							borderImageSlice: '1',
							width: '100%',
						}}>
						<Typography color={'white'}>{t(title)}</Typography>
					</NavLink>
				))}
			</List>
		</>
	);
};
