import Box from '@mui/material/Box';
import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent, StripeCardElementOptions } from '@stripe/stripe-js';
import React from 'react';

interface CreditCardFormProps {
	setIsCardValid: (value: ((prevState: boolean) => boolean) | boolean) => void;
}

const CARD_ELEMENT_OPTIONS: StripeCardElementOptions = {
	style: {
		base: {
			color: '#3C4977',
			fontFamily: 'Arial, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: '#3C4977',
			},
			iconColor: '#3C4977',
		},
		invalid: {
			fontFamily: 'Arial, sans-serif',
			color: '#FF0000',
			iconColor: '#FF0000',
		},
	},
	hidePostalCode: true,
	iconStyle: 'solid',
};
const CreditCardForm = ({ setIsCardValid }: CreditCardFormProps) => {
	const handleOnChange = ({ complete, empty, error }: StripeCardElementChangeEvent) => {
		setIsCardValid(complete && !empty && !error);
	};

	return (
		<Box sx={{ width: '100%', border: '1px solid #ADB5D2', borderRadius: '5px', padding: '13px' }}>
			<CardElement options={CARD_ELEMENT_OPTIONS} onChange={(event) => handleOnChange(event)} />
		</Box>
	);
};

export default CreditCardForm;
