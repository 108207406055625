import React, { FC, ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { IRolesAndPermissions } from '@interfaces/auth/i-roles-and-permissions';
import PermissionType from '../../enums/permission-type';
import RoleType from '../../enums/role-type';
import RoutePath from '../../enums/route-path';
import type { IAuthGatewayStateToProps } from './index.props';

type IProps = IAuthGatewayStateToProps & {
	children: ReactElement<any, any> | null;
	roles: RoleType[];
	permissions: PermissionType[];
};

const userHasAccess = (claims: IRolesAndPermissions | null, roles: RoleType[], permissions: PermissionType[]): boolean => {
	if (!roles.length && !permissions.length) {
		return true;
	}

	return roles.some((role) => claims?.roles?.includes(role)) && permissions.some((permission) => claims?.permissions?.includes(permission));
};

/**
 * Component for implementation secure feature.
 * Protect some pages with authentication.
 */
const AuthGateway: FC<IProps> = ({ isAuth, claims, children, roles = [], permissions = [] }) => {
	const location = useLocation();

	const prevUrl = `${location.pathname}${location.search}`;

	if (!isAuth) {
		return <Redirect to={{ pathname: RoutePath.Login, state: { prevUrl } }} />;
	}

	if (claims) {
		const isAccess = userHasAccess(claims, roles, permissions);

		if (!isAccess) {
			return <Redirect to={RoutePath.Administration} />;
		}
	}

	return children;
};

export default AuthGateway;
