import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ICardsListMobileDispatchToProps, ICardsListMobileStateToProps } from '@components/cards-list-mobile/index.props';
import EditableInputCard from '@components/editable-input-card';
import { ToDeposit } from '@components/index';
import InfoBlock, { InfoBlockData } from '@components/info-block';
import { IAccount } from '@interfaces/accounts/i-account';
import { ICard } from '@interfaces/cards/i-card';
import PlanType from '../../../enums/plan-type';

type IProps = ICardsListMobileDispatchToProps & ICardsListMobileStateToProps & { account: IAccount | null };
const CardsListMobile: FC<IProps> = ({
	account,
	cards,
	isFetchingAccountUpdate,
	getCardsList,
	clearCardsList,
	updateCard,
	listenTransactions,
	isListFetching,
}: IProps) => {
	const { t } = useTranslation();

	/**
	 * Get cards list on component mounting
	 */
	useEffect(() => {
		if (!account?.id) {
			return;
		}

		getCardsList();
	}, [getCardsList, account]);

	/**
	 * Clear cards list on page unmount
	 */
	useEffect(() => () => clearCardsList(), [clearCardsList]);

	const createInfoBlockData = useCallback(
		(card: ICard): InfoBlockData[] => {
			const initData = [
				{ label: `${t('card')}`, content: card.name },
				{
					label: `${t('cardsTable.cardDescription')}`,
					content: (
						<EditableInputCard
							card={card}
							initialValue={card.extra}
							listenTransactions={listenTransactions}
							updateCard={updateCard}
							sxInput={{ input: { padding: '3.5px' } }}
						/>
					),
				},
			];

			switch (account?.accountPlan?.plan?.name) {
				case PlanType.Custom:
					return [
						...initData,
						{
							label: `${t('balance')}`,

							content: (
								<Typography fontWeight="bold" color="#787878">
									{card.balance.toFixed(2)}
								</Typography>
							),
						},
						{
							label: `${t('cardsTable.toDeposite')}`,
							content: <ToDeposit id={card.id} card={card} key={card.id} />,
						},
					];
				case PlanType.Pool:
					return [
						...initData,
						{
							label: `${t('cardsTable.usage')}`,
							content: <Typography>${card.usagePool.toFixed(2)}</Typography>,
						},
					];

				case PlanType.Unlimited:
				case PlanType.Fleet:
				default:
					return [
						...initData,
						{
							label: `${t('cardsTable.usage')}`,
							content: <Typography>${(10000 - card.balance).toFixed(2)}</Typography>,
						},
					];
			}
		},
		[account?.accountPlan?.plan?.name, listenTransactions, t, updateCard],
	);

	const cardInfoBlock = useMemo(
		() =>
			cards.length ? (
				cards.map((card) => (
					<InfoBlock
						data={createInfoBlockData(card)}
						key={card.id}
						containerSx={{
							boxShadow: '0px 1px 4px 0px #00000040',
							padding: '10px',
							marginBottom: '10px',
						}}
						labelSx={{
							fontWeight: 'bold',
							textAlign: 'right',
							color: '#3C4977',
						}}
						labelGridSize={{ xs: 4 }}
						contentGridSize={{ xs: 5 }}
					/>
				))
			) : (
				<Typography color="text.primary" noWrap textAlign="center">
					{t('noData')}
				</Typography>
			),
		[cards, createInfoBlockData, t],
	);

	return (
		<Box>
			{isListFetching || isFetchingAccountUpdate ? (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						right: 0,
						left: 0,
						top: 0,
						bottom: 0,
					}}>
					<CircularProgress />
				</Box>
			) : (
				cardInfoBlock
			)}
		</Box>
	);
};

export default CardsListMobile;
