import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import type { Column } from 'react-table';
import { FixedTableHeight, Table, TableTopPane } from '@components/index';
import TableType from 'src/enums/table-type';
import type { IAccountsStateToProps, IAccountsDispatchToProps } from './index.props';
import styles from './styles';

type IProps = IAccountsStateToProps & IAccountsDispatchToProps;

/**
 * Accounts page
 * @constructor
 */
const Accounts: FC<IProps> = ({
	accounts,
	pagination,
	isFetching,
	isFetchingFirebase,
	searchValue,
	updateAccountsSearch,
	updateAccountsListPagination,
	updateAccountsSorting,
	getAccountsList,
	getLocationsList,
	locationList,
	updateAccountsSearchByLocation,
	coopThresholdAmount,
	coopThresholdColour,
}) => {
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		getLocationsList();
	}, [getLocationsList]);

	/**
	 * Get list initially
	 */
	useEffect(() => {
		if (!isFetchingFirebase) {
			getAccountsList();
		}
	}, [getAccountsList, isFetchingFirebase, getLocationsList]);

	/**
	 * Table columns config
	 */
	const columns: Column<any>[] = useMemo(
		() => [
			{ Header: () => t('accounts.h_account'), accessor: 'name' },
			{ Header: () => t('accounts.h_phone'), accessor: 'phone' },
			{ Header: () => t('accounts.h_email'), accessor: 'email' },
			{
				Header: () => t('accounts.h_status'),
				accessor: 'active',
				Cell: ({ value }) => (value ? 'Active' : 'Inactive'),
				sortType: 'basic',
			},
			{ Header: () => t('accounts.h_plan'), accessor: 'accountPlan.plan.name' },
			{ Header: () => t('accounts.h_location'), accessor: 'location.name' },
		],
		[t],
	);

	/**
	 * Navigate to edit page by row id
	 */
	const navigateToEditPage = useCallback(
		(id: string, row: Record<string, unknown>) => () => {
			//todo replace row.id to row.code when all old dbs will be migrated
			history.push(`${location.pathname}/edit/${row?.id as string}`);
		},
		[history],
	);

	/**
	 * Navigate to add page
	 */
	// const navigateToAddPage = useCallback(() => history.push('/accounts/add'), [history]);

	const handleGetIdLocation = (id: number | string) => {
		updateAccountsSearchByLocation(id);
	};

	return (
		<FixedTableHeight
			heightPercent={102}
			topPane={
				<TableTopPane
					dataLocations={locationList}
					handleOpenLocation={getLocationsList}
					searchValue={searchValue}
					updateSearch={updateAccountsSearch}
					handleGetIdLocation={handleGetIdLocation}
					isShowLocation>
					{/* <AddAccountIcon onClick={navigateToAddPage} /> */}
				</TableTopPane>
			}>
			<Table
				columns={columns}
				data={accounts}
				tableHeaderSx={styles.tableHeaderSx}
				isFetching={isFetching || isFetchingFirebase}
				onItemClick={navigateToEditPage}
				onChangeSorting={updateAccountsSorting}
				onChangePagination={updateAccountsListPagination}
				pagination={pagination}
				tableType={TableType.MainTable}
				coopThresholdAmount={coopThresholdAmount}
				coopThresholdColour={coopThresholdColour}
			/>
		</FixedTableHeight>
	);
};

export default Accounts;
