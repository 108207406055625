import type { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getSubscriptionPricesError, getSubscriptionPricesSuccess } from '@store/subscriptions/action-creators';
import TYPES from '@store/subscriptions/action-types';
import { getSubscriptionPricesApi } from '@store/subscriptions/api';
import { awaitUserToken } from '@store/tenant/sagas';

function* getSubscriptionPrices(): SagaIterator {
	try {
		yield call(awaitUserToken);
		const prices = yield call(getSubscriptionPricesApi);

		yield put(getSubscriptionPricesSuccess(prices));
	} catch (e) {
		yield put(getSubscriptionPricesError(e.message));
	}
}

export default [takeLatest(TYPES.GET_SUBSCRIPTION_PRICES, getSubscriptionPrices)];
