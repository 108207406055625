import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { getTenantBillingData, updateTenantBillingData } from '@store/tenant-billing/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		tenantName: state.tenant.tenantName,
		isFetching: state.tenantBilling.get.isFetching,
		isFetchingUpdate: state.tenantBilling.update.isFetching,
		nextBillingDate: state.tenantBilling.get.result?.nextBillingDate,
		lastPaymentDay: state.tenantBilling.get.result?.lastPaymentDay,
		tenantEmail: state.tenantBilling.get.result?.email,
	}) as const;

export const mapDispatchToProps = {
	getTenantBillingData,
	updateTenantBillingData,
};

export type ITenantBillingStateToProps = ReturnType<typeof mapStateToProps>;

export type ITenantBillingDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
