import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		display: 'flex',
		width: '100%',
		height: 'auto',
	},

	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		margin: 'auto',
		height: 'auto',
	},
	body: {
		flex: '1 1 auto',
	},
	button: {
		mt: 1,
		width: 154,
	},
	divider: {
		width: '100%',
		my: 1,
		ml: 0.5,
		backgroundColor: ({ custom }) => custom.simple,
	} as SxProps<Theme>,
	buttons: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	footerButton: {
		display: 'flex',
		justifyContent: 'center',
		mb: 2,
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		mb: 2,
	},
	email: {
		wordBreak: 'break-word',
		color: '#000',
		opacity: 0.75,
		fontSize: 'inherit',
		fontWeight: 'bold',
	},
	loaderBox: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '105px',
	},
	errorBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItem: 'center',
		gap: '100px',
	},
};

export default styles as IStyles<typeof styles>;
