import React, { FC } from 'react';
import { CellProps } from 'react-table';
import EditableInputCard from '@components/editable-input-card';

/**
 * EditableInput cell renderer
 * @constructor
 */
const EditableInputCell: FC<CellProps<any>> = ({ value, row: { original }, updateCellData, listenTransactions, sxInput }) => (
	<EditableInputCard initialValue={value} card={original} updateCard={updateCellData} listenTransactions={listenTransactions} sxInput={sxInput} />
);

export default EditableInputCell;
