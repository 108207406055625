/* eslint-disable @typescript-eslint/default-param-last */
import { User } from '@firebase/auth';
import { IRolesAndPermissions } from '@interfaces/auth/i-roles-and-permissions';
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import PlanType from '../../enums/plan-type';
import TYPES from './action-types';

export interface ITenantState extends IReducerState<User | null> {
	tenantId: string;
	tenantName: string;
	initials: string;
	loginAccountName: string;
	claims: IRolesAndPermissions | null;
	id: string;
	plan: PlanType | null;
}

const initState: ITenantState = {
	tenantId: '',
	initials: '',
	claims: null,
	error: null,
	id: '',
	isFetching: false,
	result: null,
	plan: null,
	loginAccountName: '',
	tenantName: '',
};

/**
 * User reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
	switch (type) {
		case TYPES.LOGIN:
		case TYPES.LOGOUT:
		case TYPES.LOGIN_VIA_PERSIST:
			return { ...state, error: null, isFetching: true };

		case TYPES.LOGIN_SUCCESS:
			return {
				...state,
				isFetching: false,
				result: payload.user,
				claims: payload.claims,
				id: payload.id,
				tenantId: payload.tenantId,
				tenantName: payload.tenantName,
				initials: payload.initials,
				plan: payload.plan,
				loginAccountName: payload.accountName,
			};

		case TYPES.LOGIN_ERROR:
			return { ...state, isFetching: false, error: payload.message };

		case TYPES.LOGOUT_SUCCESS:
			return { ...initState, error: payload.message };

		case TYPES.CLEAR_STORE:
			return { ...initState };

		default:
			return state;
	}
};
