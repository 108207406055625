import type { SagaIterator } from '@redux-saga/core';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import { put, select, take } from 'redux-saga/effects';
import { IAction } from '@interfaces/redux/i-action';
import IState from '@interfaces/redux/i-state';
import { IAccountsState } from '@store/accounts/reducer';

type IListReducers = Omit<IState, 'tenant' | 'sidebar' | 'settings' | 'forgot' | 'subscriptions' | 'paymentMethod' | 'tenantBilling'>;

/**
 * Generic structure for
 */
export function* onUpdateListFork(
	reducerName: keyof IListReducers,
	key: keyof IListReducers[keyof IListReducers]['list'],
	typeForAwaiting: string,
	request: () => IAction<any>,
): SagaIterator {
	while (true) {
		const prevProperty = yield select((state) => state[reducerName].list[key]);

		yield take(typeForAwaiting);

		const newProperty = yield select((state) => state[reducerName].list[key]);

		if (key === 'sorting' && newProperty.undefined === undefined) {
			delete newProperty.undefined;
		}

		if (!isEqual(prevProperty, newProperty)) {
			yield put(request());
		}
	}
}

/**
 * On update essence success
 */
export function* onUpdateEssenceSuccess(reducerName: keyof IListReducers, successTypeForAwaiting: string, request: () => IAction<any>): SagaIterator {
	while (true) {
		const {
			payload: { item },
		} = yield take(successTypeForAwaiting);
		const list: IAccountsState['list']['result'] = yield select((state) => state[reducerName].list.result);

		// Find item index using _.findIndex
		const index = findIndex(list, { id: item.id });

		if (index >= 0) {
			yield put(request());
		}
	}
}
