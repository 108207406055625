import IState from '@interfaces/redux/i-state';

const mapStateToProps = (state: IState) =>
	({
		settings: state.settings.result?.settings ?? null,
	}) as const;

export type ISettingGatewayStateToProps = ReturnType<typeof mapStateToProps>;

export { mapStateToProps };
