import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { addTransactions } from '@store/transactions/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		isFetching: state.transactions.add.isFetching,
		isFetchingCards: state.cards.list.isFetching,
	}) as const;

export const mapDispatchToProps = {
	balanceAdjustment: addTransactions,
};

export type IBalanceAdjustmentStateToProps = ReturnType<typeof mapStateToProps>;

export type IBalanceAdjustmentDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
