import Paper from '@mui/material/Paper';
import React, { FC } from 'react';
import styles from './styles';

/**
 * CloudCover
 * @constructor
 */
const CloudCover: FC = ({ children }) => (
	<Paper variant="outlined" sx={styles.paper}>
		{children}
	</Paper>
);

export default CloudCover;
