import React, { Component, ComponentType, LazyExoticComponent, ReactNode } from 'react';
import { ConnectedComponent } from 'react-redux';
import SettingGateway from '@containers/setting-gateway/index.store';
import { ISettings } from '@interfaces/auth/i-settings';

const withSettings = (
	WrappedComponent: ComponentType | ConnectedComponent<any, any> | LazyExoticComponent<ComponentType | ConnectedComponent<any, any>>,
	requiredSettingKeys: (keyof ISettings)[],
): ComponentType =>
	class extends Component {
		static displayName = 'withSettings';

		render(): ReactNode {
			return (
				<SettingGateway requiredSettingKeys={requiredSettingKeys}>
					<WrappedComponent />
				</SettingGateway>
			);
		}
	};

export default withSettings;
