enum PaymentBrand {
	AmericanExpress = 'amex',
	Diners = 'diners',
	Discover = 'discover',
	JCB = 'jcb',
	MasterCard = 'mastercard',
	UnionPay = 'unionpay',
	Visa = 'visa',
	Unknown = 'unknown',
}

export default PaymentBrand;
