import type { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { IAction } from '@interfaces/redux/i-action';
import {
	deletePaymentMethodError,
	deletePaymentMethodSuccess,
	getPaymentMethodError,
	getPaymentMethodSuccess,
	updatePaymentMethodError,
	updatePaymentMethodSuccess,
} from '@store/payment-methods/action-creators';
import {
	deleteAccountPaymentMethodApi,
	getAccountPaymentMethodApi,
	updateAccountPaymentMethodApi,
	getTenantPaymentMethodApi,
	updateTenantPaymentMethodApi,
	deleteTenantPaymentMethodApi,
} from '@store/payment-methods/api';
import TYPES from './action-types';

/**
 * Get payment method
 */
function* getPaymentMethod({ payload: { accountId } }: IAction<TYPES>): SagaIterator {
	try {
		let data;

		if (accountId) {
			data = yield call(getAccountPaymentMethodApi, accountId);
		} else {
			data = yield call(getTenantPaymentMethodApi);
		}

		const { defaultPaymentMethod } = data;

		yield put(getPaymentMethodSuccess(defaultPaymentMethod));
	} catch (e) {
		yield put(getPaymentMethodError(e.message));
	}
}

/**
 * Update payment method
 */
function* updatePaymentMethod({ payload: { accountId, paymentMethodId } }: IAction<TYPES>): SagaIterator {
	try {
		let data;

		if (accountId) {
			data = yield call(updateAccountPaymentMethodApi, accountId, paymentMethodId);
		} else {
			data = yield call(updateTenantPaymentMethodApi, paymentMethodId);
		}

		const { defaultPaymentMethod } = data;

		yield put(updatePaymentMethodSuccess(defaultPaymentMethod));
	} catch (e) {
		yield put(updatePaymentMethodError(e.message));
	}
}

/**
 * Delete payment method
 */
function* deletePaymentMethod({ payload: { accountId } }: IAction<TYPES>): SagaIterator {
	try {
		if (accountId) {
			yield call(deleteAccountPaymentMethodApi, accountId);
		} else {
			yield call(deleteTenantPaymentMethodApi);
		}

		yield put(deletePaymentMethodSuccess());
	} catch (e) {
		yield put(deletePaymentMethodError(e.message));
	}
}

export default [
	takeLatest(TYPES.GET_PAYMENT_METHOD, getPaymentMethod),
	takeLatest(TYPES.UPDATE_PAYMENT_METHOD, updatePaymentMethod),
	takeLatest(TYPES.DELETE_PAYMENT_METHOD, deletePaymentMethod),
];
