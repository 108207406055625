import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { updateTransactionListDatesWithoutRefetch } from '@store/transactions/action-creators';

export const mapStateToProps = ({ transactions: { list }, accounts: { item } }: IState) =>
	({
		dates: list?.dates,
		account: item.result,
	}) as const;

export const mapDispatchToProps = {
	updateTransactionListDatesWithoutRefetch,
};

export type IGetReportModalToProps = ReturnType<typeof mapStateToProps>;

export type IGetReportModalDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
