import { Box } from '@mui/system';
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '@components/main-loader/loader';
import { requestExcelFile } from '@helpers/request';
import { SmallButton } from '..';
import SaveMode from '../../../enums/save-mode';

interface RequestParamsForExcel {
	startDate?: string;
	endDate?: string;
	accountId?: string;
	timeZone: string;
}

export interface ExcelReportOptions {
	fileName: string;
	requestOptions: { endpoint: string; params: RequestParamsForExcel };
}

export const ExportToExcel = ({
	disabled,
	titleBtn = 'components.excel.btn',
	saveMode,
	onFinish,
	options,
}: {
	disabled?: boolean;
	titleBtn?: string;
	saveMode: SaveMode;
	onFinish?: () => void;
	options: ExcelReportOptions;
}) => {
	const { t } = useTranslation();
	const [isFetch, setIsFetch] = useState(false);

	const handleSave = async () => {
		setIsFetch(true);
		const blob = await requestExcelFile<RequestParamsForExcel>(options.requestOptions.endpoint, options.requestOptions.params);

		saveAs(blob, `${options.fileName}.xlsx`);
		setIsFetch(false);
		if (onFinish) {
			onFinish();
		}
	};

	useEffect(() => {
		if (saveMode === SaveMode.OnInit && Boolean(options)) {
			void handleSave().then(() => setIsFetch(false));
			if (onFinish) {
				onFinish();
			}
		}
		// eslint-disable-next-line
  }, [saveMode, options]);

	const renderItem = useMemo(() => {
		switch (saveMode) {
			case SaveMode.OnClick:
				return (
					<Box onClick={() => !disabled && handleSave()}>
						<SmallButton sx={{ mt: 0 }} isFetching={isFetch} disabled={disabled}>
							{t(titleBtn)}
						</SmallButton>
					</Box>
				);
			case SaveMode.OnInit:
				return isFetch && <Loader />;
		}
		// eslint-disable-next-line
  }, [disabled, isFetch, saveMode, t, titleBtn, options]);

	return <Box>{renderItem}</Box>;
};

export default ExportToExcel;
