import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	container: {
		borderRadius: '5px',
		border: 'none',
		width: '100%',
		backgroundImage: 'unset',
		boxShadow: 'none',
	},
	input: {
		fieldset: {
			boxShadow: 'none',
			border: 'unset !important',
			color: '#787878',
		},
		boxShadow: 'none',

		input: {
			py: '9px',
			color: ({ palette }) => `${palette.mode === 'light' ? '#787878' : '#fff'}`,
			boxShadow: 'none',

			'&::placeholder': {
				color: ({ palette }) => `${palette.mode === 'light' ? '#787878' : '#fff'}`,
			},
		} as SxProps<Theme>,
	},
	icon: {
		color: ({ palette }) => `${palette.mode === 'light' ? '#787878' : '#fff'}`,
		my: 0.5,
		minWidth: '10px !important',
	} as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
