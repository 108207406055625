import Box from '@mui/material/Box';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dropdown from '@components/dropdown';
import { Modal, TitledBlock } from '@components/index';
import LoadingButton from '@components/loading-button';
import { IModalForward } from '@components/modal';
import isAllowedForUser from '@helpers/is-allowed-for-user';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import { getCardsList } from '@store/cards/action-creators';
import RoleType from '../../../../enums/role-type';
import CheckoutDialog from './dialog/checkout/index.store';
import ErrorDialog from './dialog/error';
import SuccessDialog from './dialog/success';
import { ICardsPayDispatchToProps, ICardsPayStateToProps } from './index.props';
import styles from './styles';

export enum CARDS_PAY_DIALOG {
	CHECKOUT = 'CHECKOUT',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

type IProps = ICardsPayStateToProps &
	ICardsPayDispatchToProps & {
		toPayValue: number | string;
		discount?: number | string;
		setToPayValue?: any;
	};

/**
 * CardsPay button
 * @constructor
 */
const CardsPay: FC<IProps> = ({
	paymentType,
	noDeposit,
	isWaitingPayment,
	toPayValue,
	setPaymentType,
	rolesTenant,
	discount,
	isDisplayStripe,
	getTaxRateInfo,
	taxRateInfo,
	statusPay,
	isDeleteFetching,
	setToPayValue,
	isEmailExist,
}) => {
	const [isLoadingPay, setIsLoadingPay] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [paymentTypesData, setPaymentsTypesData] = useState<PAYMENT_TYPE[]>([]);
	const [dialogType, setDialogType] = useState<CARDS_PAY_DIALOG>(CARDS_PAY_DIALOG.CHECKOUT);
	const modalRef = useRef<IModalForward | null>(null);
	const isCustomerRole = isAllowedForUser([RoleType.Customer], rolesTenant as RoleType[]);
	const isPayForCreditCard: boolean = paymentType === PAYMENT_TYPE.CREDIT_CARD;
	const { t } = useTranslation();

	useEffect(() => {
		if (!isCustomerRole && !paymentTypesData.includes(PAYMENT_TYPE.AT_COUNTER)) {
			setPaymentsTypesData((prevState) => [...prevState, PAYMENT_TYPE.AT_COUNTER]);
		} else {
			setPaymentType(PAYMENT_TYPE.CREDIT_CARD);
		}

		if (isDisplayStripe) {
			if (!paymentTypesData.includes(PAYMENT_TYPE.SEND_INVOICE) && isEmailExist) {
				setPaymentsTypesData((prevState) => [...prevState, PAYMENT_TYPE.SEND_INVOICE]);
			}

			if (!paymentTypesData.includes(PAYMENT_TYPE.CREDIT_CARD)) {
				setPaymentsTypesData((prevState) => [...prevState, PAYMENT_TYPE.CREDIT_CARD]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDisplayStripe, isCustomerRole]);

	/**
	 * Reset modal dialog type
	 */
	const resetModalDialogType = useCallback(() => {
		if (!isLoadingPay) {
			setDialogType(CARDS_PAY_DIALOG.CHECKOUT);
		}
	}, [isLoadingPay]);

	/**
	 * On paying start
	 */
	const toggleModal = useCallback(() => {
		if (!isLoadingPay) {
			modalRef.current?.toggleModal();

			if (statusPay === 'payed') {
				setTimeout(() => {
					dispatch(getCardsList());
				}, 3000);
			}
		}
	}, [isLoadingPay, dispatch, statusPay]);

	useEffect(() => {
		if (!taxRateInfo.displayName) {
			getTaxRateInfo();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paymentType, getTaxRateInfo]);

	/**
	 * Render modal dialog by type
	 */

	const dialog = useMemo(() => {
		switch (dialogType) {
			case CARDS_PAY_DIALOG.SUCCESS:
				return <SuccessDialog type={paymentType} close={toggleModal} />;

			case CARDS_PAY_DIALOG.ERROR:
				return <ErrorDialog type={paymentType} close={toggleModal} />;

			case CARDS_PAY_DIALOG.CHECKOUT:
			default:
				return (
					<CheckoutDialog
						type={paymentType}
						setDialogType={setDialogType}
						close={toggleModal}
						discount={discount}
						toPayValue={toPayValue}
						setToPayValue={setToPayValue}
						setIsLoadingPay={setIsLoadingPay}
					/>
				);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogType, paymentType, toggleModal, discount, toPayValue]);

	const isConditionForPay: boolean = toPayValue ? !toPayValue || isWaitingPayment : noDeposit || isWaitingPayment;

	const handleClickItemDropdown = useCallback((value: PAYMENT_TYPE) => {
		setPaymentType(value);
		toggleModal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const btnRenderForRole = useMemo(
		() => (
			<>
				<LoadingButton
					color="primary"
					variant="contained"
					sx={{
						...styles.button,
						...(paymentTypesData.length === 1 && {
							borderRadius: '5px',
						}),
					}}
					onClick={toggleModal}
					isFetching={!isConditionForPay && isDeleteFetching}
					disabled={isConditionForPay || isDeleteFetching}>
					{paymentType}
				</LoadingButton>
				{paymentTypesData.length > 1 && (
					<Dropdown<PAYMENT_TYPE>
						id="cards-pay"
						isDisabled={isConditionForPay || isDeleteFetching}
						handleClickItem={(value) => handleClickItemDropdown(value)}
						data={paymentTypesData.filter((x) => x !== paymentType)}
					/>
				)}
			</>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[toPayValue, paymentTypesData, toggleModal, isConditionForPay, isDeleteFetching, paymentType, handleClickItemDropdown],
	);

	const isCheckoutEnd: boolean = dialogType === CARDS_PAY_DIALOG.SUCCESS || dialogType === CARDS_PAY_DIALOG.ERROR;

	return (
		<Box display="flex">
			{btnRenderForRole}
			<Modal
				ref={modalRef}
				width={{ xs: '100%', sm: isPayForCreditCard ? (isCheckoutEnd ? 400 : 796) : 400 }}
				customStyle={{
					height: 'auto',
					minHeight: '350px',
				}}
				isLoad={isLoadingPay}
				onClose={resetModalDialogType}>
				<TitledBlock title={`${t('checkoutDialog.checkout')}`} styleChildren={{ p: 0 }}>
					<Box
						sx={{
							...styles.body,
							py: isPayForCreditCard ? (isCheckoutEnd ? 3.5 : 0) : 3.5,
							px: isPayForCreditCard ? (isCheckoutEnd ? 4 : 0) : 4,
							background: ({ palette }) => (palette.mode === 'light' ? (isPayForCreditCard ? '#F8F8F8' : 'white') : '#000'),
						}}>
						{dialog}
					</Box>
				</TitledBlock>
			</Modal>
		</Box>
	);
};

export default CardsPay;
