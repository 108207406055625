import { request } from '@helpers/request';

/**
 * Get bonus-card balance
 */
export const getBonusCardBalanceApi = (bonusCardNumber: string) => request(`bonus-cards/check-balance/${bonusCardNumber}`);

/**
 * Use bonus card
 */
export const applyBonusCardApi = (body: Record<string, any>) => request(`bonus-cards/use-card`, undefined, body, 'POST');
