import type { SagaIterator } from '@redux-saga/core';
import { call, fork, put, takeLatest, delay } from 'redux-saga/effects';
import type { IAction } from '@interfaces/redux/i-action';
import { getCardsList } from '@store/cards/action-creators';
import { applyBonusCardError, applyBonusCardSuccess, getBonusCardBalanceError, getBonusCardBalanceSuccess } from './action-creators';
import TYPES from './action-types';
import { getBonusCardBalanceApi, applyBonusCardApi } from './api';

/**
 * Get bonus card balance
 */
function* getBonusCardBalance({ payload }: IAction<TYPES>): SagaIterator {
	try {
		const res = yield call(getBonusCardBalanceApi, payload);

		yield put(getBonusCardBalanceSuccess(res));
	} catch (e) {
		yield put(getBonusCardBalanceError(e));
	}
}

/**
 * Apply bonus card
 */
function* applyBonusCard({ payload }: IAction<TYPES>): SagaIterator {
	try {
		const res = yield call(applyBonusCardApi, payload);

		yield put(applyBonusCardSuccess(res));

		yield delay(1000);
		yield put(getCardsList());
	} catch (e) {
		yield put(applyBonusCardError(e));
	}
}

function* onGetBonusCardBalance(): SagaIterator {
	yield takeLatest(TYPES.GET_BONUS_CARD_BALANCE, getBonusCardBalance);
}

function* onApplyBonusCard(): SagaIterator {
	yield takeLatest(TYPES.APPLY_BONUS_CARD, applyBonusCard);
}

export default [fork(onGetBonusCardBalance), fork(onApplyBonusCard)];
