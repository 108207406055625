enum RoutePath {
	Accounts = '/accounts',
	AccountsEdit = '/accounts/edit',
	AccountsAdd = '/accounts/add',
	Cards = '/cards',
	Administration = '/administration',
	Login = '/login',
	Changelog = '/changelog',
	ForgotConfirm = '/forgot/confirm',
	Forgot = '/forgot',
	TransferFunds = '/transfer-funds',
	Report = '/report',
}

export default RoutePath;
