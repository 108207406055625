import { Component, ErrorInfo, ReactNode } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.node,
	};

	/**
	 * Handle app errors and send to Sentry
	 */
	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error(error, errorInfo);
	}

	render(): ReactNode {
		const { children } = this.props;

		return children;
	}
}

export default ErrorBoundary;
