import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { clearStore, forgotSendEmail } from '@store/forgot/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		error: state.forgot.isSendEmail.error,
		isFetching: state.forgot.isSendEmail.isFetching,
		isSend: state.forgot.isSendEmail.result,
	}) as const;

export const mapDispatchToProps = {
	forgotSendEmail,
	clearStore,
};

export type IForgotStateToProps = ReturnType<typeof mapStateToProps>;

export type IForgotDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
