import Box from '@mui/material/Box';
import React, { FC, ReactNode, useRef } from 'react';
import useWindowSize from '@hooks/use-window-size';
import styles from './styles';

interface IFixedTableHeight {
	topPane?: ReactNode;
	heightPercent?: number;
}

/**
 * FixedTableHeight
 * @constructor
 */
const FixedTableHeight: FC<IFixedTableHeight> = ({ children, topPane = null, heightPercent = 100 }) => {
	const { height } = useWindowSize();

	/**
	 * Table ref
	 */
	const tableRef = useRef<HTMLDivElement | null>(null);

	/**
	 * Table max height
	 */
	const tableHeight = (height * heightPercent) / 100 - Number(tableRef.current?.offsetTop ?? 80) - 24;

	return (
		<Box overflow="hidden">
			{topPane}
			<Box ref={tableRef} sx={styles.container} height={tableHeight}>
				{children}
			</Box>
		</Box>
	);
};

export default FixedTableHeight;
