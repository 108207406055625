import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Popper from '@mui/material/Popper';
import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useState } from 'react';
//import { Trans } from 'react-i18next';
import styles from './styles';

interface ITransitionsPopper {
	button: ReactNode;
	children?: ReactNode;
	sxBox?: any;
}

export interface ITransitionsPopperForward {
	close: () => void;
}

/**
 * TransitionsPopper
 * @constructor
 */
const TransitionsPopper = forwardRef<ITransitionsPopperForward, ITransitionsPopper>(({ children, button, sxBox }, ref) => {
	const [isOpen, setIsOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	/**
	 * Close event
	 */
	const close = useCallback(() => setIsOpen(false), []);

	/**
	 * Forward methods access
	 */
	useImperativeHandle(ref, () => ({ close }), [close]);

	/**
	 * Open tooltip
	 */
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
		setIsOpen((prevIsOpen) => !prevIsOpen);
	};

	const canBeOpen = isOpen && Boolean(anchorEl);
	const id = canBeOpen ? 'transition-popper' : undefined;

	return (
		<ClickAwayListener onClickAway={close}>
			<div>
				<div role="presentation" aria-describedby={id} onClick={handleClick}>
					{button}
				</div>

				<Popper id={id} open={isOpen} anchorEl={anchorEl} transition placement="left">
					{({ TransitionProps }) => (
						<Fade {...TransitionProps} timeout={350}>
							<Box sx={{ ...styles.box, ...sxBox }}>{children}</Box>
						</Fade>
					)}
				</Popper>
			</div>
		</ClickAwayListener>
	);
});

TransitionsPopper.displayName = 'TransitionsPopper';

export default TransitionsPopper;
