import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/index';
import styles from '@components/login-footer/styles';
import { IModalForward } from '@components/modal';
import PrivacyPolicy from '@components/privacy-policy';
import IS_MOBILE from '@constants/is-mobile';

const LoginFooter = () => {
	const { t } = useTranslation();
	const modalPrivacyRef = useRef<IModalForward | null>(null);

	const togglePrivacyModal = useCallback(() => modalPrivacyRef.current?.toggleModal(), []);

	return (
		<>
			<Box sx={styles.bottomInfo}>
				<Box onClick={togglePrivacyModal} sx={{ cursor: 'pointer' }}>
					{t('loginBanner.privacyPolicy')}
				</Box>
				<Box>
					<Link underline="none" href="https://www.m4cards.com/contact.html" color={'white'} target="_blank">
						{t('loginBanner.needHelp')}
					</Link>
				</Box>
				<Box>©2023 e-Logic CA</Box>
			</Box>
			<Modal
				ref={modalPrivacyRef}
				height={'auto'}
				customStyle={IS_MOBILE ? { maxHeight: '90%', overflow: 'auto' } : { left: '65%' }}
				width={IS_MOBILE ? undefined : '780px'}>
				<PrivacyPolicy cancelBtn={togglePrivacyModal} />
			</Modal>
		</>
	);
};

export default LoginFooter;
