import { useState, useEffect, useCallback } from 'react';

interface IWindowSize {
	width: number;
	height: number;
}

/**
 * Listen window sizes hook
 */
const useWindowSize = (): IWindowSize => {
	// Initialize state with undefined width/height so server and account renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<IWindowSize>({
		width: 0,
		height: 0,
	});

	// Handler to call on window resize
	const handleResize = useCallback(() => {
		// Set window width/height to state
		setWindowSize({
			width: window.innerWidth ?? 0,
			height: window.innerHeight ?? 0,
		});
	}, []);

	useEffect(() => {
		// only execute all the code below in account side
		if (typeof window === 'undefined') {
			return;
		}

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]); // Empty array ensures that effect is only run on mount

	return windowSize;
};

export default useWindowSize;
