import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import { IAction } from '@interfaces/redux/i-action';
import { IPagination } from '@interfaces/redux/i-pagination';
import { ITransaction } from '@interfaces/transactions/i-transaction';
import { CARDS_PAY_DIALOG } from '@pages/accounts/account/cards-pay';
import PlanType from '../../enums/plan-type';
import TYPES from './action-types';
import { ITransactionsState } from './reducer';

/**
 * Get a transaction list
 */
export const getTransactionList = (cardId?: string, bySorting?: any): IAction<TYPES> => ({
	type: TYPES.GET_TRANSACTIONS_LIST,
	payload: { cardId, bySorting },
});

/**t
 * Get transaction list success
 */
export const getTransactionListSuccess = (list: ITransactionsState['list']['result'], pagination?: IPagination, cardId?: string): IAction<TYPES> => ({
	type: TYPES.GET_TRANSACTIONS_LIST_SUCCESS,
	payload: { list, pagination, cardId },
});

/**
 * Get transaction list error
 */
export const getTransactionListError = (message: string): IAction<TYPES> => ({
	type: TYPES.GET_TRANSACTIONS_LIST_ERROR,
	payload: { message },
});

/**
 * Clear transaction list
 */
export const clearTransactionList = (): IAction<TYPES> => ({
	type: TYPES.CLEAR_TRANSACTIONS_LIST,
	payload: {},
});

/**
 * Update transaction list pagination
 */
export const updateTransactionListPagination = (pagination: Partial<IPagination>, cardId?: string): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTIONS_LIST_PAGINATION,
	payload: { pagination, cardId },
});

/**
 * Update transaction list sorting
 */
export const updateTransactionListSorting = (name?: string, desc?: boolean, cardId?: string): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTIONS_LIST_SORTING,
	payload: { name, desc, cardId },
});

/**
 * Update transactions list dates
 */
export const updateTransactionListDates = (startDate: string | null, endDate: string | null, cardId?: string): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTIONS_LIST_DATES,
	payload: { startDate, endDate, cardId },
});

export const updateTransactionListDatesWithoutRefetch = (
	startDate: string | null,
	endDate: string | null,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	_?: string,
): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTIONS_LIST_DATES_WITHOUT_REFETCH,
	payload: { startDate, endDate },
});

/**
 * Update transaction
 */
export const updateTransaction = (id: string, newData: Partial<ITransaction>, successCallback?: () => void): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTION,
	payload: { id, newData, successCallback },
});

/**
 * Update transaction success
 */
export const updateTransactionSuccess = (item: ITransaction): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTION_SUCCESS,
	payload: { item },
});

/**
 * Clear dates reducer
 */
export const handleClearDates = () => ({
	type: TYPES.CLEAR_DATES,
});

/**
 * Update transaction error
 */
export const updateTransactionError = (message: string): IAction<TYPES> => ({
	type: TYPES.UPDATE_TRANSACTION_ERROR,
	payload: { message },
});

/**
 * Delete transaction
 */
export const deleteTransaction = (id: string, successCallback?: () => void): IAction<TYPES> => ({
	type: TYPES.DELETE_TRANSACTION,
	payload: { id, successCallback },
});

/**
 * Delete transaction success
 */
export const deleteTransactionSuccess = (): IAction<TYPES> => ({
	type: TYPES.DELETE_TRANSACTION_SUCCESS,
	payload: {},
});

/**
 * Delete transaction error
 */
export const deleteTransactionError = (message: string): IAction<TYPES> => ({
	type: TYPES.DELETE_TRANSACTION_ERROR,
	payload: { message },
});

/**
 * Add transaction
 */
export const addTransaction = (id: string, successCallback?: () => void): IAction<TYPES> => ({
	type: TYPES.ADD_TRANSACTION,
	payload: { id, successCallback },
});

/**
 * Add transaction success
 */
export const addTransactionSuccess = (): IAction<TYPES> => ({
	type: TYPES.ADD_TRANSACTION_SUCCESS,
	payload: {},
});

/**
 * Add transaction error
 */
export const addTransactionError = (message: string): IAction<TYPES> => ({
	type: TYPES.ADD_TRANSACTION_ERROR,
	payload: { message },
});

/**
 * Add transactions
 */
export const addTransactions = (
	callback?: (modalType: CARDS_PAY_DIALOG) => void,
	accountPlanName?: PlanType | PAYMENT_TYPE.ADJUSTMENT | undefined,
	toPayValue?: string | number | undefined,
	discount?: string | number | null,
	specialCardId?: string | null,
): IAction<TYPES> => ({
	type: TYPES.ADD_TRANSACTIONS,
	payload: { callback, accountPlanName, toPayValue, discount, specialCardId },
});

/**
 * Add transaction success
 */
export const addTransactionsSuccess = (): IAction<TYPES> => ({
	type: TYPES.ADD_TRANSACTIONS_SUCCESS,
	payload: {},
});

/**
 * Add transactions error
 */
export const addTransactionsError = (message: string): IAction<TYPES> => ({
	type: TYPES.ADD_TRANSACTIONS_ERROR,
	payload: { message },
});
