/* eslint-disable @typescript-eslint/default-param-last */
import { IAction } from '@interfaces/redux/i-action';
import { IReducerState } from '@interfaces/redux/i-reducer-state';
import { ITenantBilling } from '@interfaces/tenant-billing/i-tenant-billing';
import TYPES from './action-types';

export interface ITenantBillingState {
	get: IReducerState<ITenantBilling | null>;
	update: { isFetching: boolean };
}

const initState: ITenantBillingState = {
	get: {
		result: null,
		isFetching: false,
		error: null,
	},
	update: {
		isFetching: false,
	},
};

/**
 * User reducer
 */
export default (state = initState, { type, payload }: IAction<TYPES>) => {
	switch (type) {
		case TYPES.GET_TENANT_BILLING_DATA:
			return { ...state, get: { ...state.get, error: null, isFetching: true } };

		case TYPES.GET_TENANT_BILLING_DATA_SUCCESS:
			return {
				...state,
				get: {
					isFetching: false,
					result: { ...payload.data },
				},
			};

		case TYPES.UPDATE_TENANT_BILLING_DATA:
			return { ...state, update: { isFetching: true } };

		case TYPES.UPDATE_TENANT_BILLING_DATA_SUCCESS:
			return {
				...state,
				update: { isFetching: false },
				get: { ...state.get, result: { ...state.get.result, email: payload.email } },
			};

		default:
			return state;
	}
};
