import { IAction } from '@interfaces/redux/i-action';
import { ITenantBillingState } from '@store/tenant-billing/reducer';
import TYPES from './action-types';

/**
 * Get tenant billing data
 */
export const getTenantBillingData = (): IAction<TYPES> => ({
	type: TYPES.GET_TENANT_BILLING_DATA,
	payload: {},
});

/**
 * Get tenant billing data success
 */
export const getTenantBillingDataSuccess = (data: ITenantBillingState['get']['result']): IAction<TYPES> => ({
	type: TYPES.GET_TENANT_BILLING_DATA_SUCCESS,
	payload: { data },
});

/**
 * Update tenant billing data
 */
export const updateTenantBillingData = ({ email }: { email: string }): IAction<TYPES> => ({
	type: TYPES.UPDATE_TENANT_BILLING_DATA,
	payload: { email },
});

/**
 * Update tenant billing data success
 */
export const updateTenantBillingDataSuccess = ({ email }: { email: string }): IAction<TYPES> => ({
	type: TYPES.UPDATE_TENANT_BILLING_DATA_SUCCESS,
	payload: { email },
});
