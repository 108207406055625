import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';

const styles = {
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		// eslint-disable-next-line
    justifyContent: 'space-between',
		height: '100%',
		// width: '100%',
	},

	title: {
		color: ({ palette }) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
	} as SxProps<Theme>,

	choosePlan: {
		width: '90%',
		boxShadow: '0px 0px 6px 4px rgba(0, 0, 0, 0.1)',
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		height: '80px',
		borderRadius: '9px',
	},

	boxIcon: {
		marginLeft: '20px',
		marginTop: '12px',
	},

	input: {
		'& :before, & :after': { content: 'unset' },
	},

	btnBox: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',

		'& button': {
			width: '125px',
		},
	},

	plan: {
		display: 'flex',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: '90%',
		textAlign: 'center',
	},

	inputSelect: {
		paddingLeft: '5px',
		input: {
			textAlign: 'center',
			paddingLeft: '7px',

			'&::placeholder': {
				color: 'rgba(0, 0, 0, 0.87)',
				opacity: 1,
			},
		},
	},

	selectBox: {
		width: '60%',
	},

	selectMenu: {
		width: '100%',
		textAlign: 'center',
	},

	infoBilling: {
		width: '90%',
		boxShadow: '0px 0px 6px 4px rgba(0, 0, 0, 0.1)',
		borderRadius: '9px',
		height: '50px',
		padding: ' 0 10px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		'& p': {
			whiteSpace: 'nowrap',
		},
		position: 'relative',
	},

	error: {
		position: 'absolute',
		bottom: '60px',
		right: 0,
		left: 0,
		textAlign: 'center',
		fontSize: '16px',
		color: '#BA1212',
	},
};

export default styles;
