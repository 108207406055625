enum TRANSACTION_ACTION_TYPES {
	GET_TRANSACTIONS_LIST = 'GET_TRANSACTIONS_LIST',
	GET_TRANSACTIONS_LIST_SUCCESS = 'GET_TRANSACTIONS_LIST_SUCCESS',
	GET_TRANSACTIONS_LIST_ERROR = 'GET_TRANSACTIONS_LIST_ERROR',
	CLEAR_TRANSACTIONS_LIST = 'CLEAR_TRANSACTIONS_LIST',
	UPDATE_TRANSACTIONS_LIST_PAGINATION = 'UPDATE_TRANSACTIONS_LIST_PAGINATION',
	UPDATE_TRANSACTIONS_LIST_SORTING = 'UPDATE_TRANSACTIONS_LIST_SORTING',
	UPDATE_TRANSACTIONS_LIST_DATES = 'UPDATE_TRANSACTIONS_LIST_DATES',
	UPDATE_TRANSACTIONS_LIST_DATES_WITHOUT_REFETCH = 'UPDATE_TRANSACTIONS_LIST_DATES_WITHOUT_REFETCH',

	UPDATE_TRANSACTION = 'UPDATE_TRANSACTION',
	UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS',
	UPDATE_TRANSACTION_ERROR = 'UPDATE_TRANSACTION_ERROR',

	DELETE_TRANSACTION = 'DELETE_TRANSACTION',
	DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS',
	DELETE_TRANSACTION_ERROR = 'DELETE_TRANSACTION_ERROR',

	ADD_TRANSACTION = 'ADD_TRANSACTION',
	ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS',
	ADD_TRANSACTION_ERROR = 'ADD_TRANSACTION_ERROR',

	ADD_TRANSACTIONS = 'ADD_TRANSACTIONS',
	ADD_TRANSACTIONS_SUCCESS = 'ADD_TRANSACTIONS_SUCCESS',
	ADD_TRANSACTIONS_ERROR = 'ADD_TRANSACTIONS_ERROR',

	CLEAR_DATES = 'CLEAR_DATES',
}

export default TRANSACTION_ACTION_TYPES;
