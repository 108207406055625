import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		width: '100%',
		backgroundColor: ({ palette }) => (palette.mode === 'light' ? 'white' : palette.grey[900]),
		height: '100%',
		overflow: 'hidden',
	} as SxProps<Theme>,
	modalText: {
		color: ({ palette }) => (palette.mode === 'light' ? 'text.primary' : 'text.secondary'),
	} as SxProps<Theme>,
	tableRow: {
		boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
		margin: '0 20px',
	},
	bodyTable: {
		width: '100%',
		height: '100%',
		overflowY: 'scroll',
	} as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
