import Box from '@mui/material/Box';
import React, { FC } from 'react';

// const changelog = process.env.REACT_APP_CHANGELOG || '';

/**
 * Changelog
 * @constructor
 */
const Changelog: FC = () => <Box padding={2}>{/* <ReactMarkdown children={changelog} /> */}</Box>;

export default Changelog;
