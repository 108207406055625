enum FORGOT_ACTION_TYPES {
	FORGOT_SEND_EMAIL = 'FORGOT_SEND_EMAIL',
	FORGOT_SEND_EMAIL_SUCCESS = 'FORGOT_SEND_EMAIL_SUCCESS',
	FORGOT_SEND_EMAIL_ERROR = 'FORGOT_SEND_EMAIL_ERROR',
	FORGOT_VERIFY = 'FORGOT_VERIFY',
	FORGOT_VERIFY_SUCCESS = 'FORGOT_VERIFY_SUCCESS',
	FORGOT_VERIFY_ERROR = 'FORGOT_VERIFY_ERROR',
	FORGOT_CONFIRM = 'FORGOT_CONFIRM',
	FORGOT_CONFIRM_SUCCESS = 'FORGOT_CONFIRM_SUCCESS',
	FORGOT_CONFIRM_ERROR = 'FORGOT_CONFIRM_ERROR',

	CLEAR_STORE = 'CLEAR_STORE',
}

export default FORGOT_ACTION_TYPES;
