import { getAuth } from 'firebase/auth';
import notistack from '@services/notistack-service';
import { configs } from './get-env';
import { timeout } from './timeout';

const getAuthHeader = async () => {
	const auth = getAuth();
	const token = await auth.currentUser?.getIdToken();

	return {
		Authorization: `Bearer ${String(token)}`,
	};
};
const baseRequest = async <TParams = Record<string, string | number | undefined>, TBody = Record<string, any>>(
	endpoint: string,
	params?: TParams,
	body?: TBody,
	method: 'GET' | 'PATCH' | 'PUT' | 'POST' | 'DELETE' = 'GET',
	headers: Record<string, string> = {},
): Promise<Response> => {
	if (!Object.keys(configs).length) {
		await timeout(1500);
	}

	const API_URL = `${configs.REACT_APP_API_URL ?? ''}/api/v1`;

	let input = `${API_URL}/${endpoint}`;

	if (params) {
		const paramsToString = Object.entries(params)
			.map(([key, value]) => value && `${key}=${value as string | number}`)
			.filter(Boolean)
			.join('&');

		input += `?${paramsToString}`;
	}

	const fetchOptions: RequestInit = {
		method,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'content-type': 'application/json;charset=UTF-8',
			// 'ngrok-skip-browser-warning': true,
			...headers,
		},
	};

	if (method !== 'GET' && body) {
		fetchOptions.body = JSON.stringify(body);
	}

	return new Promise<Response>((resolve, reject) => {
		void (async () => {
			try {
				const response = await fetch(encodeURI(input), fetchOptions);

				if (!response.ok) {
					const responseJson = await response.json();

					if (Array.isArray(responseJson?.message)) {
						throw new Error(responseJson.message.join('. '));
					}

					throw new Error(responseJson?.message ?? responseJson?.info);
				}

				resolve(response);
			} catch (e) {
				notistack.error(e.message);
				reject(e.message);
			}
		})();
	});
};

export const request = async <TResult, TParams = Record<string, string | number | undefined>, TBody = Record<string, any>>(
	endpoint: string,
	params?: TParams,
	body?: TBody,
	method: 'GET' | 'PATCH' | 'PUT' | 'POST' | 'DELETE' = 'GET',
): Promise<TResult> => {
	const headers = await getAuthHeader();
	const response = await baseRequest<TParams, TBody>(endpoint, params, body, method, headers);

	return response.json();
};

export const requestWithoutAuth = async <TResult, TParams = Record<string, string | number | undefined>, TBody = Record<string, any>>(
	endpoint: string,
	params?: TParams,
	body?: TBody,
	method: 'GET' | 'PATCH' | 'PUT' | 'POST' | 'DELETE' = 'GET',
): Promise<TResult> => {
	const response = await baseRequest<TParams, TBody>(endpoint, params, body, method);

	return response.json();
};

export const requestExcelFile = async <TParams = Record<string, string | number | undefined>>(endpoint: string, params?: TParams): Promise<Blob> => {
	const headers = await getAuthHeader();
	const response = await baseRequest<TParams>(endpoint, params, undefined, 'GET', headers);

	return response.blob();
};
