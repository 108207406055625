import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import mainBackgroundBlack from '@assets/images/icons/main-background-black.svg';
import mainBackground from '@assets/images/icons/main-background.svg';

/**
 * Loading Button
 * @constructor
 */
const Loader = () => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			position: 'absolute',
			right: 0,
			left: 0,
			top: 0,
			bottom: 0,
			backgroundImage: ({ palette }) => `url(${palette.mode === 'light' ? mainBackground : mainBackgroundBlack})`,
		}}>
		<CircularProgress />
	</Box>
);

export default Loader;
