import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { clearCardsList, getCardsList, updateCard, transferAnotherCard } from '@store/cards/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		isShowInactive: state.cards.isShowInactive,
		cards: state.cards.list.result,
		isListFetching: state.cards.list.isFetching,
		updateFetchingRowId: state.cards.update.result,
		deleteFetchingRowId: state.cards.delete.result,
		fetchingTransfer: state.cards.transfer,
		listenTransactions: state.payments.listen.transactions,
		accountId: state.accounts.item.result?.id,
	}) as const;

export const mapDispatchToProps = {
	clearCardsList,
	getCardsList,
	updateCard,
	transferAnotherCard,
};

export type ICardTransferStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardTransferDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
