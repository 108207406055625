import { Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Box } from '@mui/system';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SmallButton, TitledBlock } from '@components/index';
import isConditionDotsInString from '@helpers/condition-payment-dots';
import roundNumber from '@helpers/round-number';
import PAYMENT_TYPE from '@interfaces/cards/payment-type';
import type { IBalanceAdjustmentStateToProps, IBalanceAdjustmentDispatchToProps } from './index.props';
import styles from './styles';

type IProps = IBalanceAdjustmentStateToProps &
	IBalanceAdjustmentDispatchToProps & {
		currentCardId: string;
		cardName: string;
		cardBalance: string | number;
		close: any;
		setOpenModal: any;
	};

const ModalBalanceAdjustment: FC<IProps> = ({
	cardName,
	cardBalance,
	currentCardId,
	close,
	isFetching,
	isFetchingCards,
	balanceAdjustment,
	setOpenModal,
}) => {
	const { t } = useTranslation();
	const [valueBalance, setValueBalance] = useState<string | number>(cardBalance);

	const blockInvalidChar = (e: any) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

	const isRegexTest = (val: any) =>
		({
			name: 'period',
			regex: /^\d+(\.\d{0,2})?$/,
		}).regex.test(val);

	const handleChangePayment = useCallback((e: any) => {
		const value = Number(roundNumber(e.target.value));

		if (isConditionDotsInString(e.target.value)) {
			setValueBalance(e.target.value);

			return;
		}

		if (isRegexTest(e.target.value)) {
			setValueBalance(e.target.value);

			return;
		}

		if (value >= 0) {
			setValueBalance(value);
		}
	}, []);

	const handleSubmit = () => {
		const countAmount = roundNumber(Number(cardBalance) - Number(valueBalance));

		balanceAdjustment(
			() => {
				close();
				setOpenModal();
			},
			PAYMENT_TYPE.ADJUSTMENT,
			countAmount,
			0,
			currentCardId,
		);
	};

	const isCheckAmount = valueBalance < cardBalance;
	const isLoading = isFetching || isFetchingCards;

	return (
		<TitledBlock title={`${t('cardActionsCell.balanceAdjustment')}`} fullHeight styleChildren={styles.wrapper}>
			<Box>
				<Typography sx={styles.title}>
					{t('card')}: <div>{cardName}</div>
				</Typography>
				<Typography sx={styles.title}>
					{t('balance')}: <div>${cardBalance}</div>
				</Typography>
				<Typography mt={2} sx={styles.title}>
					{t('cardActionsCell.enterAmount')}
				</Typography>

				<OutlinedInput
					value={valueBalance}
					onChange={handleChangePayment}
					sx={styles.input}
					id={cardName}
					startAdornment={<InputAdornment position="start">$</InputAdornment>}
					onKeyDown={blockInvalidChar}
				/>

				<Typography sx={styles.title}>{t('cardActionsCell.descripBalanceAdjust')}</Typography>
				<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} width="100%">
					<SmallButton disabled={isLoading} sx={{ width: '45%' }} variant="outlined" color="primary" onClick={close}>
						{t('cancel')}
					</SmallButton>
					<SmallButton sx={{ width: '45%' }} color="primary" type="submit" onClick={handleSubmit} isFetching={isLoading} disabled={!isCheckAmount}>
						{t('submit')}
					</SmallButton>
				</Box>
			</Box>
		</TitledBlock>
	);
};

export default ModalBalanceAdjustment;
