import SearchIcon from '@mui/icons-material/Search';
import { Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SmallButton from '@components/small-button';
import { ICard } from '@interfaces/cards/i-card';
import type { ICardsAutocompleteDispatchToProps, ICardsAutocompleteStateToProps } from './index.props';
import styles from './styles';

type IProps = ICardsAutocompleteDispatchToProps & ICardsAutocompleteStateToProps;

/**
 * CardsAutocomplete
 * @see {https://mui.com/material-ui/react-autocomplete/#virtualization} if a virtual list will be needed
 * @constructor
 */
const CardsAutocomplete: FC<IProps> = ({
	cards,
	isFetchingList,
	isFetchingAdd,
	isWaitingPayment,
	addCards,
	updateNewCardsSearch,
	handleClearSearch,
}) => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState<typeof cards>([]);
	const [valueSearch, setValueSearch] = useState<string>('');
	const [valueCards, setValueCards] = useState<typeof cards>([]);

	useEffect(
		() => () => {
			handleClearSearch();
		},
		[handleClearSearch],
	);

	useEffect(() => {
		setValueCards(_.differenceBy(cards, selected, 'id'));
	}, [cards, selected]);
	/**
	 * On change selected
	 */
	const onChange = useCallback((__, value) => setSelected(value), []);

	/**
	 * Success callback on saving
	 */
	const successCallback = useCallback(() => setSelected([]), []);

	/**
	 * On submit
	 */
	const onSubmit = useCallback(
		() =>
			addCards(
				selected.map(({ id }) => id),
				successCallback,
			),
		[addCards, selected, successCallback],
	);

	const handleSearch = (e: any) => {
		if (e.target.value.length >= 2) {
			updateNewCardsSearch(e.target.value);
		}

		setValueSearch(e.target.value);
	};

	const isConditionForOpen = cards.length > 0 && valueSearch.length >= 2;

	const getOptionLabel = ({ name, cardHolderName, cardHolderEmail, cardHolderPhone }: ICard) =>
		`${name} ${cardHolderName} ${cardHolderEmail} ${cardHolderPhone}`;

	const renderTags = (value: ICard[], getTagProps: any) =>
		value.map(({ id, name }: ICard, index: number) => <Chip key={id} label={name} {...getTagProps({ index })} />);

	return (
		<Box sx={styles.wrapper}>
			<Box sx={styles.iconContainer}>{isFetchingList ? <CircularProgress size={24} color="inherit" /> : <SearchIcon sx={styles.icon} />}</Box>

			<Autocomplete
				multiple
				disablePortal
				disableCloseOnSelect
				onChange={onChange}
				id="cards-autocomplete"
				options={valueCards}
				value={selected}
				loading={isFetchingList}
				getOptionLabel={(option: ICard) => getOptionLabel(option)}
				onClose={() => setValueSearch('')}
				sx={styles.autocomplete}
				noOptionsText={`${t('empty')}`}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						sx={styles.input}
						placeholder={`${t('cardsAutocomplete.placeholderField')}`}
						onChange={(e) => handleSearch(e)}
					/>
				)}
				open={isConditionForOpen}
				renderOption={(props, { name, cardHolderName, cardHolderEmail, cardHolderPhone }, { selected: checked }) => (
					<li {...props}>
						<Checkbox checked={checked} />
						<Box sx={{ display: 'flex', gap: '10px' }}>
							{name && <Typography fontWeight={600}>{name}</Typography>}
							{cardHolderName && (
								<Typography>
									| {t('cards.h_holderName')}: {`"${cardHolderName}"`}
								</Typography>
							)}
							{cardHolderEmail && (
								<Typography>
									| {t('cards.h_holderEmail')}: {`"${cardHolderEmail}"`}
								</Typography>
							)}
							{cardHolderPhone && (
								<Typography>
									| {t('cards.h_holderPhone')}: {`"${cardHolderPhone}"`}
								</Typography>
							)}
						</Box>
					</li>
				)}
				renderTags={(value, getTagProps) => renderTags(value, getTagProps)}
			/>

			<SmallButton sx={styles.button} onClick={onSubmit} isFetching={isFetchingAdd} disabled={!selected.length || isWaitingPayment}>
				{t('add')}
			</SmallButton>
		</Box>
	);
};

export default CardsAutocomplete;
