import { request } from '@helpers/request';
import { ITransaction } from '@interfaces/transactions/i-transaction';

/**
 * Get the transaction list
 */
export const getTransactionListApi = (params: Record<string, any>) => request(`transactions/`, params);

/**
 * Create transaction
 */
export const createTransactionApi = (cardId: string | number, body?: Partial<ITransaction> | Record<string, any>) =>
	request(`transactions/${cardId}/card`, undefined, body, 'POST');

export const createTransactionForCreditPlanApi = (accountId: string | number, body?: Partial<ITransaction> | Record<string, any>) =>
	request(`transactions/${accountId}/account`, undefined, body, 'POST');

/**
 * Update transaction
 */
export const updateTransactionApi = (id: string, body?: Record<string, any>) => request(`transactions/${id}`, undefined, body, 'PATCH');

/**
 * Delete transaction
 */
export const deleteTransactionApi = (id: string) => request(`transactions/${id}`, undefined, undefined, 'DELETE');
