import type { Theme } from '@mui/material/styles';
import type { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	wrapper: {
		height: '100%',
	},

	box: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'left',
		justifyContent: 'space-between',
		height: '100%',
	},
	label: {
		fontSize: '12px',
		color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),

		'& p': {
			color: ({ palette }: any) => (palette.mode === 'light' ? '#3C4977' : '#607EB5'),
		},
	} as SxProps<Theme>,

	value: {
		lineHeight: 1.44,
		fontSize: '20px',
		textAlign: 'left',
		mx: 0,
		fontWeight: '700',
		color: ({ palette }: any) => (palette.mode === 'light' ? '#787878' : 'rgba(255, 255, 255, 0.83)'),

		'& p': {
			fontWeight: '700',
			color: ({ palette }: any) => (palette.mode === 'light' ? '#787878' : 'rgba(255, 255, 255, 0.83)'),
		},
	} as SxProps<Theme>,
};

export default styles as IStyles<typeof styles>;
