import { Box } from '@mui/system';
import { useState, useEffect, FC } from 'react';

interface Props {
	name?: string;
	domain: string;
	width?: string;
	height?: string;
}
const DynamicIcon: FC<Props> = ({ name = 'logo', domain, width = '100%', height = '100%' }) => {
	const [svg, setSVG] = useState<any>(undefined);

	useEffect(() => {
		let isMounted = true;
		//// eslint-disable-next-line @typescript-eslint/no-floating-promises
		(async () => {
			await import(`@assets/icons/${domain}/${name}.svg`)
				.then((icon) => isMounted && setSVG(icon))
				.catch(async () => {
					if (isMounted) {
						const iconDef = await import(`@assets/icons/default/${name}.svg`);
						setSVG(iconDef);
					}
				});
		})();

		return () => {
			isMounted = false;
		};
	}, [domain, name]);

	return (
		<Box sx={{ width, height }}>
			<img src={svg?.default} alt={name} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
		</Box>
	);
};

export default DynamicIcon;
