import getSummary from '@helpers/get-summary';
import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { updateAccount, updateAutoCharge } from '@store/accounts/action-creators';
import { updateNewDeposit } from '@store/deposits/action-creators';
import { deletePayment } from '@store/payments/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		totalBalance: getSummary(state.cards.list.result, 'finaleBalance'),
		deposit: getSummary(Object.values(state.deposits.item.deposit)),
		customBonus: getSummary(Object.values(state.deposits.item.customBonus)),
		isFetchingAutoCharge: state.accounts.updateAutoCharge.isFetching,
		account: state.accounts.item.result,
		isUpdateFetching: state.accounts.update.isFetching,
		userRoles: state.tenant.claims?.roles,
		isDeleteFetching: state.payments.delete.isFetching,
		listenTransaction: state.payments.listen.transactions,
		isWaitingPayment: state.payments.listen.result,
		paymentMethod: state.paymentMethod.item.result,
	}) as const;

export const mapDispatchToProps = {
	updateAccount,
	deletePayment,
	updateAutoCharge,
	updateNewDeposit,
};

export type IAdditionalBlocksStateToProps = ReturnType<typeof mapStateToProps>;

export type IAdditionalBlocksDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
