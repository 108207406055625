import crypto from '@crypto-browserify';

const algorithm: any = process.env.REACT_APP_ALGORITHM;
const password: any = process.env.REACT_APP_PASSWORD;
const localAppMode: any = process.env.REACT_APP_MODE === 'local';
const configs = localAppMode ? process.env : ({} as IEnvConfigs);

interface IEnvConfigs {
	REACT_APP_API_URL: string;
	REACT_APP_ID: string;
	REACT_APP_JWT_SECRET: string;
	REACT_APP_LOGIN_SUBDOMAIN: string;
}

const getEnv = async (): Promise<boolean> => {
	if (localAppMode) {
		Object.assign(configs, process.env);
		//console.debug(`[INFO] getEnv->configs from process.env (app local mode):\n ${JSON.stringify(process.env)}`);
	} else {
		const { env, iv } = await fetch('/env').then((res) => res.json());
		Object.assign(configs, JSON.parse(decrypt(env, iv)));
		//console.debug(`[INFO] getEnv->configs from /env (app production mode):\n ${JSON.stringify(process.env)}`);
	}

	return true;
};

function decrypt(text: any, iv: any) {
	iv = Buffer.from(iv, 'hex');
	const cDecipher = crypto.createDecipheriv(algorithm, password.replaceAll('-', ''), iv);
	let dec = cDecipher.update(text, 'hex', 'utf8');
	dec += cDecipher.final('utf8');

	//console.debug(`[INFO] get-env->decrypt:\n text=${text},\n dec=${dec}`);

	return dec;
}

export { getEnv, configs };
