import { ReplacePropertiesReturnType } from '@interfaces/common';
import IState from '@interfaces/redux/i-state';
import { updateTransactionListDatesWithoutRefetch } from '@store/transactions/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		authAccountId: state.tenant.id,
		roles: state.tenant.claims?.roles,
	}) as const;

export const mapDispatchToProps = {
	updateTransactionListDatesWithoutRefetch,
};

export type IReportStateToProps = ReturnType<typeof mapStateToProps>;

export type IReportDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
