import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	box: {
		py: 6,
		minHeight: 282, // @todo mocked height
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	button: {
		display: 'flex',
		justifyContent: 'center',
	},
};

export default styles as IStyles<typeof styles>;
