import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { IStyles } from '@interfaces/styles/i-styles';

const styles = {
	mainText: {
		p: {
			p: {
				fontWeight: 400,
				fontSize: '16px',
				lineHeight: '19px',
			} as SxProps<Theme>,
		},
	},
	secondaryText: {
		p: {
			p: {
				fontWeight: 300,
				fontSize: '14px',
				lineHeight: '16px',
			},
		},
	},
};

export default styles as IStyles<typeof styles>;
