import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SmallButton, TitledBlock } from '@components/index';
import styles from '@components/transfer-funds-block/styles';
import formatMoney from '@helpers/format-money';

const TransferFundsBlock = ({ balance, applyCard, getBonusCardBalance, applyBonusCard, bonusCardClear }: any) => {
	const [bonusCard, setBonusCard] = useState('');
	const [cardName, setCardName] = useState('');
	const { t } = useTranslation();

	useEffect(() => {
		bonusCardClear();

		return () => {
			bonusCardClear();
		};
		// eslint-disable-next-line
  }, []);

	const handleOnChange = (e: any) => {
		setBonusCard(e.target.value);
		bonusCardClear();
	};

	return (
		<TitledBlock title={t('transferFunds.title')}>
			<Box>
				<Box component="form" sx={styles.form}>
					<TextField
						sx={styles.textField}
						onChange={(e) => handleOnChange(e)}
						value={bonusCard}
						InputProps={{
							sx: styles.input,
							endAdornment: (
								<SmallButton sx={styles.button} onClick={() => getBonusCardBalance(bonusCard)} disabled={!bonusCard} isFetching={balance.isFetching}>
									{t('transferFunds.btnCheckBalance')}
								</SmallButton>
							),
						}}
						label={t('transferFunds.btnBalanceLabel')}
					/>
					<Typography sx={styles.error}>{balance.error || ' '}</Typography>
					<Box display="flex" alignItems="center" flexDirection="column">
						<Typography sx={styles.balance}>{balance?.result?.credit ? formatMoney(balance?.result?.credit) : ''}</Typography>
						<Typography textAlign="center" margin="20px 0" whiteSpace={'nowrap'}>
							{t('transferFunds.btnBalanceDescribe')}
						</Typography>
					</Box>
					<TextField
						sx={styles.textField}
						onChange={(e) => setCardName(e.target.value)}
						value={cardName}
						InputProps={{
							sx: styles.input,
							endAdornment: (
								<SmallButton
									sx={styles.button}
									onClick={() => applyBonusCard({ bonusCard, cardName })}
									disabled={!bonusCard || !cardName || !balance?.result?.credit}
									isFetching={applyCard.isFetching}>
									{t('transferFunds.btnTransferFunds')}
								</SmallButton>
							),
						}}
						label={t('transferFunds.btnTransferFundsLabel')}
					/>
					<Typography sx={styles.error}>{applyCard.error ? applyCard.error : ' '}</Typography>
				</Box>

				<Box sx={styles.successfulBox}>
					{applyCard.result.isUsed ? (
						<>
							<Typography>{t('transferFunds.successTransfer_firstP')}</Typography>
							<Typography>{t('transferFunds.successTransfer_secondP')}</Typography>
						</>
					) : null}
				</Box>
			</Box>
		</TitledBlock>
	);
};

export default TransferFundsBlock;
