import type { SagaIterator } from '@redux-saga/core';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { onUpdateListFork } from '@helpers/sagas';
import { IAccount } from '@interfaces/accounts/i-account';
import { ICard } from '@interfaces/cards/i-card';
import PlanType from '../../enums/plan-type';
import { getCardsListApi } from '../cards/api';
import { awaitUserToken } from '../tenant/sagas';
import { getNewCardsList, getNewCardsListError, getNewCardsListSuccess } from './action-creators';
import TYPES from './action-types';

/**
 * Get cards list
 */
function* getCards(payload?: any): SagaIterator {
	try {
		yield call(awaitUserToken);
		const selected = payload.payload?.map(({ id }: ICard) => id);
		const search: string = yield select((state) => state.newCards.list.searchValue);
		const account: IAccount = yield select((state) => state.accounts?.item?.result);
		const planName = account.accountPlan.plan.name;

		const result = yield call(getCardsListApi, {
			page: 1,
			size: 100,
			order: 1,
			selected,
			forAccount: planName === PlanType.Custom ? account?.id : undefined,
			...(search && { search: `%${search}%` }),
		});

		console.info({ getNewCards: result });

		yield put(
			getNewCardsListSuccess(result.data, {
				page: Number(result.currentPage),
				total: result.count,
				size: 100,
			}),
		);
	} catch (e) {
		yield put(getNewCardsListError(e.message));
	}
}

/**
 * On update search
 */
function* onUpdateSearch(): SagaIterator {
	yield call(onUpdateListFork, 'newCards', 'searchValue', TYPES.UPDATE_CARDS_SEARCH, getNewCardsList);
}

export default [takeLatest(TYPES.GET_CARDS_LIST, getCards), fork(onUpdateSearch)];
