import type { ReplacePropertiesReturnType } from '@interfaces/common';
import type IState from '@interfaces/redux/i-state';
import { getCardsList, updateCardsListPagination, updateCardsSearch, updateCardsSorting } from '@store/cards/action-creators';

export const mapStateToProps = (state: IState) =>
	({
		accounts: state.cards.list.result,
		pagination: state.cards.list.pagination,
		isFetching: state.cards.list.isFetching,
		isFetchingFirebase: state.tenant.isFetching,
		searchValue: state.cards.list.searchValue,
	}) as const;

export const mapDispatchToProps = {
	getCardsList,
	updateCardsSearch,
	updateCardsListPagination,
	updateCardsSorting,
};

export type ICardsStateToProps = ReturnType<typeof mapStateToProps>;

export type ICardsDispatchToProps = ReplacePropertiesReturnType<typeof mapDispatchToProps, void>;
